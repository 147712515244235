<template>
    <k-action-dialog :api-call="apiCall" action="Create" @close="close">
        <v-form v-model="valid">
            <v-checkbox label="With identity" v-model="has_identity" required />
            <div v-if="has_identity">
                <v-text-field label="First name" v-model="identity.first_name" required />
                <v-text-field label="Last name" v-model="identity.last_name" required />
                <v-text-field label="Email address" v-model="identity.email_address" required />
                <v-text-field label="Phone number" v-model="identity.phone_number" required />
                <v-select label="Employment status" v-model="identity.employment_status" :items="enums.employment_status" clearable @click:clear="clear_employment_status"/>
                <v-autocomplete label="Nationality" v-model="identity.nationality" :items="enums.country" clearable @click:clear="clear_nationality"/>
                <v-checkbox label="With date of birth" v-model="has_date_of_birth" required />
                <div v-if="has_date_of_birth" class="d-flex flex-row">
                    <v-text-field
                      label="Day"
                      v-model.number="date_of_birth.day"
                      type="number"
                      single-line
                      class="shrink"
                      style="width: 80px !important; margin-right: 25px"
                      required />
                    <v-text-field
                      label="Month"
                      v-model.number="date_of_birth.month"
                      type="number"
                      single-line
                      class="shrink"
                      style="width: 80px !important; margin-right: 25px"
                      required />
                    <v-text-field
                      label="Year"
                      v-model.number="date_of_birth.year"
                      type="number"
                      single-line
                      class="shrink"
                      style="width: 120px !important; margin-right: 25px"
                      required />
                </div>
                <v-checkbox label="With address" v-model="has_address" required />
                <div v-if="has_address">
                    <v-text-field label="Line 1" v-model="address.line_1" required />
                    <v-text-field label="Line 2" v-model="address.line_2" required />
                    <v-text-field label="Postal code" v-model="address.postal_code" required />
                    <v-text-field label="City" v-model="address.city" required />
                    <v-text-field label="Region" v-model="address.region" required />
                    <v-select label="Country" v-model="address.country" :items="enums.country" />
                </div>
                <v-checkbox label="With identity document" v-model="has_document" required />
                <div v-if="has_document">
                    <v-select label="Type" v-model="document.type" :items="enums.identity_document_type" />
                    <v-select label="Country" v-model="document.country" :items="enums.country" />
                    <v-text-field label="Number" v-model="document.number" required />
                    <v-menu v-model="expiration_date_menu" :close-on-content-click="false" offset-y>
                        <v-text-field slot="activator" label="Expiration date" v-model="document.expiration_date" required />
                        <v-date-picker v-model="document.expiration_date" @change="expiration_date_menu = false" />
                    </v-menu>

                    <k-file-selector label="Front picture" v-model="document.front_file_id" required />
                    <k-file-selector label="Back picture" v-model="document.back_file_id" required />
                    <k-file-selector label="Selfie picture" v-model="document.selfie_file_id" required />
                </div>
            </div>
        </v-form>
    </k-action-dialog>
</template>

<script>
import modalMixin from '@/services/modalMixin';
import enums from '@/services/enums';

export default {
  data() {
    return {
      expiration_date_menu: false,
      enums,
      valid: null,
      has_identity: false,
      has_date_of_birth: false,
      has_address: false,
      has_document: false,
      identity: {
        first_name: '',
        last_name: '',
        email_address: '',
        phone_number: '',
        nationality: 'ES',
        employment_status: null,
      },
      date_of_birth: {
        day: null,
        month: null,
        year: null,
      },
      address: {
        line_1: '',
        line_2: '',
        postal_code: '',
        city: '',
        region: '',
        country: 'ES',
      },
      document: {
        type: 'national_id',
        country: 'ES',
        number: '',
        expiration_date: '2018-12-31',
        front_file_id: null,
        back_file_id: null,
        selfie_file_id: null,
      },
    };
  },
  mixins: [modalMixin],
  methods: {
    clear_employment_status() {
      setTimeout(() => {
        this.identity.employment_status = null;
      }, 1);
    },
    clear_nationality() {
      setTimeout(() => {
        this.identity.nationality = null;
      }, 1);
    },
  },
  computed: {
    apiCall() {
      let identity;
      if (this.has_identity) {
        identity = { ...this.identity };
        if (this.has_date_of_birth) {
          identity.date_of_birth = this.date_of_birth;
        }
        if (this.has_address) {
          identity.address = this.address;
        }
        if (this.has_document) {
          identity.document = this.document;
        }
      }
      return {
        method: 'POST',
        path: '/customers',
        body: {
          identity,
        },
      };
    },
  },
};

</script>
