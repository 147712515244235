<template>
    <k-action-dialog :api-call="apiCall" action="Create bank account" @close="close">
        <v-form v-model="valid">
            Belongs to:
            <v-radio-group v-model="owner">
                <v-radio label="Business" value="business"/>
                <v-radio label="Customer" value="customer"/>
            </v-radio-group>
            <div v-if="owner === 'business'">
              <k-business-selector label="Business" v-model="business_id" required />
            </div>
            <div v-if="owner === 'customer'">
              <k-customer-selector label="Customer" v-model="customer_id" required />
            </div>
            <v-text-field label="IBAN" v-model="bank_account_info.iban" />
            <v-text-field label="Beneficiary name" v-model="bank_account_info.beneficiary_name" />

            Meta:
            <v-row>
              <v-col>
                <k-meta-edit v-model="meta"></k-meta-edit>
              </v-col>
            </v-row>
        </v-form>
    </k-action-dialog>
</template>

<script>
import modalMixin from '@/services/modalMixin';

export default {
  data() {
    return {
      valid: null,
      bank_account_info: {
        iban: '',
        beneficiary_name: '',
      },
      owner: 'business',
      customer_id: null,
      business_id: null,
      meta: {},
    };
  },
  mixins: [modalMixin],
  created() {
    if (this.$route.params.id) {
      this.account_id = this.$route.params.id;
    }
  },
  computed: {
    apiCall() {
      const body = {
        bank_account_info: this.bank_account_info,
      };
      if (this.owner === 'business') {
        body.business_id = this.business_id;
      }
      if (this.owner === 'customer') {
        body.customer_id = this.customer_id;
      }
      body.meta = this.meta;
      return {
        method: 'POST',
        path: '/bank_accounts',
        body,
      };
    },
  },
};

</script>
