<template>
  <k-detail-view :source="detailSource" style="overflow: hidden;">
    <template slot="object" slot-scope="{ o }">
      <h1>System check</h1>
      <v-expansion-panels multiple>
        <v-expansion-panel v-for="(check, checkName) in filterChecks(o)" :key="checkName">
          <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
          <v-expansion-panel-header @click="page = 1">
            {{checkName}}: {{check.err ? 'FAILED' : check.failures.length}}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card>
              <v-card-text>
                <pre v-if="check.err" style="color: white; background: black; padding: 10px;">{{check.err}}</pre>
                <div v-else>
                   <v-pagination
                      v-model="page"
                      :length="Math.ceil(check.failures.length/pageSize)"
                      :total-visible="10"
                    ></v-pagination>

                    <div v-for="(f, index) in check.failures.slice((page-1)*pageSize, page*pageSize)" :key="index">
                      <v-btn color="primary" router :to="`/${myRoute.params.env}/reserve_transactions/${f.id}`" append>RSTX</v-btn>
                      <div><strong>id:</strong> {{f.id}}</div>
                      <div><strong>created_at:</strong> {{f.created_at}}</div>
                      <div v-highlightjs="f && JSON.stringify(f, null, 2)"></div>
                    </div>

                   <v-pagination
                      v-model="page"
                      :length="Math.ceil(check.failures.length/pageSize)"
                      :total-visible="10"
                    ></v-pagination>
                </div>
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
  </k-detail-view>
</template>

<script>
import activationMixin from '@/services/activationMixin';

export default {
  name: 'SystemCheck',
  mixins: [activationMixin],
  data() {
    return {
      page: 1,
    };
  },
  methods: {
    filterChecks(checks) {
      const final = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const [checkName, check] of Object.entries(checks)) {
        if (check.err || check.failures.length) final[checkName] = { ...check, failures: check.failures };
      }
      return final;
    },
  },
  computed: {
    pageSize() {
      return 10;
    },
    detailSource() {
      return {
        env: this.myRoute.params.env,
        path: `/system_check/${this.$route.params.id}`,
        postprocess(data) {
          const groupBy = {
            reconciliation_transaction: 'type',
            reconciliation_reserve_transaction: 'type',
          };
          const res = {};
          for (const [key, value] of Object.entries(data)) {
            if (value.err === null && key in groupBy) {
              for (const item of value.failures) {
                const key2 = `${key}/${item.type}`;
                if (!(key2 in res)) {
                  res[key2] = {
                    failures: [],
                    err: null,
                    duration: value.duration,
                  };
                }
                res[key2].failures.push(item);
              }
            } else {
              res[key] = value;
            }
          }

          return res;
        },
      };
    },
  },
};
</script>
