<template>
    <k-action-dialog :api-call="apiCall" action="Create Credentials" @close="close">
          <v-form v-model="valid">
            <k-banking-connection-selector label='Banking Connection' v-model="banking_connection_id" required />
            <k-banking-connection-method-selector label='Banking Connection Method' v-model="banking_connection_method_id" required />
          Data:
          <v-row>
              <v-col>
                <k-meta-edit v-model="data"></k-meta-edit>
              </v-col>
            </v-row>
          Meta:
            <br>
            <v-row>
              <v-col>
                <k-meta-edit v-model="meta"></k-meta-edit>
              </v-col>
            </v-row>
        </v-form>
    </k-action-dialog>
</template>

<script>
import modalMixin from '@/services/modalMixin';

export default {
  data() {
    return {
      banking_connection_id: null,
      banking_connection_method_id: null,
      data: {},
      meta: {},
    };
  },
  mixins: [modalMixin],
  computed: {
    apiCall() {
      const body = {
        banking_connection_id: this.banking_connection_id,
        banking_connection_method_id: this.banking_connection_method_id,
        data: this.data,
        meta: this.meta,
      };

      return {
        method: 'POST',
        path: '/banking_credentials',
        body,
      };
    },
  },
};

</script>
