<template>
  <k-action-dialog :api-call="apiCall" action="Create" @close="close">
    <v-form v-model="valid">
      <v-text-field label="Name" v-model="name" required />
      <k-virtual-currency-selector label="Currency" v-model="currency" required />
      <k-customer-selector label="Customer" v-model="customer_id" required />
    </v-form>
  </k-action-dialog>
</template>

<script>
import modalMixin from '@/services/modalMixin';

export default {
  name: 'VirtualAccountCreate',
  mixins: [modalMixin],
  data() {
    return {
      valid: null,
      name: '',
      currency: 'EUR',
      customer_id: null,
    };
  },
  computed: {
    apiCall() {
      return {
        method: 'POST',
        path: '/virtual_accounts',
        body: {
          currency: this.currency,
          customer_id: this.customer_id,
          meta: {
            _name: this.name,
          },
        },
      };
    },
  },
};

</script>
