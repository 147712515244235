<template>
  <div>
    <k-detail-view :source="detailSource">
        <template slot="object" slot-scope="{ o }">
            <h1>Virtual account {{o.meta._name || o.id}}</h1>

            <k-filter-set :filters="filters" :query="myRoute.query"/>

            <k-list-view :source="source">
                <template slot="header">
                    <th class="id-column">ID</th>
                    <th>Date</th>
                    <th class="listable-right">Amount</th>
                    <th class="listable-right">Balance</th>
                </template>
                <template slot="object" slot-scope="{ o }">
                    <tr class="rowlink" :key="o.id" @click="() => {selectedTransaction = o.id; openTransactionDetailModal();}">
                        <td class="fulllink">
                            <k-id :id="o.id"/>
                        </td>
                        <td>{{o.created_at | moment('DD/MM/YYYY - HH:mm') }}</td>
                        <td class="listable-right">
                            <k-value sign color :value="o.value"></k-value>
                        </td>
                        <td class="listable-right">
                            <k-value :value="o.balance_after"></k-value>
                        </td>
                    </tr>
                </template>
            </k-list-view>
        </template>
    </k-detail-view>

    <k-virtual-transaction-detail v-if="isVirtualTransactionDetailModalVisible" @close="closeVirtualTransactionDetailModal" v-bind:virtual-transaction-id="selectedVirtualTransaction"/>
  </div>
</template>

<script>
import activationMixin from '@/services/activationMixin';
import { useModal } from '@/services/modalMixin';

export default {
  name: 'VirtualAccountDetail',
  mixins: [
    activationMixin,
    useModal('VirtualTransactionDetail'),
  ],
  data() {
    return {
      filters: [
        { key: 'value.amount', name: 'Value', type: 'int' },
        { key: 'created_at', name: 'Created at', type: 'time' },
      ],
      selectedTransaction: undefined,
    };
  },
  computed: {
    detailSource() {
      return {
        env: this.myRoute.params.env,
        project: this.myRoute.params.project,
        path: `/virtual_accounts/${this.myRoute.params.id}`,
      };
    },
    source() {
      return {
        env: this.myRoute.params.env,
        project: this.myRoute.params.project,
        path: '/virtual_transactions',
        query: {
          ...this.myRoute.query,
          virtual_account_id: this.myRoute.params.id,
        },
      };
    },
  },
};
</script>
