<template>
    <k-action-dialog :api-call="apiCall" action="Card Payin Dispute Override" @close="close">
        <v-text-field label="Acquiring id" v-model="card_payin_dispute_acquiring_id" required />
        <k-value-selector label="Amount" v-model="value" required />
    </k-action-dialog>
</template>

<script>
import modalMixin from '@/services/modalMixin';

export default {
  props: {
    reserveTransactionId: String,
  },
  mixins: [modalMixin],
  data() {
    return {
      valid: null,
      card_payin_dispute_acquiring_id: '',
      value: {
        amount: 0,
        currency: 'EUR',
      },
    };
  },
  computed: {
    apiCall() {
      return {
        method: 'POST',
        path: `/reserve_transactions/${this.reserveTransactionId}/card_payin_dispute_override`,
        body: {
          card_payin_dispute_acquiring_id: this.card_payin_dispute_acquiring_id,
          value: this.value,
        },
      };
    },
  },
};
</script>
