<template>
    <v-list v-if="$global.env">
        <template v-for="(item, i) in items">
            <v-subheader :key="i" v-if="item.heading">
                {{ item.heading }}
            </v-subheader>
            <v-list-group :key="i" v-else-if="item.children" v-model="item.model" no-action>
                <v-list-item slot="item">
                    <v-list-item-action>
                        <v-icon>{{ item.model ? item.icon : item['icon-alt'] }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ item.text }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-for="(child, i) in item.children" :key="i">
                    <v-list-item-action v-if="child.icon">
                        <v-icon>{{ child.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ child.text }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>
            <v-list-item :key="i" v-else router exact :to="`/${$global.env.id}${item.action}`">
                <v-list-item-action>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>
                        {{ item.text }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>
    </v-list>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { icon: 'domain', text: 'Projects', action: '' },
        { icon: 'add', text: 'Create project', action: '/create' },
        { heading: 'Internal' },
        { icon: 'mdi-bank', text: 'Reserve accounts', action: '/reserve_accounts' },
        { icon: 'mdi-transfer', text: 'Extractions', action: '/reserve_account_extractions' },
        { icon: 'mdi-bank-transfer', text: 'Reserve transfers', action: '/reserve_transfers' },
        { icon: 'mdi-bank-check', text: 'Reconciliation groups', action: '/reconciliation_groups' },
        { icon: 'mdi-shield-check', text: 'System check', action: '/system_check' },
        { icon: 'mdi-database-export', text: 'Data exports', action: '/data_exporter' },
        { icon: 'mdi-credit-card-remove-outline', text: 'Unwanted card payins', action: '/unwanted_card_payins?card_payin_unwanted_state=requires_action' },
        { icon: 'mdi-cog-sync-outline', text: 'Bot transfer configs', action: '/bot_transfer_configs' },
        { heading: 'Bank Aggregation' },
        { icon: 'mdi-bank', text: 'Institutions', action: '/banking_institutions' },
        { icon: 'mdi-login-variant', text: 'Connection Methods', action: '/banking_connection_methods' },
        { icon: 'mdi-key-chain', text: 'Credentials', action: '/banking_credentials' },
        { icon: 'mdi-transfer', text: 'Extractions', action: '/banking_credential_extractions' },
      ],
    };
  },
};
</script>
