<template>
    <div>
        <h1>Banking Institutions</h1>

        <k-filter-set :filters="filters" :query="myRoute.query"/>

        <k-list-view :source="source">
            <template slot="header">
                <th class="id-column">ID</th>
                <th colspan="2">Name</th>
                <th>Region</th>
                <th>Status</th>
                <th colspan="2">Connection</th>
                <th colspan="2">Refresh</th>
            </template>
            <template slot="object" slot-scope="{ o }">
              <router-link class="rowlink" :to="`/${myRoute.params.env}/${myRoute.params.project}/banking_connections?banking_institution_id=${o.id}`" tag="tr" :key="o.id">
                    <td class="fulllink">
                        <k-id :id="o.id"/>
                    </td>
                    <td class="logo-column">
                      <img :src="o.meta._image_url" class="logo" alt="logo"/>
                    </td>
                    <td>
                        {{o.meta._name}}
                    </td>
                    <td>
                        {{ o.region }}
                    </td>
                    <td>
                      OK
                    </td>
                    <td class="logo-column">
                      <v-progress-circular :value="o.status.connection.success_rate * 100" width="6" size="25" color="green lighten-2" :rotate="-90" />
                    </td>
                    <td>
                      {{o.status.connection.success_rate * 100}}%
                    </td>
                    <td class="logo-column">
                      <v-progress-circular :value="o.status.refresh.success_rate * 100" width="6" size="25" color="green lighten-2" :rotate="-90" />
                    </td>
                    <td>
                      {{o.status.refresh.success_rate * 100}}%
                    </td>
              </router-link>
            </template>
        </k-list-view>
        <!-- <k-iban-detail v-if="isIBANDetailModalVisible" @close="closeIBANDetailModal" v-bind:iban-id="selectedInstitution"/> -->
    </div>
</template>

<script>
import activationMixin from '@/services/activationMixin';
import { useModal } from '@/services/modalMixin';

export default {
  name: 'BankingInstitutionList',
  mixins: [activationMixin, useModal('Create'), useModal('IBANDetail')],
  data() {
    return {
      filters: [
        { key: 'created_at', name: 'Created at', type: 'time' },
      ],
      selectedInstitution: undefined,
    };
  },
  computed: {
    source() {
      return {
        env: this.myRoute.params.env,
        project: this.myRoute.params.project,
        path: '/banking_institutions',
        query: this.myRoute.query,
      };
    },
  },
};
</script>
