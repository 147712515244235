<template>
  <v-flex xs12 sm6 offset-sm3>
    <v-card style="padding: 30px;">
      <v-card-title primary-title>
        <h1 v-if="emailScreen">Email verification</h1>
        <h1 v-else>Log in</h1>
      </v-card-title>
      <v-card-text v-if="emailScreen" >
        You need to verify your email. You have received an email with a confirmation link, please click it.
      </v-card-text>
      <v-card-text v-else>
        <v-form v-model="valid" ref="form" lazy-validation @submit.prevent="submit">
          <v-text-field
              label="E-mail"
              v-model="email"
              required
              :rules="emailRules"
              autofocus
          />
          <v-text-field
              label="Password"
              v-model="password"
              type="password"
              required
              :rules="passwordRules"
          />
          <v-btn type="submit" v-if="!processing" :disabled="!valid" >
              Log in
          </v-btn>
          <v-progress-circular v-if="processing" indeterminate color="primary"></v-progress-circular>
        </v-form>
        <v-alert v-if="error" type="error" :value="true">
          {{ error.message }}
        </v-alert>

        <div>
          Don't have an account? <router-link to="/signup">Sign up</router-link>
        </div>
        <div>
          <router-link to="/forgot_password">Forgot password</router-link>
        </div>
      </v-card-text>
    </v-card>
  </v-flex>
</template>

<script>
import * as session from '@/services/session';
import api from '@/services/api';

export default {
  name: 'Login',
  data() {
    return {
      valid: true,
      email: '',
      password: '',

      emailScreen: false,

      error: null,
      processing: false,
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: [
        (v) => !!v || 'Password is required',
      ],
    };
  },
  methods: {
    submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.$globalEventBus.$emit('object-edited');

      this.error = null;
      this.processing = true;
      api.userman.post('/login', {
        email: this.email,
        password: this.password,
      }).then((data) => {
        if (data.email_verification_required) {
          this.emailScreen = true;
          localStorage.setItem('email_verification_pending', data.token);
        } else {
          localStorage.setItem('email_verification_pending', '');
          session.setToken(data.token);
          this.$router.push('/');
        }
      }, (error) => {
        this.processing = false;
        this.error = error;
      });
    },
  },
};
</script>
