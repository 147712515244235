<template>
    <div>
        <h1>Files</h1>

        <v-btn color="primary" @click="openUploadModal">Upload</v-btn>
        <k-file-upload v-if="isUploadModalVisible" @close="closeUploadModal"/>

        <div>
            [Files can't be listed]
        </div>
    </div>
</template>

<script>
import { useModal } from '@/services/modalMixin';

export default {
  mixins: [useModal('Upload')],
};
</script>
