<template>
    <k-action-dialog :api-call="apiCall" action="Upload" @close="close">
        <input ref="file" type="file" :multiple="false" @change="fileChanged()">
        <v-form v-model="valid">
            <v-btn class="file-upload" color="primary" @click="pick">
                Choose file
            </v-btn>
            {{file && file.name}}
        </v-form>

        <template slot="done" slot-scope="{ response }">
            <h1>File uploaded!</h1>

            This is the File ID:
            <pre>{{ response.json.id }}</pre>
        </template>
    </k-action-dialog>
</template>

<script>
import modalMixin from '@/services/modalMixin';

export default {
  data() {
    return {
      valid: null,
      file: null,
    };
  },
  mixins: [modalMixin],
  methods: {
    pick() {
      this.$refs.file.click();
    },
    fileChanged() {
      // eslint-disable-next-line prefer-destructuring
      this.file = this.$refs.file.files[0];
    },
  },
  computed: {
    apiCall() {
      return {
        method: 'POST',
        path: '/files',
        files: {
          file: this.file,
        },
      };
    },
  },
};

</script>

<style lang="scss">
input[type="file"] {
    position: absolute;
    top: -1000px;
    left: -1000px;
    width: 1px;
    height: 1px;
    opacity: 0;
}

</style>
