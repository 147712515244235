<template>
    <v-flex xs12 sm6 offset-sm3>
        <v-card style="padding: 30px;">
            <v-card-title primary-title>
                <h1>Logging out...</h1>
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-card-title>
        </v-card>
    </v-flex>
</template>

<script>
import * as session from '@/services/session';

export default {
  name: 'Login',
  data() {
    return {
    };
  },
  created() {
    this.$globalEventBus.$emit('object-edited');
    session.logout().then(() => {
      setTimeout(() => {
        this.$router.push('/login');
      }, 700);
    });
  },
};
</script>
