<template>
    <div>
        <h1>Bank payins</h1>
        <k-filter-set :filters="filters" :query="myRoute.query"/>

        <k-list-view :source="source">
            <template slot="header">
                <th class="id-column">ID</th>
                <th>Date</th>
                <th>Reference</th>
                <th>Account</th>
                <th>Value</th>
            </template>
            <template slot="object" slot-scope="{ o }">
                <tr class="rowlink" :key="o.id" @click="() => {selectedBankPayin = o.id; openBankPayinDetailModal();}">
                    <td class="fulllink">
                        <k-id :id="o.id"/>
                    </td>
                    <td>{{o.created_at | moment('DD/MM/YYYY - HH:mm') }}</td>
                    <td>
                        {{o.bank_payin_reference_id}}
                    </td>
                    <td>
                        <router-link :to="`/${myRoute.params.env}/${myRoute.params.project}/accounts/${o.account_id}`" tag="a">
                            {{o.account_id}}
                        </router-link>
                    </td>
                    <td class="listable-right">
                        <k-value :value="o.value"/>
                    </td>
                </tr>
            </template>
        </k-list-view>
        <k-bank-payin-detail v-if="isBankPayinDetailModalVisible" @close="closeBankPayinDetailModal" v-bind:bank-payin-id="selectedBankPayin"/>
    </div>
</template>

<script>
import activationMixin from '@/services/activationMixin';
import enums from '@/services/enums';
import { useModal } from '@/services/modalMixin';

export default {
  name: 'BankPayinList',
  mixins: [activationMixin, useModal('BankPayinDetail')],
  data() {
    return {
      filters: [
        { key: 'value.amount', name: 'Value', type: 'int' },
        { key: 'value.currency', name: 'Currency', type: 'enum', choices: enums.currency },
        { key: 'concept', name: 'Concept', type: 'string' },
        { key: 'account_id', name: 'Account', type: 'id', id_prefix: 'acc' },
        { key: 'bank_payin_reference_id', name: 'Bank payin reference', type: 'id', id_prefix: 'bpir' },
        { key: 'created_at', name: 'Created at', type: 'time' },
      ],
      selectedBankPayin: undefined,
    };
  },
  created() {
    if (this.$route.query.id) {
      this.isBankPayinDetailModalVisible = true;
      this.selectedBankPayin = this.$route.query.id;
    }
  },
  computed: {
    source() {
      return {
        env: this.myRoute.params.env,
        project: this.myRoute.params.project,
        path: '/bank_payins',
        query: this.myRoute.query,
      };
    },
  },
};
</script>
