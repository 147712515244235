<template>
  <k-action-dialog :api-call="apiCall" action="Accept dispute" @close="close"
      :disable-devmode="true">
    <p>Accepting the dispute means to consider it as `lost`. This action cannot be undone. Are you sure?.</p>
  </k-action-dialog>
</template>

<script>
import modalMixin from '@/services/modalMixin';

export default {
  props: {
    cardPayinDisputeId: String,
  },
  mixins: [modalMixin],
  computed: {
    apiCall() {
      return {
        method: 'POST',
        path: `/card_payin_disputes/${this.cardPayinDisputeId}/close`,
      };
    },
  },
};
</script>

<style>
  p {
    font-size: 12pt;
  }
</style>
