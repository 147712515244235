<template>
    <div>
        <h1>Customers</h1>

        <v-btn color="primary" @click="openCreateModal">Create</v-btn>
        <k-customer-create v-if="isCreateModalVisible" @close="closeCreateModal"/>

        <k-filter-set :filters="filters" :query="myRoute.query"/>

        <k-list-view :source="source">
            <template slot="header">
                <th class="id-column">ID</th>
                <th>First name</th>
                <th>Last name</th>
            </template>
            <template slot="object" slot-scope="{ o }">
                <tr class="rowlink" :key="o.id" @click="() => {selectedCustomer = o.id; openCustomerDetailModal();}">
                    <td class="fulllink">
                        <k-id :id="o.id"/>
                    </td>
                    <td>
                        {{o.identity && o.identity.first_name}}
                    </td>
                    <td>
                        {{o.identity && o.identity.last_name}}
                    </td>
                </tr>
            </template>
        </k-list-view>

        <k-customer-detail v-if="isCustomerDetailModalVisible" @close="closeCustomerDetailModal" v-bind:customer-id="selectedCustomer"/>
    </div>
</template>

<script>
import activationMixin from '@/services/activationMixin';
import { useModal } from '@/services/modalMixin';

export default {
  name: 'CustomerList',
  mixins: [activationMixin, useModal('Create'), useModal('CustomerDetail')],
  data() {
    return {
      filters: [
        { key: 'external_id', name: 'External ID', type: 'string' },
        { key: 'identity.first_name', name: 'First Name', type: 'string' },
        { key: 'identity.last_name', name: 'Last Name', type: 'string' },
        { key: 'identity.document.number', name: 'ID Number', type: 'string' },
        { key: 'created_at', name: 'Created at', type: 'time' },
      ],
      selectedCustomer: undefined,
    };
  },
  created() {
    if (this.$route.query.id) {
      this.isCustomerDetailModalVisible = true;
      this.selectedCustomer = this.$route.query.id;
    }
  },
  computed: {
    source() {
      return {
        env: this.myRoute.params.env,
        project: this.myRoute.params.project,
        path: '/customers',
        query: this.myRoute.query,
      };
    },
  },
};
</script>
