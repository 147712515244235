<template>
    <div>
        <h1>General</h1>

        <v-form v-if="o">
          <v-row>
            <v-col cols="3" md="3">
              <v-text-field
                :rules="[
                  v => (v.length === 0 || (v.length >= 4 && v.length <= 25)) || 'Should be empty or between 4 and 25 characters',
                  v => /^$||^[a-zA-Z]{2}[a-zA-Z0-9 \-#.,]{2,23}$/.test(v) || '(a-z, A-Z, 0-9, spaces, hyphens, hashes, commas or periods) and starts with two letters are valid' ]"
                label="Static statement descriptor"
                v-model="o.statement_descriptor" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" md="3">
              <v-text-field
                :rules="[
                  v => (v.length === 0 || (v.length >= 2 && v.length <= 10)) || 'Should be empty or between 2 and 10 characters',
                  v => /^$||^[a-zA-Z]{2}[a-zA-Z0-9 ]{0,8}$/.test(v) || '(a-z, A-Z, 0-9, spaces, hyphens, hashes, commas or periods) and starts with two letters are valid' ]"
                label="Statement descriptor prefix"
                v-model="o.statement_descriptor_prefix"/>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3" md="3">
              <v-alert dense text type="success" v-if="submitted">
                  General settings edited!
              </v-alert>
              <v-alert dense text type="error" v-if="error">
                  {{ error.message }}
              </v-alert>
            </v-col>
          </v-row>

          <v-btn color="success" class="mr-4" @click="submit">Edit</v-btn>
        </v-form>

    </div>
</template>

<script>
import api from '@/services/api';

function defaultData() {
  return {
    o: null,
    error: null,
    submitted: false,
  };
}

export default {
  name: 'General',
  data: defaultData,
  activated() {
    Object.assign(this.$data, defaultData());

    api.kernel.get('/project').then((data) => {
      this.o = data;
    });
  },
  methods: {
    submit() {
      if (!this.o) {
        return;
      }

      this.error = null;
      this.submitted = false;

      api.kernel.patch('/project', {
        statement_descriptor: this.o.statement_descriptor,
        statement_descriptor_prefix: this.o.statement_descriptor_prefix,
      }).then(() => {
        this.submitted = true;
      }, (error) => {
        this.error = error;
      });
    },
  },
};
</script>
