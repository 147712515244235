<template>
    <div>
        <h1>System checks</h1>
        <v-btn color="primary" v-on:click="openSystemCheckPerformModal()" append>Perform</v-btn>
        <k-systemcheck-perform v-if="isSystemCheckPerformModalVisible" @close="closeSystemCheckPerformModal"/>
          <v-alert v-if="error" type="error">
            {{ error.message }}
          </v-alert>
        <k-list-view :source="source">
            <template slot="header">
                <th class="id-column">ID</th>
                <th>State</th>
                <th>Created At</th>
                <th>Updated At</th>
                <th></th>
            </template>
            <template slot="object" slot-scope="{ o }">
                <tr>
                    <td class="fulllink">
                        <k-id :id="o.id"/>
                    </td>
                    <td>
                        {{ o.state }}
                    </td>
                    <td>
                        {{ o.created_at | moment('DD/MM/YYYY - HH:mm') }}
                    </td>
                    <td>
                        {{ o.updated_at | moment('DD/MM/YYYY - HH:mm') }}
                    </td>
                    <td class="listable-buttons" v-if="o.state=='finished'">
                        <v-btn style="padding: 0 15px" color="green" router :to="{ path: `system_check/${o.id}` }">View result</v-btn>
                    </td>
                </tr>
            </template>
        </k-list-view>
    </div>
</template>

<script>
import api from '@/services/api';
import activationMixin from '@/services/activationMixin';
import { useModal } from '@/services/modalMixin';

export default {
  name: 'SystemCheckList',
  mixins: [activationMixin, useModal('SystemCheckPerform')],
  data() {
    return {
      error: null,
    };
  },
  computed: {
    source() {
      return {
        env: this.myRoute.params.env,
        path: '/system_check',
        query: this.myRoute.query,
      };
    },
  },
  methods: {
    enqueue() {
      this.error = null;

      api.kernel.post('/system_check', {}).then(() => {
        this.$globalEventBus.$emit('object-edited');
      }, (error) => {
        this.error = error;
      });
    },
  },
};
</script>
