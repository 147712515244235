<template>
    <k-dialog title="Reserve transfer" :big="true" @close="close">
        <k-detail-view :source="detailSource">
            <template slot="object" slot-scope="{ o }">
               <div v-highlightjs="o && JSON.stringify(o, null, 2)"></div>
                <h1>Attempts</h1>
                <k-list-view :source="source">
                    <template slot="header">
                        <th class="id-column">ID</th>
                        <th>Date</th>
                        <th>State</th>
                        <th>Bot</th>
                        <th></th>
                    </template>
                    <template slot="object" slot-scope="{ o }">
                        <tr>
                            <td class="fulllink">
                                <k-id :id="o.id"/>
                            </td>
                            <td>
                                {{o.created_at | moment('DD/MM/YYYY - HH:mm')}}
                            </td>
                            <td>
                                {{o.state}}
                            </td>
                            <td>
                                {{ getBotName(o) }}
                            </td>
                            <td class="listable-buttons">
                              <v-btn :disabled="!o.logs_filename" color="blue" target="_blank" :href="`https://admin.kernel.network/api/${$global.env.id}/v1/bots_file/${o.logs_filename}`" @click.stop>L</v-btn>
                            </td>
                        </tr>
                    </template>
                </k-list-view>
            </template>
        </k-detail-view>
    </k-dialog>
</template>

<script>
import modalMixin from '@/services/modalMixin';
import activationMixin from '@/services/activationMixin';

export default {
  name: 'ReserveTransferDetail',
  props: {
    reserveTransferId: String,
  },
  mixins: [activationMixin, modalMixin],
  methods: {
    getBotName(o) {
      if (o.bot_name) {
        return o.bot_name;
      }
      if (o.reserve_account) {
        return o.reserve_account.bot_name;
      }
      return 'Unclaimed';
    },
  },
  computed: {
    detailSource() {
      return {
        env: this.myRoute.params.env,
        path: `/reserve_transfers/${this.reserveTransferId}`,
      };
    },
    source() {
      return {
        env: this.myRoute.params.env,
        path: '/reserve_transfer_attempts',
        query: {
          reserve_transfer_id: this.reserveTransferId,
          expand: 'reserve_account',
        },
      };
    },
  },
};
</script>

<style lang="scss">
</style>
