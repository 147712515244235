<template>
    <k-action-dialog :api-call="apiCall" action="Create bank payout" @close="close">
        <v-form v-model="valid">
            <k-account-selector label="Source account" v-model="account_id" required />
            <k-value-selector label="Amount" v-model="value" required />

            <v-radio-group v-model="source">
                <v-radio label="Bank account info" value="bank_account_info"/>
                <v-radio label="Bank account" value="bank_account"/>
            </v-radio-group>
            <div v-if="source === 'bank_account_info'">
                <v-text-field label="IBAN" v-model="bank_account_info.iban" />
                <v-text-field label="Beneficiary name" v-model="bank_account_info.beneficiary_name" />
            </div>
            <div v-if="source === 'bank_account'">
                <k-bank-account-selector label="Bank account" v-model="bank_account_id" required />
            </div>
            <v-text-field label="Concept" v-model="concept" />

            <v-checkbox label="Instant" v-model="instant" />

            Meta:
            <v-row>
              <v-col>
                <k-meta-edit v-model="meta"></k-meta-edit>
              </v-col>
            </v-row>
        </v-form>
    </k-action-dialog>
</template>

<script>
import modalMixin from '@/services/modalMixin';

export default {
  props: {
    accountId: String,
  },
  mixins: [modalMixin],
  data() {
    return {
      valid: null,
      source: 'card_info',
      bank_account_id: null,
      bank_account_info: {
        iban: '',
        beneficiary_name: '',
      },
      account_id: null,
      value: {
        amount: 100,
        currency: 'EUR',
      },
      concept: '',
      instant: false,
      meta: {},
    };
  },
  created() {
    if (this.accountId) {
      this.account_id = this.accountId;
    }
  },
  computed: {
    apiCall() {
      const body = {
        account_id: this.account_id,
        type: 'standard',
        value: this.value,
        concept: this.concept,
      };
      if (this.source === 'bank_account_info') {
        body.bank_account_info = this.bank_account_info;
      }
      if (this.source === 'bank_account') {
        body.bank_account_id = this.bank_account_id;
      }
      if (this.instant) {
        body.type = 'instant';
      }
      body.meta = this.meta;
      return {
        method: 'POST',
        path: '/bank_payouts',
        body,
      };
    },
  },
};

</script>
