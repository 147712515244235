<template>
    <k-action-dialog :api-call="apiCall" :disable-devmode="true" action="Create" @close="close">
        <v-form v-model="valid">
            <v-text-field label="Name" v-model="meta._name"/>
            <v-radio-group
              v-model="type"
              row
              label="Type"
            >
                <v-radio label="Secret" value="secret"/>
                <v-radio label="Public" value="public"/>
            </v-radio-group>
            <v-simple-table v-if="type === 'secret'">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>
                      Resource
                    </th>
                    <th>
                      Permission
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(resource, index) in resources"
                    :key="index"
                  >
                    <td>{{ resource }}</td>
                    <td>
                      <v-radio-group
                        row
                        @change="(value) => updatePermission(resource, value)"
                        :value="getPermissionValue(resource)"
                      >
                        <v-radio
                          label="None"
                          value="none"
                        />
                        <v-radio
                          label="Read only"
                          value="read"
                        />
                        <v-radio
                          label="Read and write"
                          value="read_write"
                          :disabled="resource === 'transactions' || resource === 'project'"
                        />
                      </v-radio-group>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
        </v-form>

        <template slot="done" slot-scope="{ response }">
            <h1>API Key created!</h1>

            Copy the API Key below. <b v-if="$global.env.id == 'live'">You won't be able to retrieve it again later</b>

            <pre>{{ response.json.token }}</pre>
        </template>
    </k-action-dialog>
</template>

<script>
import modalMixin from '@/services/modalMixin';
import enums from '@/services/enums';

export default {
  data() {
    return {
      valid: null,
      type: 'secret',
      meta: {
        _name: '',
      },
      enums,
      permissions: [],
      touchedPerms: {},
    };
  },
  mixins: [modalMixin],
  computed: {
    apiCall() {
      return {
        method: 'POST',
        path: '/api_keys',
        body: {
          meta: this.meta,
          permissions: this.type === 'secret' ? this.permissions : [],
          type: this.type,
        },
      };
    },
    resources() {
      return enums.resource.filter((x) => !(['users', 'api_keys'].includes(x)));
    },
  },
  methods: {
    updatePermission(res, perm) {
      this.touchedPerms[res] = perm;
      this.permissions = Object.entries(this.touchedPerms).map(([resource, permission]) => `${resource}:${permission}`);
    },
    getPermissionValue(resource) {
      return this.touchedPerms[resource];
    },
  },
};

</script>

<style scoped>
pre {
    display: block;
    background: black;
    color: #ddd;
    padding: 20px;
    word-wrap: break-word;
    white-space: pre-wrap;
}
</style>
