<template>
    <div>
      <div class="page-header">
          <h1>Bank Credentials</h1>
          <v-btn rounded color="primary" @click="openCreateModal">
            <v-icon left>mdi-plus</v-icon>
            Create
          </v-btn>
      </div>

        <k-banking-credential-create v-if="isCreateModalVisible" @close="closeCreateModal"/>
        <k-filter-set :filters="filters" :query="myRoute.query"/>

        <k-list-view :source="source">
            <template slot="header">
                <th class="id-column">ID</th>
                <th>Owner</th>
                <th>Connection</th>
                <th colspan="2">Method</th>
                <th>Provider</th>
                <th class="listable-right">Actions</th>
            </template>
            <template slot="object" slot-scope="{ o }">
              <router-link class="rowlink" :to="`/${myRoute.params.env}/${o.project_id}/banking_connections?banking_credential_id=${o.id}`" tag="tr" :key="o.id">
                    <td class="fulllink">
                        <k-id :id="o.id" />
                    </td>
                    <td v-if="o.banking_connection.customer">
                        <span v-if="o.banking_connection.customer.identity">
                            <k-id :id="o.banking_connection.customer.id"/> {{o.banking_connection.customer.identity.first_name}} {{o.banking_connection.customer.identity.last_name}}
                        </span>
                        <span v-else><k-id long :id="o.banking_connection.customer.id"/></span>
                    </td>
                    <td v-else-if="o.banking_connection.business">
                        <span>
                            <k-id :id="o.banking_connection.business.id"/> {{o.banking_connection.business.trading_name}}
                        </span>
                    </td>
                    <td v-else>
                        N/A
                    </td>
                    <td>
                        <k-id :id="o.banking_connection_id" />
                    </td>
                    <td class="logo-column">
                      <img :src="o.banking_connection_method.banking_institution.meta._image_url" class="logo" alt="logo"/>
                    </td>
                    <td>
                        <code>{{o.banking_connection_method.provider_name}}</code>
                    </td>
                    <td>
                      {{o.banking_connection_method.provider}}
                    </td>
                    <td class="listable-right">
                      <v-btn
                        icon
                        color="blue"
                        @click.stop.prevent="extract(o.id)"
                      >
                        <v-icon size="20">mdi-transfer</v-icon>
                      </v-btn>
                    </td>
              </router-link>
            </template>
        </k-list-view>
    </div>
</template>

<script>
import api from '@/services/api';
import activationMixin from '@/services/activationMixin';
import { useModal } from '@/services/modalMixin';

export default {
  name: 'BankingCredentialsList',
  mixins: [activationMixin, useModal('Create')],
  data() {
    return {
      filters: [
        { key: 'created_at', name: 'Created at', type: 'time' },
      ],
      selectedInstitution: undefined,
    };
  },
  methods: {
    extract(id) {
      api.kernel.post(`/banking_credentials/${id}/extract`, {});
    },
  },
  computed: {
    source() {
      return {
        env: this.myRoute.params.env,
        path: '/banking_credentials',
        query: {
          ...this.myRoute.query,
          expand: ['banking_connection_method.banking_institution', 'banking_connection.customer', 'banking_connection.business'],
        },
      };
    },
  },
};
</script>
