export type AnyObject = { [index: string]: any };

function diffMeta(orig: AnyObject, curr: AnyObject) {
  const res: AnyObject = {};
  for (const k of Object.keys(orig)) {
    if (orig[k] !== curr[k]) {
      res[k] = curr[k] || '';
    }
  }
  for (const k of Object.keys(curr)) {
    if (orig[k] !== curr[k]) {
      res[k] = curr[k] || '';
    }
  }
  if (Object.keys(res).length === 0) return undefined;
  return res;
}

function diffValue(orig: AnyObject, curr: AnyObject) {
  if (orig.amount !== curr.amount) {
    return curr;
  }
  // eslint-disable-next-line no-use-before-define
  return diff(orig, curr);
}

function diff(orig: AnyObject, curr: AnyObject) {
  if (orig === curr) {
    return undefined;
  }
  if (curr === undefined || curr === null) {
    return null;
  }
  if (orig === null) {
    return curr;
  }
  if (Array.isArray(orig) && Array.isArray(curr)) {
    const res : any[] = [];
    curr.forEach((v) => {
      res.push(v);
    });
    return res;
  }
  if (typeof (orig) === 'object' && typeof (curr) === 'object') {
    const res: AnyObject = {};
    let found = false;
    for (const k of Object.keys(orig)) {
      let d;
      if (k === 'meta') {
        d = diffMeta(orig[k], curr[k]);
      } else if (k === 'value' || k === 'balance') {
        d = diffValue(orig[k], curr[k]);
      } else {
        d = diff(orig[k], curr[k]);
      }
      if (d !== undefined) {
        res[k] = d;
        found = true;
      }
    }
    for (const k of Object.keys(curr)) {
      if (!(k in orig)) {
        res[k] = curr[k];
        found = true;
      }
    }
    if (!found) {
      return undefined;
    }
    return res;
  }
  return curr;
}

export default function (orig: AnyObject, curr: AnyObject) {
  const res = diff(orig, curr);
  if (res === undefined) return {};
  return res;
}
