  <template>
  <k-action-dialog :api-call="apiCall" action="Create" @close="close">
    <v-form v-model="valid">
      <v-text-field label="IBAN" v-model="iban" required />
      <v-text-field label="Bot Name" v-model="botName" required />

      <div v-for="typ in transferTypes" :key="typ">
        <v-checkbox :label="`Supports ${typ} transfers`" :value="typ" v-model="transfer_supported_types" required />
      </div>

      <v-checkbox :label="`Supports manual transactions`" v-model="supports_manual_transactions" required />

      <v-text-field label="Retry delay for transfers in seconds" v-model.number="transfer_failed_soft_retry_delay" type="number" required />
      <v-text-field label="Max number of transfer attempts" v-model.number="transfer_max_attempts" type="number" required />

      <v-text-field label="(Extraction) Max after last external id" v-model.number="extraction_max_after_last_external_id" type="number" required />

      <v-text-field label="External ID" v-model="external_id" type="text" required />
    </v-form>
  </k-action-dialog>
</template>

<script>
import modalMixin from '@/services/modalMixin';
import enums from '@/services/enums';

export default {
  name: 'AccountCreate',
  mixins: [modalMixin],
  data() {
    return {
      valid: null,
      iban: '',
      botName: '',
      external_id: null,
      transfer_failed_soft_retry_delay: 0,
      transfer_max_attempts: 0,
      transfer_supported_types: [],
      extraction_max_after_last_external_id: 0,
      supports_manual_transactions: false,
    };
  },
  computed: {
    transferTypes() {
      return enums.reserve_account_transfer_type;
    },
    apiCall() {
      const body = {
        iban: this.iban,
        bot_name: this.botName,
        transfer_supported_types: this.transfer_supported_types,
        transfer_failed_soft_retry_delay: this.transfer_failed_soft_retry_delay,
        transfer_max_attempts: this.transfer_max_attempts,
        external_id: this.external_id,
        supports_manual_transactions: this.supports_manual_transactions,
      };

      if (this.extraction_max_after_last_external_id !== 0) {
        body.extraction_max_after_last_external_id = this.extraction_max_after_last_external_id;
      }

      return {
        method: 'POST',
        path: '/reserve_accounts',
        body,
      };
    },
  },
};

</script>
