<template>
    <k-action-dialog :api-call="apiCall" action="Delete" @close="close">
        Are you sure?
    </k-action-dialog>
</template>

<script>
import modalMixin from '@/services/modalMixin';
import api from '@/services/api';

export default {
  props: {
    webhookId: String,
  },
  mixins: [modalMixin],
  data() {
    return {
      o: null,
    };
  },
  created() {
    api.kernel.get(`/webhooks/${this.webhookId}`).then((data) => {
      this.o = data;
    });
  },
  computed: {
    apiCall() {
      if (!this.o) return {};
      return {
        method: 'DELETE',
        path: `/webhooks/${this.o.id}`,
      };
    },
  },
};

</script>
