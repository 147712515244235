<template>
    <div>
        <h1>Reconciliation Groups</h1>

        <v-btn color="primary" @click="openCreateModal">Create</v-btn>
        <k-reconciliation-group-create v-if="isCreateModalVisible" @close="closeCreateModal"/>

        <k-list-view :source="source" :allow-download="false">
            <template slot="header">
                <th class="id-column">ID</th>
                <th>Date</th>
            </template>
            <template slot="object" slot-scope="{ o }">
                <router-link :to="`/${myRoute.params.env}/reconciliation_groups/${o.id}`" tag="tr" :key="o.id">
                    <td>{{o.id}}</td>
                    <td>{{o.created_at | moment('DD/MM/YYYY - HH:mm') }}</td>
                </router-link>
            </template>
        </k-list-view>
    </div>
</template>

<script>
import activationMixin from '@/services/activationMixin';
import { useModal } from '@/services/modalMixin';

export default {
  name: 'ReconciliationGroupList',
  mixins: [activationMixin, useModal('Create')],
  computed: {
    source() {
      return {
        env: this.myRoute.params.env,
        path: '/reconciliation_groups',
        query: this.myRoute.query,
      };
    },
  },
};
</script>

<style lang="scss">
</style>
