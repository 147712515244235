<template>
    <div>
        <h1>Reserve transfers</h1>
        <k-filter-set :filters="filters" :query="myRoute.query"/>
        <k-list-view :source="source">
            <template slot="header">
                <th class="id-column">ID</th>
                <th>Date</th>
                <th>Type</th>
                <th>State</th>
                <th>Last attempt</th>
                <th></th>
                <th></th>
                <th class="listable-right">Value</th>
            </template>
            <template slot="object" slot-scope="{ o }">
                <tr class="rowlink" :key="o.id" @click="() => {selectedReserveTransfer = o.id; openReserveTransferDetailModal();}">
                    <td class="fulllink">
                        <k-id :id="o.id"/>
                    </td>
                    <td>{{o.created_at | moment('DD/MM/YYYY - HH:mm') }}</td>
                    <td>{{o.type}}</td>
                    <td>{{o.state}}</td>
                    <td>{{o.latest_reserve_transfer_attempt_state}}</td>
                    <td class="listable-buttons">
                        <div v-if="o.latest_reserve_transfer_attempt_state === 'waiting'">
                          <v-btn color="red" @click.stop.prevent="resolve(o, 'failed_hard')">Failed Hard</v-btn>
                          <v-btn color="green" @click.stop.prevent="resolve(o, 'completed')">Complete</v-btn>
                        </div>
                        <div v-if="o.latest_reserve_transfer_attempt_state === 'processing'">
                            <v-btn color="gray" @click.stop.prevent="resolve(o, 'failed_unsafe')">Failed Unsafe</v-btn>
                        </div>
                        <div v-if="o.latest_reserve_transfer_attempt_state === 'failed_unsafe'">
                            <v-btn color="red" @click.stop.prevent="resolve(o, 'failed_hard')">Failed Hard</v-btn>
                            <v-btn color="orange" @click.stop.prevent="resolve(o, 'failed_soft')">Failed Soft</v-btn>
                            <v-btn color="green" @click.stop.prevent="resolve(o, 'completed')">Complete</v-btn>
                        </div>
                        <div v-if="o.latest_reserve_transfer_attempt_state === 'failed_soft'">
                            <v-btn color="red" @click.stop.prevent="resolve(o, 'failed_hard')">Failed Hard</v-btn>
                            <v-btn color="purple" @click.stop.prevent="retry(o)">Retry</v-btn>
                        </div>
                    </td>
                    <td class="listable-buttons">
                        <div v-if="o.latest_reserve_transfer_attempt_state !== 'waiting' && o.latest_reserve_transfer_attempt_state !== 'processing'">
                            <v-btn color="blue" target="_blank" :href="`https://storage.cloud.google.com/${$global.env.botsBucketName}/${o.latest_reserve_transfer_attempt_id}.txt?authuser=daniel.gomez@kernelpay.com`" @click.stop>L</v-btn>
                        </div>
                    </td>
                    <td class="listable-right">
                        <k-value :value="o.value"/>
                    </td>
                </tr>
            </template>
        </k-list-view>
        <k-reserve-transfer-detail v-if="isReserveTransferDetailModalVisible" @close="closeReserveTransferDetailModal" v-bind:reserve-transfer-id="selectedReserveTransfer"/>
    </div>
</template>

<script>
import api from '@/services/api';
import activationMixin from '@/services/activationMixin';
import enums from '@/services/enums';
import { useModal } from '@/services/modalMixin';

export default {
  name: 'ReserveTransferList',
  mixins: [activationMixin, useModal('ReserveTransferDetail')],
  data() {
    return {
      filters: [
        { key: 'state', name: 'State', type: 'enum', choices: enums.reserve_transfer_state },
        { key: 'type', name: 'Type', type: 'enum', choices: enums.reserve_account_transfer_type },
        { key: 'latest_reserve_transfer_attempt_state', name: 'Attempt state', type: 'enum', choices: enums.reserve_transfer_attempt_state },
        { key: 'created_at', name: 'Created at', type: 'time' },
        { key: 'value.amount', name: 'Value', type: 'int' },
        { key: 'bank_payout_id', name: 'Bank Payout', type: 'id', id_prefix: 'bpo', nullable: true },
      ],
      selectedReserveTransfer: undefined,
    };
  },
  computed: {
    source() {
      return {
        env: this.myRoute.params.env,
        path: '/reserve_transfers',
        query: this.myRoute.query,
      };
    },
  },
  created() {
    if (this.$route.query.id) {
      this.isReserveTransferDetailModalVisible = true;
      this.selectedReserveTransfer = this.$route.query.id;
    }
  },
  methods: {
    resolve(trf, state) {
      api.kernel.patch(`/reserve_transfer_attempts/${trf.latest_reserve_transfer_attempt_id}`, {
        state,
      }).then((data) => {
        // eslint-disable-next-line no-param-reassign
        trf.latest_reserve_transfer_attempt_state = data.state;
      });
    },
    retry(trf) {
      api.kernel.post(`/reserve_transfers/${trf.id}/retry`, {}).then((data) => {
        Object.assign(trf, data);
      });
    },
  },
};
</script>

<style lang="scss">
</style>
