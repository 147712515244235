<template>
    <k-detail-view :source="detailSource">
        <template slot="object" slot-scope="{ o }">
            <h1>Reconciliation group {{o.id}}</h1>
            <v-btn color="red" @click="del">Delete</v-btn>
            <v-btn color="blue" v-if="o.diff_value.amount" @click="set_diff_as_fee">Diff as fee</v-btn>
            <div>
                <b>Difference: </b> <k-value :value="o.diff_value"/>
            </div>
            <h2>Transactions</h2>
            <table class="listable">
                <thead>
                    <tr>
                        <th class="id-column">ID</th>
                        <th>Account ID</th>
                        <th>Type</th>
                        <th class="listable-right">Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="tx in o.transactions" :key="tx.id">
                        <td>
                            {{tx.id}}
                        </td>
                        <td>
                            {{tx.account_id}}
                        </td>
                        <td>
                            {{tx.type}}
                        </td>
                        <td class="listable-right">
                            <k-value sign color :value="tx.value"></k-value>
                        </td>
                    </tr>
                </tbody>
            </table>
            <h2>Reserve transactions</h2>
            <table class="listable">
                <thead>
                    <tr>
                        <th class="id-column">ID</th>
                        <th>Type</th>
                        <th>Concept</th>
                        <th class="listable-right">Value</th>
                    </tr>
                </thead>
                <tbody>
                    <router-link v-for="tx in o.reserve_transactions" :to="`/${myRoute.params.env}/reserve_transactions/${tx.id}`" tag="tr" :key="tx.id">
                        <td>
                            {{tx.id}}
                        </td>
                        <td>
                            {{tx.type}}
                        </td>
                        <td>
                            {{tx.concept}}
                        </td>
                        <td class="listable-right">
                            <k-value sign color :value="tx.value"></k-value>
                        </td>
                    </router-link>
                </tbody>
            </table>
        </template>
    </k-detail-view>
</template>

<script>
import api from '@/services/api';

import activationMixin from '@/services/activationMixin';

export default {
  name: 'ReconciliationGroupDetail',
  mixins: [activationMixin],
  data() {
    return {
      o: null,
    };
  },
  methods: {
    del() {
      api.kernel.delete(`/reconciliation_groups/${this.myRoute.params.id}`).then(() => {
        // eslint-disable-next-line no-alert
        alert('Deleted!');
      }, (error) => {
        // eslint-disable-next-line no-alert
        alert(error);
      });
    },
    set_diff_as_fee() {
      api.kernel.post(`/reconciliation_groups/${this.myRoute.params.id}/set_diff_as_fee`).then(() => {
        api.kernel.get(`/reconciliation_groups/${this.myRoute.params.id}`).then((data) => {
          this.o = data;
        });
      }, (error) => {
        // eslint-disable-next-line no-alert
        alert(error);
      });
    },
  },
  computed: {
    detailSource() {
      return {
        env: this.myRoute.params.env,
        path: `/reconciliation_groups/${this.myRoute.params.id}`,
      };
    },
  },
};
</script>

<style lang="scss">
</style>
