<template>
    <k-action-dialog :api-call="apiCall" action="Create" @close="close">
        <v-form v-model="valid">
            <v-checkbox label="Enabled" v-model="is_enabled" required />
            <v-text-field label="Destination URL" v-model="url" required />
            <h2>Event types</h2>
            <div v-for="(value, key) in event_types" :key="key">
                <v-checkbox :label="key" v-model="event_types[key]" required />
            </div>
        </v-form>

        <template slot="done" slot-scope="{ response }">
            <h1>Webhook created!</h1>

            Copy the webhook secret below. <b v-if="$global.env.id == 'live'">You won't be able to retrieve it again later</b>

            <pre>{{ response.json.secret }}</pre>
        </template>

    </k-action-dialog>
</template>

<script>
import modalMixin from '@/services/modalMixin';
import enums from '@/services/enums';

export default {
  data() {
    const e = {};
    for (const t of enums.event_type) {
      e[t] = false;
    }
    return {
      valid: null,
      url: '',
      is_enabled: true,
      enums,
      event_types: e,
    };
  },
  mixins: [modalMixin],
  computed: {
    apiCall() {
      return {
        method: 'POST',
        path: '/webhooks',
        body: {
          url: this.url,
          is_enabled: this.is_enabled,
          event_types: this.event_types,
        },
      };
    },
  },
};

</script>
