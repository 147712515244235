<template>
    <k-action-dialog :api-call="apiCall" action="Conditional extraction" @close="close">
        <v-form v-model="valid">
          Enter ExternalID whitelist, one per line.
          WARNING: entering an ExternalID that doesn't exist will cause the bot to loop forever until the beginning of time!
          <v-textarea v-model="conditions"/>
        </v-form>

        <v-btn
          color="primary"
          @click="count">
            Count matching rtxs
        </v-btn>
    </k-action-dialog>
</template>

<script>
import api from '@/services/api';
import modalMixin from '@/services/modalMixin';

export default {
  props: {
    reserveAccountId: String,
  },
  mixins: [modalMixin],
  data() {
    return {
      valid: null,
      conditions: '',
    };
  },
  computed: {
    apiCall() {
      const conditions = [];
      for (let condition of this.conditions.split('\n')) {
        condition = condition.trim();
        if (condition !== '') {
          conditions.push(condition);
        }
      }

      const body = {
        conditions,
      };

      return {
        method: 'POST',
        path: `/reserve_accounts/${this.reserveAccountId}/do_conditional_extraction`,
        body,
      };
    },
  },
  methods: {
    count() {
      const conditions = [];
      for (let condition of this.conditions.split('\n')) {
        condition = condition.trim();
        if (condition !== '') {
          conditions.push(condition);
        }
      }

      const body = {
        do_count: true,
        conditions,
      };

      api.kernel.post(`/reserve_accounts/${this.reserveAccountId}/do_conditional_extraction`, body).then((n) => {
        // eslint-disable-next-line no-alert
        alert(`${n} unreconciled reserve transactions matches the criteria!`);
      }, () => {
        // eslint-disable-next-line no-alert
        alert('Something went wrong.');
      });
    },
  },
};
</script>
