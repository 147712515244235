<template>
  <div>
    <h1>Card payins</h1>

    <v-btn color="primary" @click="openCreateModal">Create</v-btn>
    <k-card-payin-create v-if="isCreateModalVisible" @close="closeCreateModal" />

    <v-btn color="primary" @click="openCardTokenCreateModal">Create token</v-btn>
    <k-card-token-create v-if="isCardTokenCreateModalVisible" @close="closeCardTokenCreateModal" />

    <k-filter-set :filters="filters" :query="myRoute.query" />

    <k-list-view :source="source">
      <template slot="header">
        <th class="id-column">ID</th>
        <th class="listable-right">Value</th>
        <th>State</th>
        <th>Acquirer</th>
        <th>Miniref</th>
        <th>Offline</th>
        <th>Card</th>
        <th>Account</th>
        <th class="listable-right">Date</th>
      </template>
      <template slot="object" slot-scope="{ o }">
        <router-link
          class="rowlink"
          :to="`/${myRoute.params.env}/${myRoute.params.project}/card_payins/${o.id}`"
          tag="tr"
          :key="o.id"
        >
          <td class="fulllink">
            <k-id :id="o.id" />
          </td>
          <td class="listable-right">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <k-value :value="o.value" />
                </span>
              </template>
              <span>Refunded Value: <k-value :value="o.refunded_value"/></span>
            </v-tooltip>
          </td>
          <td class="text-center">
            <v-chip small color="green" v-if="o.state == 'authorized' || o.state == 'settled'">
              <v-icon small>
                mdi-checkbox-marked-circle-outline
              </v-icon>
              <!-- {{ o.state }} -->
            </v-chip>
            <v-tooltip left  v-else-if="o.state == 'processing' || o.state == 'authentication_pending'">
                <template v-slot:activator="{ on, attrs }">
                    <v-chip
                    small
                    outlined
                    color="orange"
                    v-bind="attrs"
                    v-on="on"
                    >
                    <v-icon small>
                        mdi-timelapse
                    </v-icon>
                    </v-chip>
                </template>
                {{ o.state.replace("_", " ") }}
            </v-tooltip>

            <v-chip small color="red" v-else-if="o.state == 'authentication_timeout'">
              <v-icon small>
                mdi-timer-off
              </v-icon>
              <!-- {{ o.state.replace("_", " ") }} -->
            </v-chip>
              <v-tooltip left v-else>
                <template v-slot:activator="{ on, attrs }">
                    <v-chip small color="red" v-bind="attrs" v-on="on">
                  <v-icon small>
                    mdi-close-circle-outline
                  </v-icon>
                  <span >
                    <!-- {{ o.state.replace("_", " ") }} -->
                </span>
            </v-chip>
                </template>
                <span
                  >{{ o.decline_code ? o.decline_code.replaceAll('_', ' ') : o.state }}</span>
              </v-tooltip>
          </td>
          <td>
            {{ o.acquiring_backend }}
          </td>
          <td>
            {{ o.miniref }}
          </td>
          <td class="text-center">
            <v-icon color="orange" v-if="o.offline">
              mdi-check
            </v-icon>
            <v-icon color="blue" v-else>
              mdi-close
            </v-icon>
          </td>
          <td>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <span v-if="o.card_id" v-bind="attrs" v-on="on">
                  <k-id-chip
                    :id="o.card_id"
                    :url="`/${myRoute.params.env}/${myRoute.params.project}/cards?id=${o.card_id}`"
                  />
                </span>
                <span v-else-if="o.sca_card_id" v-bind="attrs" v-on="on">
                  {{ o.sca_card_id }}
                </span>
                <span v-else-if="o.card_token_id" v-bind="attrs" v-on="on">
                  {{ o.card_token_id }}
                </span>
                <span v-else v-bind="attrs" v-on="on">
                  {{ o.card_info.number }}
                </span>
              </template>
              <span
                >{{ o.card_info.brand }} {{ o.card_info.number }} <br />
                Exp: {{ o.card_info.expiration_month }}/{{ o.card_info.expiration_year }} - Type:
                {{ o.card_info.funding }} <br />
                Bank: {{ o.card_info.bank }} <br />
                Country: {{ o.card_info.country }}
              </span>
            </v-tooltip>
          </td>
          <td>
            <k-id-chip
              :id="o.account_id"
              :url="`/${myRoute.params.env}/${myRoute.params.project}/accounts/${o.account_id}`"
            />
          </td>
          <td class="listable-right">{{ o.created_at | moment("DD MMM YYYY, HH:mm") }}</td>
        </router-link>
      </template>
    </k-list-view>
  </div>
</template>

<script>
import activationMixin from '@/services/activationMixin';
import enums from '@/services/enums';
import { useModal } from '@/services/modalMixin';

export default {
  name: 'CardPayinList',
  mixins: [activationMixin, useModal('Create'), useModal('CardTokenCreate')],
  data() {
    return {
      filters: [
        { key: 'value.amount', name: 'Value', type: 'int' },
        { key: 'refunded_value.amount', name: 'Refunded value', type: 'int' },
        { key: 'value.currency', name: 'Currency', type: 'enum', choices: enums.currency },
        { key: 'account_id', name: 'Account', type: 'id', id_prefix: 'acc' },
        { key: 'card_id', name: 'Card', type: 'id', id_prefix: 'crd' },
        { key: 'sca_card_id', name: 'SCA Card', type: 'id', id_prefix: 'scacrd' },
        { key: 'state', name: 'State', type: 'enum', choices: enums.card_payin_state },
        {
          key: 'authentication_type',
          name: 'Auth type',
          type: 'enum',
          choices: enums.card_payin_authentication_type,
        },
        { key: 'created_at', name: 'Created at', type: 'time' },
        { key: 'offline', name: 'Offline', type: 'bool' },
        {
          key: 'configure_offline_card',
          name: 'Configure for offline',
          type: 'enum',
          choices: enums.card_offline_configuration,
        },
        { key: 'decline_code', name: 'Decline code', type: 'enum', choices: enums.decline_code },
        { key: 'card_info.number', name: 'Card number', type: 'string' },
        { key: 'card_info.first6', name: 'First 6 digits', type: 'string' },
      ],
    };
  },
  computed: {
    source() {
      return {
        env: this.myRoute.params.env,
        project: this.myRoute.params.project,
        path: '/card_payins',
        query: this.myRoute.query,
      };
    },
  },
};
</script>
