<template>
    <v-flex xs12 sm6 offset-sm3>
        <v-card style="padding: 30px;">
            <v-card-title primary-title>
                <h1>Sign up</h1>
            </v-card-title>
            <v-card-text v-if="emailScreen" >
                Thank you! You have received an email with a confirmation link, please click it.
            </v-card-text>
            <v-card-text v-else>
                <v-form v-model="valid" ref="form" lazy-validation @submit.prevent="submit">
                    <v-text-field
                        label="First name"
                        v-model="first_name"
                        required
                        :rules="nameRules"
                        autofocus
                    />
                    <v-text-field
                        label="Last name"
                        v-model="last_name"
                        required
                        :rules="nameRules"
                    />
                    <v-text-field
                        label="E-mail"
                        v-model="email"
                        required
                        :rules="emailRules"
                    />
                    <v-text-field
                        label="Password"
                        v-model="password"
                        type="password"
                        required
                        :rules="passwordRules"
                    />
                    <v-text-field
                        label="Confirm password"
                        v-model="password2"
                        type="password"
                        required
                        :rules="password2Rules"
                    />
                    <v-btn type="submit" v-if="!processing" :disabled="!valid" >
                        Sign up
                    </v-btn>
                    <v-progress-circular v-if="processing" indeterminate color="primary"></v-progress-circular>
                </v-form>
                <v-alert v-if="error" type="error" :value="true">
                    {{ error.message }}
                </v-alert>
                <div>
                    Already have an account? <router-link to="/login">Log in</router-link>
                </div>
            </v-card-text>
        </v-card>
    </v-flex>
</template>

<script>
import api from '@/services/api';
import * as session from '@/services/session';

export default {
  name: 'Signup',
  data() {
    return {
      valid: true,
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      password2: '',

      emailScreen: false,

      error: null,
      processing: false,
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: [
        (v) => !!v || 'Password is required',
      ],
      nameRules: [
        (v) => !!v || 'Required',
      ],
      password2Rules: [
        (v) => v === this.password || 'Passwords don\'t match',
      ],
    };
  },
  methods: {
    submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.error = null;
      this.processing = true;
      api.userman.post('/signup', {
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        password: this.password,
      }).then((data) => {
        if (data.email_verification_required) {
          this.emailScreen = true;
          localStorage.setItem('email_verification_pending', data.token);
        } else {
          localStorage.setItem('email_verification_pending', '');
          session.setToken(data.token);
          this.$router.push('/');
        }
      }, (error) => {
        this.processing = false;
        this.error = error;
      });
    },
  },
};
</script>
