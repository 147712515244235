<template>
    <div>
        <h1>Projects</h1>

        <v-btn v-if="$config.adminEnabled || $global.env.id !== 'live'" color="primary" router :to="`/${myRoute.params.env}/create`" append>Create</v-btn>
        <k-list-view :source="source">
            <template slot="header">
                <th class="id-column">ID</th>
                <th>Name</th>
            </template>
            <template slot="object" slot-scope="{ o }">
                <router-link :to="`/${myRoute.params.env}/${o.id}/accounts`" tag="tr" :key="o.id">
                    <td class="fulllink">
                        <k-id :id="o.id"/>
                    </td>
                    <td>
                        {{o.meta._name}}
                    </td>
                </router-link>
            </template>
        </k-list-view>
    </div>
</template>

<script>
import activationMixin from '@/services/activationMixin';

export default {
  name: 'ProjectEnvList',
  mixins: [activationMixin],
  computed: {
    source() {
      return {
        env: this.myRoute.params.env,
        path: '/projects',
        query: this.myRoute.query,
      };
    },
  },
};
</script>
