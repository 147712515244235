<template>
  <k-action-dialog :api-call="apiCall" action="Edit" @close="close">
    <v-form v-model="valid" v-if="o">
      <v-checkbox label="Is enabled?" v-model="o.enabled" required />

      <v-checkbox label="Supports SEPA Credit" value="sepa_credit" v-model="supported_types" required />
      <v-checkbox label="Supports SEPA Instant" value="sepa_instant" v-model="supported_types" required />

      Maximum amount (leave it empty to remove):
      <v-text-field
        v-model.number="o.max_amount"
        type="number"
        single-line
        class="shrink"
        style="width: 120px !important; margin-right: 25px"
        required />

      Ignore the following IBANs (leave empty for removal):
      <div v-for="(iban, idx) in o.ignore_ibans" :key="idx">
          <v-layout row wrap>
              <v-flex xs6 style="padding: 0 12px">
                  <v-text-field
                    v-model="o.ignore_ibans[idx]"
                    style="padding: 0"/>
              </v-flex>
          </v-layout>
      </div>
      <v-btn color="green" @click="addIBAN">Add new</v-btn>
    </v-form>
  </k-action-dialog>
</template>

<script>
import api from '@/services/api';
import patch from '@/services/patch';
import modalMixin from '@/services/modalMixin';

export default {
  name: 'BotTransferConfigEdit',
  mixins: [modalMixin],
  props: {
    botTransferConfigId: String,
  },
  data() {
    return {
      orig: null,
      o: null,
      valid: null,
      supported_types: [],
    };
  },
  created() {
    api.kernel.get(`/bot_transfer_configs/${this.botTransferConfigId}`).then((data) => {
      this.o = data;
      if (this.o.supported_types) {
        this.supported_types = this.o.supported_types;
      }
      this.orig = JSON.parse(JSON.stringify(data)); // Deep clone
    });
  },
  computed: {
    apiCall() {
      if (!this.o) return {};

      const o = { ...this.o };

      o.supported_types = this.supported_types;

      if (this.o.max_amount === '') {
        o.max_amount = null;
      }

      o.ignore_ibans = [];
      this.o.ignore_ibans.forEach((iban) => {
        if (iban === '') {
          return;
        }
        o.ignore_ibans.push(iban);
      });

      return {
        method: 'PATCH',
        path: `/bot_transfer_configs/${this.o.id}`,
        body: patch(this.orig, o),
      };
    },
  },
  methods: {
    addIBAN() {
      this.o.ignore_ibans.push('');
    },
  },
};

</script>
