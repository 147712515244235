<template>
  <v-flex xs12 sm6 offset-sm3>
    <v-card style="padding: 30px;">
      <v-card-title primary-title>
        <h1>Forgot password</h1>
      </v-card-title>
      <v-card-text v-if="done">
        Done! Check your email and click the confirmation link in it.
      </v-card-text>
      <v-card-text v-else>
        <v-form v-model="valid" ref="form" lazy-validation @submit.prevent="submit">
          <v-text-field
              label="E-mail"
              v-model="email"
              required
              :rules="emailRules"
              autofocus
          />
          <v-btn type="submit" v-if="!processing" :disabled="!valid" >
              Reset password
          </v-btn>
          <v-progress-circular v-if="processing" indeterminate color="primary"></v-progress-circular>
        </v-form>
        <v-alert v-if="error" type="error" :value="true">
          {{ error.message }}
        </v-alert>
      </v-card-text>
    </v-card>
  </v-flex>
</template>

<script>
import api from '@/services/api';

export default {
  name: 'ForgotPassword',
  data() {
    return {
      valid: true,
      email: '',

      done: false,

      error: null,
      processing: false,
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid',
      ],
    };
  },
  methods: {
    submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.error = null;
      this.processing = true;
      api.userman.post('/forgot_password', {
        email: this.email,
      }).then(() => {
        this.done = true;
      }, (error) => {
        this.processing = false;
        this.error = error;
      });
    },
  },
};
</script>
