<template>
    <k-action-dialog :api-call="apiCall" action="Edit" @close="close">
        <v-form v-model="valid" v-if="o">
            <v-checkbox label="Enabled" v-model="o.is_enabled" required />
            <v-text-field label="Destination URL" v-model="o.url" required />
            <h2>Event types</h2>
            <div v-for="(value, key) in o.event_types" :key="key">
                <v-checkbox :label="key" v-model="o.event_types[key]" required />
            </div>
        </v-form>
    </k-action-dialog>
</template>

<script>
import modalMixin from '@/services/modalMixin';
import api from '@/services/api';
import patch from '@/services/patch';

export default {
  props: {
    webhookId: String,
  },
  mixins: [modalMixin],
  data() {
    return {
      orig: null,
      o: null,
      valid: null,
    };
  },
  created() {
    api.kernel.get(`/webhooks/${this.webhookId}`).then((data) => {
      this.o = data;
      this.orig = JSON.parse(JSON.stringify(data)); // Deep clone
    });
  },
  computed: {
    apiCall() {
      if (!this.o) return {};

      return {
        method: 'PATCH',
        path: `/webhooks/${this.o.id}`,
        body: patch(this.orig, this.o),
      };
    },
  },
};
</script>
