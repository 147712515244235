<template>
  <v-flex xs12 sm6 offset-sm3>
    <v-card style="padding: 30px;">
      <v-card-title primary-title>
        <h1>Email verification</h1>
      </v-card-title>
      <v-card-text>
        <div  v-if="processing">
            Verifying your email...
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>

        <v-alert v-if="error" type="error" :value="true">
            {{ error.message }}
        </v-alert>
      </v-card-text>
    </v-card>
  </v-flex>
</template>

<script>
import api from '@/services/api';
import * as session from '@/services/session';

export default {
  name: 'EmailVerification',
  data() {
    return {
      error: null,
      processing: false,
    };
  },
  created() {
    this.error = null;
    this.processing = true;
    api.userman.post('/email_verification', {
      token: this.$route.params.token,
    }).then(() => {
      const token = localStorage.getItem('email_verification_pending');
      if (token) {
        session.setToken(token);
        this.$router.push('/');
      } else {
        this.$router.push('/login');
      }
      localStorage.setItem('email_verification_pending', '');
    }, (error) => {
      this.processing = false;
      this.error = error;
    });
  },
};
</script>
