<template>
  <div>
    <k-detail-view :source="detailSource">
        <template slot="object" slot-scope="{ o }">
            <h1>Event {{o.id}}</h1>

            <br>

            <div v-for="o in data" :key="o.webhook.id">
              <v-card style="padding: 0 20px;">
                <v-card-title class="headline">
                  Webhook `{{ o.webhook.id }}` event delivery
                </v-card-title>

                <table class="listable">
                  <thead>
                    <tr>
                        <th class="id-column">ID</th>
                        <th>Date</th>
                        <th>State</th>
                        <th>Error</th>
                        <th>Duration</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="delivery in o.deliveries" class="rowlink" :key="delivery.id" @click="() => {selectedWebhookDelivery = delivery.id; selectedWebhookId = o.webhook.id; openWebhookDeliveryDetailModal();}">
                        <td class="fulllink">
                            <k-id :id="delivery.id"/>
                        </td>
                        <td>
                            {{delivery.created_at | moment('DD/MM/YYYY - HH:mm') }}
                        </td>
                        <td>
                            {{delivery.state}}
                        </td>
                        <td>
                            {{delivery.error}}
                        </td>
                        <td>
                          {{delivery.duration_ms}} ms
                        </td>
                    </tr>
                  </tbody>
                </table>
              </v-card>
              <br>
            </div>

            <h2>Raw</h2>
            <div v-highlightjs="o && JSON.stringify(o, null, 2)"></div>
        </template>
    </k-detail-view>
    <k-webhook-delivery-detail v-if="isWebhookDeliveryDetailModalVisible" @close="closeWebhookDeliveryDetailModal" v-bind:webhook-delivery-id="selectedWebhookDelivery" v-bind:webhook-id="selectedWebhookId"/>
  </div>
</template>

<script>
import api from '@/services/api';
import activationMixin from '@/services/activationMixin';
import { useModal } from '@/services/modalMixin';

export default {
  name: 'EventDetail',
  mixins: [activationMixin, useModal('WebhookDeliveryDetail')],
  data() {
    return {
      data: [],
      selectedWebhookDelivery: undefined,
      selectedWebhookId: undefined,
    };
  },
  computed: {
    detailSource() {
      return {
        env: this.myRoute.params.env,
        project: this.myRoute.params.project,
        path: `/events/${this.myRoute.params.id}`,
      };
    },
  },
  created() {
    api.kernel.get(`/events/${this.myRoute.params.id}/deliveries`).then((data) => {
      this.data = data;
    });
  },
};
</script>
