  <template>
  <div>
    <k-detail-view :source="detailSource">
        <template slot="object" slot-scope="{ o }">
            <h1>Card payin dispute {{o.id}}</h1>

            <router-link class="v-btn v-btn--contained theme--dark v-size--default primary" :to="`/${myRoute.params.env}/${myRoute.params.project}/card_payins/${o.card_payin_id}`" tag="button" :key="o.card_payin_id">See Card Payin</router-link>

            <v-btn color="green" v-if="!o.provided_evidence.file_id" disabled>
              No provided evidence
            </v-btn>
            <v-btn color="green btn-evidence-file" v-if="o.provided_evidence.file_id">
              <k-file-view display-text="See provided evidence" :id="o.provided_evidence.file_id"/>
            </v-btn>

            <v-btn color="red" v-if="o.state === 'needs_response'" @click="openCardPayinDisputeAcceptModal">Accept Dispute</v-btn>
            <k-card-payin-dispute-accept v-if="isCardPayinDisputeAcceptModalVisible && o.state === 'needs_response'" v-bind:card-payin-dispute-id="o.id" @close="closeCardPayinDisputeAcceptModal"/>

            <k-file-selector v-model="o.provided_evidence.file_id" v-if="o.state === 'needs_response'"
              @input="updateDisputeFile"/>

            <v-alert v-if="updateFileError" type="error" :value="true">
                Failed to update provided evidence: {{ updateFileError.message }}
            </v-alert>
            <h2>Raw</h2>
            <div v-highlightjs="o && JSON.stringify(o, null, 2)"></div>
        </template>
    </k-detail-view>
  </div>
</template>

<script>
import activationMixin from '@/services/activationMixin';
import { useModal } from '@/services/modalMixin';
import api from '@/services/api';

export default {
  name: 'CardPayinDispute',
  mixins: [
    activationMixin,
    useModal('CardPayinDisputeAccept'),
  ],
  data() {
    return {
      updateFileError: null,
    };
  },
  methods: {
    updateDisputeFile(fileId) {
      this.updateFileError = null;

      const body = {
        provided_evidence: {
          file_id: fileId,
        },
      };

      api.kernel.patch(`/card_payin_disputes/${this.myRoute.params.id}`, body).then(() => {}, (error) => {
        this.updateFileError = error;
      });
    },
  },
  computed: {
    detailSource() {
      return {
        env: this.myRoute.params.env,
        project: this.myRoute.params.project,
        path: `/card_payin_disputes/${this.myRoute.params.id}`,
        query: {
          expand: 'card_payin',
        },
      };
    },
  },
};
</script>

<style>
  .btn-evidence-file a {
    color: white;
    text-decoration: none;
  }
</style>
