<template>
    <k-dialog title="Card" :big="true" @close="close">
        <k-detail-view :source="detailSource">
            <template slot="object" slot-scope="{ o }">
               <div v-highlightjs="o && JSON.stringify(o, null, 2)"></div>
            </template>
        </k-detail-view>
    </k-dialog>
</template>

<script>
import modalMixin from '@/services/modalMixin';
import activationMixin from '@/services/activationMixin';

export default {
  name: 'CardDetail',
  mixins: [activationMixin, modalMixin],
  props: ['cardId'],
  computed: {
    detailSource() {
      return {
        env: this.myRoute.params.env,
        path: `/cards/${this.cardId}`,
      };
    },
  },
};
</script>
