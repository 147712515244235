<template>
    <k-dialog title="Customer" @close="close">
        <k-detail-view :source="detailSource">
            <template slot="object" slot-scope="{ o }">
                <v-btn color="primary" :to="`/${myRoute.params.env}/${myRoute.params.project}/accounts?customer_id=${o.id}`" @click="close">See Accounts</v-btn>
                <v-btn color="primary" :to="`/${myRoute.params.env}/${myRoute.params.project}/cards?customer_id=${o.id}`" @click="close">See Cards</v-btn>
                <v-btn color="primary" :to="`/${myRoute.params.env}/${myRoute.params.project}/bank_accounts?customer_id=${o.id}`" @click="close">See Bank Accounts</v-btn>
                <div class="proplist">
                    <div class="prop"><b>ID</b><span>{{o.id}}</span></div>
                    <template v-if="!o.identity">
                        <div class="prop"><b>Identity</b><span>No identity</span></div>
                    </template>
                    <template v-else>
                        <h1>Identity</h1>
                        <div class="prop"><b>First name</b><span>{{o.identity.first_name}}</span></div>
                        <div class="prop"><b>Last name</b><span>{{o.identity.last_name}}</span></div>
                        <div class="prop"><b>Email address</b><span>{{o.identity.email_address}}</span></div>
                        <div class="prop"><b>Phone number</b><span>{{o.identity.phone_number}}</span></div>
                        <div class="prop"><b>Nationality</b><span>{{o.identity.nationality}}</span></div>
                        <div class="prop"><b>Employment status</b><span>{{o.identity.employment_status}}</span></div>

                        <template v-if="!o.identity.date_of_birth.day">
                            <div class="prop"><b>Date of birth</b><span>No date of birth</span></div>
                        </template>
                        <template v-else>
                            <div class="prop"><b>Date of birth</b><span>{{o.identity.date_of_birth.day}} / {{ o.identity.date_of_birth.month }} / {{o.identity.date_of_birth.year}}</span></div>
                        </template>

                        <template v-if="!o.identity.address">
                            <div class="prop"><b>Address</b><span>No address</span></div>
                        </template>
                        <template v-else>
                            <h1>Address</h1>
                            <div class="prop"><b>Line 1</b><span>{{o.identity.address.line_1}}</span></div>
                            <div class="prop"><b>Line 2</b><span>{{o.identity.address.line_2}}</span></div>
                            <div class="prop"><b>Postal_code</b><span>{{o.identity.address.postal_code}}</span></div>
                            <div class="prop"><b>City</b><span>{{o.identity.address.city}}</span></div>
                            <div class="prop"><b>Region</b><span>{{o.identity.address.region}}</span></div>
                            <div class="prop"><b>Country</b><span>{{o.identity.address.country}}</span></div>
                        </template>

                        <template v-if="!o.identity.document">
                            <div class="prop"><b>Document</b><span>No document</span></div>
                        </template>
                        <template v-else>
                            <h1>Document</h1>
                            <div class="prop"><b>Type</b><span>{{o.identity.document.type}}</span></div>
                            <div class="prop"><b>Country</b><span>{{o.identity.document.country}}</span></div>
                            <div class="prop"><b>Number</b><span>{{o.identity.document.number}}</span></div>
                            <div class="prop"><b>Expiration date</b><span>{{o.identity.document.expiration_date}}</span></div>
                            <div class="prop"><b>Front picture</b><span><k-file-view :id="o.identity.document.front_file_id"/></span></div>
                            <div class="prop"><b>Back picture</b><span><k-file-view :id="o.identity.document.back_file_id"/></span></div>
                            <div class="prop"><b>Selfie picture</b><span><k-file-view :id="o.identity.document.selfie_file_id"/></span></div>
                        </template>
                    </template>
                    <template v-if="isEmpty(o.meta)">
                        <div class="prop"><b>Meta</b><span>No metadata</span></div>
                    </template>
                    <template v-else>
                        <h1>Meta</h1>
                        <div v-for="(v, k) in o.meta" :key="k" class="prop"><b>{{k}}</b><span>{{v}}</span></div>
                    </template>
                </div>
                <v-btn :to="`/${myRoute.params.env}/${myRoute.params.project}/customers/${o.id}/edit`">Edit</v-btn>
                <v-btn v-if="$config.adminEnabled" @click="exportData">Export Data</v-btn>
            </template>
        </k-detail-view>
    </k-dialog>
</template>

<script>
import modalMixin from '@/services/modalMixin';
import activationMixin from '@/services/activationMixin';
import api from '@/services/api';

export default {
  name: 'CustomerDetail',
  mixins: [activationMixin, modalMixin],
  props: {
    customerId: String,
  },
  methods: {
    isEmpty(o) {
      return !o || Object.keys(o).length === 0;
    },
    exportData() {
      const body = {
        project_id: this.$global.project.id,
        customer_id: this.customerId,
      };
      api.kernel.post('/data_exporter', body).then((resp) => {
        window.alert(`User data export is processing with id: ${resp.id}`);
      }).catch(console.error);
    },
  },
  computed: {
    detailSource() {
      return {
        env: this.myRoute.params.env,
        project: this.myRoute.params.project,
        path: `/customers/${this.customerId}`,
      };
    },
  },
};
</script>

<style lang="scss">
.proplist {
    > h1 {
        padding-left: 150px;
        font-size: 20px;
    }
    > .prop {
        > b {
            display: inline-block;
            text-align: right;
            padding: 5px 5px;
            width: 150px;
        }
        > span {
            display: inline-block;
        }
    }
}
</style>
