<template>
    <div>
    <div class="page-header">
        <h1>Banking Connections</h1>
        <v-btn rounded color="primary" @click="openCreateModal">
          <v-icon left>mdi-plus</v-icon>
          Create
        </v-btn>
    </div>

        <k-filter-set :filters="filters" :query="myRoute.query"/>
        <k-banking-connection-create v-if="isCreateModalVisible" @close="closeCreateModal"/>

        <k-list-view :source="source">
            <template slot="header">
                <th class="id-column">ID</th>
                <th>Owner</th>
                <th colspan="2">Institution</th>
                <th>State</th>
                <th class="listable-right">Last Refresh</th>
            </template>
            <template slot="object" slot-scope="{ o }">
              <router-link class="rowlink" :to="`/${myRoute.params.env}/${myRoute.params.project}/banking_products?banking_connection_id=${o.id}`" tag="tr" :key="o.id">
                    <td class="fulllink">
                        <k-id :id="o.id"/>
                    </td>
                    <td v-if="o.customer">
                        <span v-if="o.customer.identity">
                            <k-id :id="o.customer.id"/> {{o.customer.identity.first_name}} {{o.customer.identity.last_name}}
                        </span>
                        <span v-else><k-id long :id="o.customer.id"/></span>
                    </td>
                    <td v-else-if="o.business">
                        <span>
                            <k-id :id="o.business.id"/> {{o.business.trading_name}}
                        </span>
                    </td>
                    <td v-else>
                        N/A
                    </td>
                    <td class="logo-column">
                      <img :src="o.banking_institution.meta._image_url" class="logo" alt="logo"/>
                    </td>
                    <td>
                        {{o.banking_institution.meta._name}}
                    </td>
                    <td>
                      {{ o.state }}
                    </td>
                    <td class="listable-right">{{ o.updated_at | moment("DD MMM YYYY, HH:mm") }}</td>
              </router-link>
            </template>
        </k-list-view>
        <!-- <k-iban-detail v-if="isIBANDetailModalVisible" @close="closeIBANDetailModal" v-bind:iban-id="selectedConnection"/> -->
    </div>
</template>

<script>
import activationMixin from '@/services/activationMixin';
import { useModal } from '@/services/modalMixin';

export default {
  name: 'BankingConnectionList',
  mixins: [activationMixin, useModal('Create')],
  data() {
    return {
      filters: [
        { key: 'banking_institution_id', name: 'Institution', type: 'id', id_prefix: 'bi' },
        { key: 'created_at', name: 'Created at', type: 'time' },
      ],
      selectedConnection: undefined,
    };
  },
  computed: {
    source() {
      return {
        env: this.myRoute.params.env,
        project: this.myRoute.params.project,
        path: '/banking_connections',
        query: {
          ...this.myRoute.query,
          expand: 'customer,business',
        },
      };
    },
  },
};
</script>
