<template>
    <k-action-dialog :api-call="apiCall" action="Activate Connection Method" @close="close">
          <v-form v-model="valid">
              <v-radio-group v-model="activation_type">
              <v-radio label="Assign to current institution" value="current"/>
              <v-radio label="Create new institution" value="new"/>
          </v-radio-group>
          <div v-if="activation_type === 'current'">
            <k-banking-institution-selector label='Banking Institution' v-model="banking_institution_id" required />
          </div>
          <div v-if="activation_type === 'new'">
            <v-text-field label="Code Override" v-model="code_override"/>
            Meta Override:
            <br>
            <br>
            <v-row>
              <v-col>
                <k-meta-edit v-model="meta_override"></k-meta-edit>
              </v-col>
            </v-row>
          </div>
        </v-form>
    </k-action-dialog>
</template>

<script>
import modalMixin from '@/services/modalMixin';

export default {
  props: {
    connectionMethod: Object,
  },
  data() {
    return {
      activation_type: 'current',
      banking_institution_id: null,
      code_override: this.connectionMethod.provider_name,
      meta_override: this.connectionMethod.meta,
    };
  },
  mixins: [modalMixin],
  computed: {
    apiCall() {
      const body = {};

      if (this.activation_type === 'current') {
        body.banking_institution_id = this.banking_institution_id;
      } else {
        body.code_override = this.code_override;
        body.meta_override = this.meta_override;
      }

      return {
        method: 'POST',
        path: `/banking_connection_methods/${this.connectionMethod.id}/activate`,
        body,
      };
    },
  },
};

</script>
