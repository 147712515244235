import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import global from '@/global';
import api from '@/services/api';
import * as session from '@/services/session';
import { Config } from '@/config.model';

// eslint-disable-next-line import/no-unresolved
const config: Config = require('config').default;

Vue.use(VueRouter);

function p(scope: string, path: string, component: string) {
  return {
    path,
    component: () => import(/* webpackMode: "eager" */ `@/views/${component}`),
    meta: {
      scope,
    },
  };
}

const routes = [];
routes.push(p('nil', '/login', 'Login.vue'));
routes.push(p('nil', '/logout', 'Logout.vue'));
routes.push(p('nil', '/signup', 'Signup.vue'));
routes.push(p('nil', '/email_verification/:token([a-zA-Z0-9_]+)', 'EmailVerification.vue'));
routes.push(p('nil', '/forgot_password', 'ForgotPassword.vue'));
routes.push(p('nil', '/forgot_password/:token([a-zA-Z0-9_]+)', 'ForgotPasswordFinish.vue'));
routes.push(p('usr', '/', 'Projects.vue'));
routes.push(p('usr', '/change_password', 'ChangePassword.vue'));
routes.push(p('env', '/:env([a-z-]+)', 'ProjectsEnv.vue'));
routes.push(p('env', '/:env([a-z-]+)/create', 'ProjectCreate.vue'));

if (config.adminEnabled) {
  routes.push(p('env', '/:env([a-z-]+)/reserve_accounts', 'ReserveAccounts.vue'));
  routes.push(p('env', '/:env([a-z-]+)/reserve_accounts/:id', 'ReserveAccount.vue'));
  routes.push(p('env', '/:env([a-z-]+)/reserve_account_extractions', 'ReserveAccountExtractions.vue'));
  routes.push(p('env', '/:env([a-z-]+)/reserve_transfers', 'ReserveTransfers.vue'));
  routes.push(p('env', '/:env([a-z-]+)/reserve_transactions/:id', 'ReserveTransaction.vue'));
  routes.push(p('env', '/:env([a-z-]+)/reconciliation_groups', 'ReconciliationGroups.vue'));
  routes.push(p('env', '/:env([a-z-]+)/reconciliation_groups/:id', 'ReconciliationGroup.vue'));
  routes.push(p('env', '/:env([a-z-]+)/system_check', 'SystemChecks.vue'));
  routes.push(p('env', '/:env([a-z-]+)/system_check/:id', 'SystemCheck.vue'));
  routes.push(p('env', '/:env([a-z-]+)/data_exporter', 'DataExporter.vue'));
  routes.push(p('env', '/:env([a-z-]+)/unwanted_card_payins', 'UnwantedCardPayins.vue'));
  routes.push(p('env', '/:env([a-z-]+)/bot_transfer_configs', 'BotTransferConfigs.vue'));
  routes.push(p('env', '/:env([a-z-]+)/banking_institutions', 'BankingInstitutionsAdmin.vue'));
  routes.push(p('env', '/:env([a-z-]+)/banking_connection_methods', 'BankingConnectionMethodsAdmin.vue'));
  routes.push(p('env', '/:env([a-z-]+)/banking_credentials', 'BankingCredentialsAdmin.vue'));
  routes.push(p('env', '/:env([a-z-]+)/banking_credential_extractions', 'BankingCredentialExtractionsAdmin.vue'));
}

routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/general', 'General.vue'));
routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/api_keys', 'APIKeys.vue'));
routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/users', 'Users.vue'));
routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/accounts', 'Accounts.vue'));
routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/accounts/:id', 'Account.vue'));
routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/businesses', 'Businesses.vue'));
routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/customers', 'Customers.vue'));
routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/customers/:id/edit', 'CustomerEdit.vue'));
routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/transfers', 'Transfers.vue'));
routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/card_payins', 'CardPayins.vue'));
routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/card_payins/:id', 'CardPayin.vue'));
routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/card_payin_disputes', 'CardPayinDisputes.vue'));
routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/card_payin_disputes/:id', 'CardPayinDispute.vue'));
routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/cards', 'Cards.vue'));
routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/sca_cards', 'SCACards.vue'));
routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/bank_payins', 'BankPayins.vue'));
routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/bank_payin_references', 'BankPayinReferences.vue'));
routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/ibans', 'IBANs.vue'));
routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/bank_payouts', 'BankPayouts.vue'));
routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/bank_payouts/:id', 'BankPayout.vue'));
routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/bank_accounts', 'BankAccounts.vue'));
routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/banking_institutions', 'BankingInstitutions.vue'));
routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/banking_connections', 'BankingConnections.vue'));
routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/banking_products', 'BankingProducts.vue'));
routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/banking_products/:id', 'BankingProduct.vue'));
routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/files', 'Files.vue'));
routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/events', 'Events.vue'));
routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/events/:id', 'Event.vue'));
routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/virtual_accounts', 'VirtualAccounts.vue'));
routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/virtual_accounts/:id', 'VirtualAccount.vue'));
routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/virtual_currencies', 'VirtualCurrencies.vue'));
routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/virtual_transfers', 'VirtualTransfers.vue'));
routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/webhooks', 'Webhooks.vue'));
routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/webhooks/:id', 'Webhook.vue'));
routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/fees', 'Fees.vue'));
routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/fees/:id', 'Fee.vue'));
routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/acquiring_denylist/cards', 'AcquiringDenylistCardNumbers.vue'));
routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/acquiring_denylist/bins', 'AcquiringDenylistBINs.vue'));
routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/recurring_payments', 'RecurringPayments.vue'));
routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/scheduled_payments', 'ScheduledPayments.vue'));
routes.push(p('prj', '/:env([a-z-]+)/:project(prj_[a-z0-9]+)/*', 'NotFound.vue'));
routes.push(p('env', '/:env([a-z-]+)/*', 'NotFound.vue'));
routes.push(p('none', '*', 'NotFound.vue'));

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

api.events.$on('unauthorized', () => {
  if (router.currentRoute.path === '/login') {
    return;
  }

  if (session.hasToken()) {
    session.cleanSessionData();
  }

  router.replace({
    path: '/login',
  });
});

router.beforeEach((to: Route, from: Route, next: any) => {
  if (to.meta?.scope !== 'nil' && config.usermanEnabled && !session.hasToken()) {
    router.replace({
      path: '/login',
      query: { redirect: to.fullPath },
    });
    return;
  }

  global.scope = to.meta?.scope;

  if (to.meta?.scope === 'prj' || to.meta?.scope === 'env') {
    global.env = global.envs[to.params.env];
  } else {
    global.env = null;
  }

  if (to.meta?.scope === 'env') {
    api.envs[to.params.env].get('/environment').then((data: any) => {
      for (const [key, value] of Object.entries(data)) {
        global.envs[to.params.env][key] = value;
      }
    });
  }

  if (to.meta?.scope === 'prj') {
    if (global.project && global.project.id === to.params.project) {
      next();
    } else {
      api.kernel.get(`/projects/${to.params.project}`).then((data: any) => {
        global.project = data;

        return api.kernel.get('/virtual_currencies');
      }).then((data: any) => {
        const virtualCurrenciesByCode: any = {};
        for (const c of data.data) {
          virtualCurrenciesByCode[c.code] = c;
          global.projectVirtualCurrenciesCodes.push(c.code);
        }
        global.projectVirtualCurrenciesByCode = virtualCurrenciesByCode;

        return api.kernel.get('/environment');
      }).then((data: any) => {
        for (const [key, value] of Object.entries(data)) {
          global.envs[to.params.env][key] = value;
        }
      })
        .catch((error: any) => {
          // eslint-disable-next-line no-console
          console.log(error);
        })
        .finally(next);
    }
  } else {
    global.project = null;
    global.projectVirtualCurrenciesByCode = null;
    global.projectVirtualCurrenciesCodes = [];
    next();
  }
});

export default router;
