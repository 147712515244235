<template>
  <v-flex xs12 sm6 offset-sm3>
    <v-card style="padding: 30px;">
      <v-card-title primary-title>
        <h1>Forgot password</h1>
      </v-card-title>
      <v-card-text v-if="checking">
        <div v-if="processing">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
        <v-alert v-if="error" type="error" :value="true">
          {{ error.message }}
        </v-alert>
      </v-card-text>
      <v-card-text v-else-if="done">
        <div>
          Your password has been reset.
        </div>
        <v-btn color="primary" router to="/login" append>Log in</v-btn>
      </v-card-text>
      <v-card-text v-else>
        <v-form v-model="valid" ref="form" lazy-validation @submit.prevent="submit">
          <v-text-field
              label="New password"
              v-model="password"
              type="password"
              required
              :rules="passwordRules"
          />
          <v-text-field
              label="Confirm password"
              v-model="password2"
              type="password"
              required
              :rules="password2Rules"
          />
          <v-btn type="submit" v-if="!processing" :disabled="!valid" >
            Reset password
          </v-btn>
          <v-progress-circular v-if="processing" indeterminate color="primary"></v-progress-circular>
        </v-form>
        <v-alert v-if="error" type="error" :value="true">
          {{ error.message }}
        </v-alert>
      </v-card-text>
    </v-card>
  </v-flex>
</template>

<script>
import api from '@/services/api';

export default {
  name: 'ForgotPasswordFinish',
  data() {
    return {
      error: null,
      processing: false,
      checking: true,
      done: false,
      valid: true,

      password: '',
      password2: '',

      passwordRules: [
        (v) => !!v || 'Password is required',
      ],
      password2Rules: [
        (v) => v === this.password || 'Passwords don\'t match',
      ],
    };
  },
  created() {
    this.error = null;
    this.processing = true;
    api.userman.post('/forgot_password/check', {
      token: this.$route.params.token,
    }).then(() => {
      this.processing = false;
      this.checking = false;
    }, (error) => {
      this.processing = false;
      this.error = error;
    });
  },
  methods: {
    submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.error = null;
      this.processing = true;
      api.userman.post('/forgot_password/finish', {
        token: this.$route.params.token,
        new_password: this.password,
      }).then(() => {
        this.processing = true;
        this.done = true;
      }, (error) => {
        this.processing = false;
        this.error = error;
      });
    },
  },
};
</script>
