<template>
    <k-action-dialog :api-call="apiCall" action="Add BIN" @close="close">
        <v-form v-model="valid">
            BIN:
            <v-text-field label="BIN" v-model="bin" />
        </v-form>
    </k-action-dialog>
</template>

<script>
import modalMixin from '@/services/modalMixin';

export default {
  data() {
    return {
      valid: null,
      bin: '',
    };
  },
  mixins: [modalMixin],
  computed: {
    apiCall() {
      const body = {
        bin: this.bin,
      };

      return {
        method: 'POST',
        path: '/acquiring_denylist/bin',
        body,
      };
    },
  },
};

</script>

<style scoped>
pre {
    display: block;
    background: black;
    color: #ddd;
    padding: 20px;
    word-wrap: break-word;
    white-space: pre-wrap;
}
</style>
