<template>
    <k-action-dialog :api-call="apiCall" action="Refund" @close="close">
        <v-form v-model="valid">
            <v-radio-group v-model="type">
                <v-radio label="Full refund" value="full"/>
                <v-radio label="Partial refund" value="partial"/>
            </v-radio-group>
            <k-value-selector v-if="type==='partial'" label="Amount" v-model="value" required ></k-value-selector>
            <v-radio-group v-model="account">
                <v-radio label="From same account as the card payin" value="same"/>
                <v-radio label="From another account" value="other"/>
            </v-radio-group>
            <k-account-selector v-if="account==='other'" label="Source account" v-model="account_id" required />
        </v-form>
    </k-action-dialog>
</template>

<script>
import modalMixin from '@/services/modalMixin';

export default {
  props: {
    cardPayinId: String,
  },
  mixins: [modalMixin],
  data() {
    return {
      valid: null,
      type: 'full',
      account: 'same',
      account_id: null,
      value: {
        amount: 100,
        currency: 'EUR',
      },
    };
  },
  computed: {
    apiCall() {
      const body = {};
      if (this.type === 'partial') {
        body.value = this.value;
      }
      if (this.account === 'other') {
        body.account_id = this.account_id;
      }
      return {
        method: 'POST',
        path: `/card_payins/${this.cardPayinId}/refunds`,
        body,
      };
    },
  },
};

</script>
