<template>
    <div>
        <h1>Data exports</h1>

        <k-filter-set :filters="filters" :query="myRoute.query"/>

        <k-list-view :source="source">
            <template slot="header">
                <th class="id-column">ID</th>
                <th class="id-column">CustomerID</th>
                <th>State</th>
                <th>Created At</th>
                <th>Updated At</th>
                <th></th>
            </template>
            <template slot="object" slot-scope="{ o }">
                <tr>
                    <td class="fulllink">
                        <k-id :id="o.id"/>
                    </td>
                    <td class="fulllink">
                        <k-id :id="o.customer_id"/>
                    </td>
                    <td>
                        {{ o.state }}
                    </td>
                    <td>
                        {{ o.created_at | moment('DD/MM/YYYY - HH:mm') }}
                    </td>
                    <td>
                        {{ o.updated_at | moment('DD/MM/YYYY - HH:mm') }}
                    </td>
                    <td class="listable-buttons" v-if="o.download_url">
                        <v-btn style="padding: 0 15px" color="green" :href="o.download_url">Download</v-btn>
                    </td>
                </tr>
            </template>
        </k-list-view>
    </div>
</template>

<script>
import activationMixin from '@/services/activationMixin';

export default {
  name: 'DataExporterList',
  mixins: [activationMixin],
  data() {
    return {
      error: null,
      filters: [
        { key: 'customer_id', name: 'Customer', type: 'id', id_prefix: 'cus' },
      ],
    };
  },
  computed: {
    source() {
      return {
        env: this.myRoute.params.env,
        path: '/data_exporter',
        query: this.myRoute.query,
      };
    },
  },
};
</script>
