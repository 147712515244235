<template>
    <div>
        <h1>Unwanted card payins</h1>
        <k-filter-set :filters="filters" :query="myRoute.query"/>
        <k-list-view :source="source">
            <template slot="header">
                <th class="id-column">ID</th>
                <th>Date</th>
                <th>State</th>
                <th></th>
                <th class="listable-right">Value</th>
            </template>
            <template slot="object" slot-scope="{ o }">
                <router-link class="rowlink" :to="`/${myRoute.params.env}/reserve_transactions/${o.id}`" tag="tr" :key="o.id">
                    <td class="fulllink">
                        <k-id :id="o.id"/>
                    </td>
                    <td>{{o.created_at | moment('DD/MM/YYYY - HH:mm') }}</td>
                    <td>{{o.card_payin_unwanted_state}}</td>
                    <td class="listable-buttons">
                        <div v-if="o.card_payin_unwanted_state === 'requires_action'">
                            <v-btn color="green" @click.stop.prevent="patch(o, 'refund_pending')">Refund</v-btn>
                            <v-btn color="orange" @click.stop.prevent="patch(o, 'refund_discarded')">Ignore</v-btn>
                        </div>
                    </td>
                    <td class="listable-right">
                        <k-value :value="o.value"/>
                    </td>
                </router-link>
            </template>
        </k-list-view>
    </div>
</template>

<script>
import api from '@/services/api';
import activationMixin from '@/services/activationMixin';
import enums from '@/services/enums';

export default {
  name: 'UnwantedCardPayinList',
  mixins: [activationMixin],
  data() {
    return {
      filters: [
        { key: 'card_payin_unwanted_state', name: 'State', type: 'enum', choices: enums.card_payin_unwanted_state, fixed: true },
        { key: 'created_at', name: 'Created at', type: 'time' },
        { key: 'value.amount', name: 'Value', type: 'int' },
      ],
    };
  },
  computed: {
    source() {
      return {
        env: this.myRoute.params.env,
        path: '/reserve_transactions',
        query: {
          ...this.myRoute.query,
        },
      };
    },
  },
  methods: {
    patch(rtx, state) {
      api.kernel.patch(`/reserve_transactions/${rtx.id}`, {
        card_payin_unwanted_state: state,
      }).then((data) => {
        // eslint-disable-next-line no-param-reassign
        rtx.card_payin_unwanted_state = data.card_payin_unwanted_state;
      });
    },
  },
};
</script>
