<template>
    <div>
        <h1>Bank accounts</h1>

        <v-btn color="primary" @click="openCreateModal">Create</v-btn>
        <k-bank-account-create v-if="isCreateModalVisible" @close="closeCreateModal"/>
        <k-bank-account-edit v-if="isBankAccountEditModalVisible" @close="closeBankAccountEditModal" v-bind:bank-account-id="selectedBankAccount"/>

        <k-filter-set :filters="filters" :query="myRoute.query"/>

        <k-list-view :source="source">
            <template slot="header">
                <th class="id-column">ID</th>
                <th>IBAN</th>
                <th>Beneficiary name</th>
                <th>Business</th>
                <th>Customer</th>
                <th></th>
            </template>
            <template slot="object" slot-scope="{ o }">
                <tr class="rowlink" :key="o.id" @click="() => {selectedBankAccount = o.id; openBankAccountDetailModal();}">
                    <td class="fulllink">
                        <k-id :id="o.id"/>
                    </td>
                    <td>
                        {{o.bank_account_info.iban}}
                    </td>
                    <td>
                        {{o.bank_account_info.beneficiary_name}}
                    </td>
                    <td>
                        {{o.business_id}}
                    </td>
                    <td>
                        {{o.customer_id}}
                    </td>
                    <td class="listable-buttons">
                        <v-btn color="primary" @click.prevent.stop="() => {selectedBankAccount = o.id; openBankAccountEditModal();}">Edit</v-btn>
                    </td>
                </tr>
            </template>
        </k-list-view>
        <k-bank-account-detail v-if="isBankAccountDetailModalVisible" @close="closeBankAccountDetailModal" v-bind:bank-account-id="selectedBankAccount"/>
    </div>
</template>

<script>
import activationMixin from '@/services/activationMixin';
import { useModal } from '@/services/modalMixin';

export default {
  name: 'BankAccountList',
  mixins: [activationMixin, useModal('Create'), useModal('BankAccountDetail'), useModal('BankAccountEdit')],
  data() {
    return {
      filters: [
        { key: 'bank_account_info.iban', name: 'IBAN', type: 'string' },
        { key: 'business_id', name: 'Business', type: 'id', id_prefix: 'bus', nullable: true },
        { key: 'customer_id', name: 'Customer', type: 'id', id_prefix: 'cus', nullable: true },
        { key: 'created_at', name: 'Created at', type: 'time' },
      ],
      selectedBankAccount: undefined,
    };
  },
  computed: {
    source() {
      return {
        env: this.myRoute.params.env,
        project: this.myRoute.params.project,
        path: '/bank_accounts',
        query: this.myRoute.query,
      };
    },
  },
};
</script>
