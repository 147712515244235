<template>
  <div>
    <k-detail-view :source="detailSource">
        <template slot="object" slot-scope="{ o }">
            <h1>Fee {{o.id}}</h1>

            <router-link v-if="$config.adminEnabled && o.reserve_transaction_id" class="v-btn v-btn--contained theme--dark v-size--default primary" :to="`/${myRoute.params.env}/reserve_transactions/${o.reserve_transaction_id}`" tag="button" :key="o.reserve_transaction_id">See Reserve Transaction</router-link>

            <h2>Raw</h2>
            <div v-highlightjs="o && JSON.stringify(o, null, 2)"></div>
        </template>
    </k-detail-view>
  </div>
</template>

<script>
import activationMixin from '@/services/activationMixin';

export default {
  name: 'FeeDetail',
  mixins: [activationMixin],
  computed: {
    detailSource() {
      return {
        env: this.myRoute.params.env,
        project: this.myRoute.params.project,
        path: `/fees/${this.myRoute.params.id}`,
      };
    },
  },
};
</script>
