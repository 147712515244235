<template>
  <div>
    <k-detail-view :source="detailSource">
        <template slot="object" slot-scope="{ o }">
            <h1>{{o.meta._name || `Account ${o.id}`}}</h1>

            <v-btn color="primary" @click="openJSONDetailModal">See account json</v-btn>
            <k-json-detail title="Account json detail" :object="o" v-if="isJSONDetailModalVisible" @close="closeJSONDetailModal"/>

            <k-filter-set :filters="filters" :query="myRoute.query"/>

            <k-list-view :source="source">
                <template slot="header">
                    <th class="id-column">ID</th>
                    <th>Date</th>
                    <th>Concept</th>
                    <th class="listable-right">Amount</th>
                    <th class="listable-right">Balance</th>
                </template>
                <template slot="object" slot-scope="{ o }">
                    <tr class="rowlink" :key="o.id" @click="() => {selectedTransaction = o.id; openTransactionDetailModal();}">
                        <td class="fulllink">
                            <k-id :id="o.id"/>
                        </td>
                        <td>{{o.created_at | moment('DD/MM/YYYY') }}</td>
                        <td>{{o.concept}}</td>
                        <td class="listable-right">
                            <k-value sign color :value="o.value"></k-value>
                        </td>
                        <td class="listable-right">
                            <k-value :value="o.balance_after"></k-value>
                        </td>
                    </tr>
                </template>
            </k-list-view>
        </template>
    </k-detail-view>

    <k-transaction-detail v-if="isTransactionDetailModalVisible" @close="closeTransactionDetailModal" v-bind:transaction-id="selectedTransaction"/>
  </div>
</template>

<script>
import activationMixin from '@/services/activationMixin';
import enums from '@/services/enums';
import { useModal } from '@/services/modalMixin';

export default {
  name: 'BankingProductDetail',
  mixins: [
    activationMixin,
    useModal('JSONDetail'),
  ],
  data() {
    return {
      filters: [
        { key: 'value.amount', name: 'Value', type: 'int' },
        { key: 'value.currency', name: 'Currency', type: 'enum', choices: enums.currency },
        { key: 'type', name: 'Type', type: 'enum', choices: enums.transaction_type },
        { key: 'created_at', name: 'Created at', type: 'time' },
      ],
      selectedTransaction: undefined,
    };
  },
  computed: {
    detailSource() {
      return {
        env: this.myRoute.params.env,
        project: this.myRoute.params.project,
        path: `/banking_products/${this.myRoute.params.id}`,
      };
    },
    source() {
      return {
        env: this.myRoute.params.env,
        project: this.myRoute.params.project,
        path: '/banking_transactions',
        query: {
          banking_product_id: this.myRoute.params.id,
          ...this.myRoute.query,
        },
      };
    },
  },
};
</script>
