<template>
  <div>
    <k-detail-view :source="detailSource">
        <template slot="object" slot-scope="{ o }">
            <h1>Webhook {{myRoute.params.id}}</h1>

            <v-btn color="primary" @click="openWebhookEditModal">Edit</v-btn>
            <k-webhook-edit v-if="isWebhookEditModalVisible" @close="closeWebhookEditModal" v-bind:webhook-id="myRoute.params.id"/>

            <v-btn color="primary" @click="openWebhookDeleteModal">Delete</v-btn>
            <k-webhook-delete v-if="isWebhookDeleteModalVisible" @close="closeWebhookDeleteModal" v-bind:webhook-id="myRoute.params.id"/>

            URL: {{o.url}}
            Secret: {{o.secret}}
            <h2>Deliveries</h2>
            <k-list-view :source="source">
                <template slot="header">
                    <th class="id-column">ID</th>
                    <th>State</th>
                    <th>Error</th>
                    <th>Duration</th>
                </template>
                <template slot="object" slot-scope="{ o }">
                    <tr class="rowlink" :key="o.id" @click="() => {selectedWebhookDelivery = o.id; openWebhookDeliveryDetailModal();}">
                        <td class="fulllink">
                            <k-id :id="o.id"/>
                        </td>
                        <td>
                            {{o.state}}
                        </td>
                        <td>
                            {{o.error}}
                        </td>
                        <td>
                          {{o.duration_ms}} ms
                        </td>
                    </tr>
                </template>
            </k-list-view>
        </template>
    </k-detail-view>
    <k-webhook-delivery-detail v-if="isWebhookDeliveryDetailModalVisible" @close="closeWebhookDeliveryDetailModal" v-bind:webhook-delivery-id="selectedWebhookDelivery"/>
  </div>
</template>

<script>
import activationMixin from '@/services/activationMixin';
import { useModal } from '@/services/modalMixin';

export default {
  name: 'WebhookDetail',
  mixins: [activationMixin, useModal('WebhookEdit'), useModal('WebhookDelete'), useModal('WebhookDeliveryDetail')],
  computed: {
    detailSource() {
      return {
        env: this.myRoute.params.env,
        project: this.myRoute.params.project,
        path: `/webhooks/${this.myRoute.params.id}/deliveries`,
        query: this.myRoute.query,
      };
    },
    source() {
      return {
        env: this.myRoute.params.env,
        project: this.myRoute.params.project,
        path: `/webhooks/${this.myRoute.params.id}/deliveries`,
        query: this.myRoute.query,
      };
    },
  },
  data() {
    return {
      selectedWebhookDelivery: undefined,
    };
  },
};
</script>
