<template>
  <div>
      <h1>BIN denylist</h1>

      <v-btn color="primary" @click="openCreateModal">Create</v-btn>
      <k-acquiring-denylist-bin-create v-if="isCreateModalVisible" @close="closeCreateModal"/>

      <k-filter-set :filters="filters" :query="myRoute.query"/>

      <k-list-view :source="source">
          <template slot="header">
              <th class="id-column">ID</th>
              <th>Date</th>
              <th>BIN</th>
              <th class="listable-right">Actions</th>
          </template>
          <template slot="object" slot-scope="{ o }">
              <tr @click="() => { selectedAcquiringDenylistBIN = o.id; openAcquiringDenylistBINDetailModal() }" class="rowlink">
                <td class="fulllink">
                    <k-id :id="o.id"/>
                </td>
                <td>{{o.created_at | moment('DD/MM/YYYY - HH:mm') }}</td>
                <td>
                    {{o.bin}}
                </td>
                <td class="listable-right">
                  <v-btn color="primary" @click.prevent.stop="del(o.id)">Delete</v-btn>
                </td>
              </tr>
          </template>
      </k-list-view>
      <k-acquiring-denylist-bin-detail v-if="isAcquiringDenylistBINDetailModalVisible" @close="closeAcquiringDenylistBINDetailModal" v-bind:acquiringDenylistBINId="selectedAcquiringDenylistBIN"/>
  </div>
</template>

<script>
import api from '@/services/api';
import activationMixin from '@/services/activationMixin';
import { useModal } from '@/services/modalMixin';

export default {
  name: 'AcquiringDenylistBINList',
  mixins: [activationMixin, useModal('AcquiringDenylistBINDetail'), useModal('Create')],
  data() {
    return {
      filters: [
        { key: 'bin', name: 'BIN', type: 'string' },
        { key: 'created_at', name: 'Created at', type: 'time' },
      ],
      selectedAcquiringDenylistBIN: undefined,
    };
  },
  created() {
    if (this.$route.query.id) {
      this.isAcquiringDenylistBINDetailModalVisible = true;
      this.selectedAcquiringDenylistBIN = this.$route.query.id;
    }
  },
  methods: {
    del(id) {
      api.kernel.delete(`/acquiring_denylist/bin/${id}`).then(() => {
        this.$globalEventBus.$emit('object-edited');
      }, (error) => {
        // eslint-disable-next-line no-alert
        alert(error);
      });
    },
  },
  computed: {
    source() {
      return {
        env: this.myRoute.params.env,
        project: this.myRoute.params.project,
        path: '/acquiring_denylist/bin',
        query: this.myRoute.query,
      };
    },
  },
};
</script>
