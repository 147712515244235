import Vue from 'vue';

import global from '@/global';
import { Config, Environment } from '@/config.model';

// eslint-disable-next-line import/no-unresolved
const config: Config = require('config').default;

function serialize(obj: { [key: string]: any }) {
  return Object.entries(obj).map((x) => x.map(encodeURIComponent).join('=')).join('&');
}

const apiEventBus = new Vue();

function makeClient(env: Environment) {
  function buildHeaders() {
    const headers = new Headers();
    if (global.token) {
      headers.append('Authorization', `Bearer ${global.token}`);
    }
    if (global.project) {
      headers.append('X-Kernel-Project', global.project.id);
    }

    if (env.headers) {
      for (const k of Object.keys(env.headers)) {
        headers.append(k, env.headers[k]);
      }
    }
    return headers;
  }

  function rawRequest(options: any) {
    let body;

    if (options.files) {
      body = new FormData();
      for (const [key, value] of Object.entries(options.files)) {
        body.append(key, value as any);
      }
    } else {
      body = JSON.stringify(options.body);
    }

    const finalOptions: any = {
      method: options.method,
      body,
      mode: env.cors ? 'cors' : 'same-origin',
      credentials: env.cors ? 'omit' : 'same-origin',
      headers: buildHeaders(),
    };

    return fetch(env.url + options.path, finalOptions);
  }

  async function request(options: any) {
    try {
      const response = await rawRequest(options);
      if (response.status === 401) {
        apiEventBus.$emit('unauthorized');
      }
      if (response.status >= 400 && response.status < 600) {
        const json = await response.json();
        return Promise.reject(json);
      }
      return response.json();
    } catch (err) {
      throw new Error('Error connecting to the server. Check your internet connection.');
    }
  }

  return {
    get(path: string, params?: { [key: string]: string }) {
      let path2 = path;
      if (params) path2 += `?${serialize(params)}`;
      return request({
        path: path2,
        method: 'GET',
      });
    },
    post(path: string, body: any) {
      return request({
        path,
        method: 'POST',
        body,
      });
    },
    postFile(path: string, file: any) {
      return request({
        path,
        method: 'POST',
        files: {
          file,
        },
      });
    },
    patch(path: string, body: any) {
      return request({
        path,
        method: 'PATCH',
        body,
      });
    },
    delete(path: string) {
      return request({
        path,
        method: 'DELETE',
      });
    },
    raw(options: any) {
      return rawRequest(options);
    },
  };
}

const envs: any = {};

for (const id of Object.keys(global.envs)) {
  const env = global.envs[id];
  envs[id] = makeClient(env);
}

export default {
  envs,
  get kernel() {
    return envs[global.env.id];
  },
  userman: makeClient(config.userman as any),
  events: apiEventBus,
};
