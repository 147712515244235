<template>
    <k-detail-view :source="detailSource">
        <template slot="object" slot-scope="{ o }">
            <h1>Card payin {{o.id}}</h1>

            <v-btn color="primary" @click="openCardPayinRefundCreateModal">Create refund</v-btn>
            <k-card-payin-refund-create v-if="isCardPayinRefundCreateModalVisible" @close="closeCardPayinRefundCreateModal" v-bind:card-payin-id="myRoute.params.id"/>

            <v-btn color="primary" v-if="$global.env.id === 'sandbox' || $global.env.id === 'local'" @click="openCardPayinSettleModal">Settle</v-btn>
            <k-card-payin-settle v-if="isCardPayinSettleModalVisible" @close="closeCardPayinSettleModal" v-bind:card-payin-id="myRoute.params.id"/>

            <h2>Refunds</h2>
            <k-filter-set :filters="filters" :query="myRoute.query"/>
            <k-list-view :source="source">
                <template slot="header">
                    <th class="id-column">ID</th>
                    <th>State</th>
                    <th>ARN</th>
                    <th>Transaction</th>
                    <th>Failure Transaction</th>
                    <th class="listable-right">Value</th>
                    <th></th>
                </template>
                <template slot="object" slot-scope="{ o }">
                    <tr :key="o.id">
                        <td class="fulllink">
                            <k-id :id="o.id"/>
                        </td>
                        <td>
                            {{o.state}}
                        </td>
                        <td>
                            {{o.arn}}
                        </td>
                        <td>
                            {{o.transaction_id}}
                        </td>
                        <td>
                            {{o.failure_transaction_id}}
                        </td>
                        <td class="listable-right">
                            <k-value :value="o.value"/>
                        </td>
                        <td class="listable-buttons">
                            <v-btn v-if="o.state === 'processing' && $config.adminEnabled" color="red" @click.stop.prevent="cancel(o)">Cancel</v-btn>
                        </td>
                    </tr>
                </template>
            </k-list-view>
            <h2>Raw</h2>
            <div v-highlightjs="o && JSON.stringify(o, null, 2)"></div>
        </template>
    </k-detail-view>
</template>

<script>
import api from '@/services/api';
import activationMixin from '@/services/activationMixin';
import enums from '@/services/enums';
import { useModal } from '@/services/modalMixin';

export default {
  name: 'CardPayinDetail',
  mixins: [activationMixin, useModal('CardPayinRefundCreate'), useModal('CardPayinSettle')],
  data() {
    return {
      filters: [
        { key: 'value.amount', name: 'Value', type: 'int' },
        { key: 'value.currency', name: 'Currency', type: 'enum', choices: enums.currency },
        { key: 'account_id', name: 'Account', type: 'id', id_prefix: 'acc' },
        { key: 'card_payin_id', name: 'Card payin', type: 'id', id_prefix: 'cpi' },
        { key: 'state', name: 'State', type: 'enum', choices: enums.card_payin_refund_state },
        { key: 'created_at', name: 'Created at', type: 'time' },
      ],
    };
  },
  computed: {
    detailSource() {
      return {
        env: this.myRoute.params.env,
        project: this.myRoute.params.project,
        path: `/card_payins/${this.myRoute.params.id}`,
      };
    },
    source() {
      return {
        env: this.myRoute.params.env,
        project: this.myRoute.params.project,
        path: `/card_payins/${this.myRoute.params.id}/refunds`,
        query: this.myRoute.query,
      };
    },
  },
  methods: {
    cancel(cpr) {
      api.kernel.post(`/card_payins/${cpr.card_payin_id}/refunds/${cpr.id}/cancel`, {}).then((data) => {
        Object.assign(cpr, data);
      });
    },
  },
};
</script>
