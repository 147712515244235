<template>
    <k-action-dialog :api-call="apiCall" :disable-devmode="true" action="Edit" @close="close">
        <v-text-field label="API Key ID" v-model="apiKeyId" required disabled/>
        <v-text-field label="Name" v-model="meta._name"/>
        <v-simple-table v-if="type === 'secret'">
          <template v-slot:default>
            <thead>
              <tr>
                <th>
                  Resource
                </th>
                <th>
                  Permission
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(resource, index) in resources"
                :key="index"
              >
                <td>{{ resource }}</td>
                <td>
                  <v-radio-group
                    row
                    @change="(value) => updatePermission(resource, value)"
                    :value="getPermissionValue(resource)"
                  >
                    <v-radio
                      label="None"
                      value="none"
                    />
                    <v-radio
                      label="Read only"
                      value="read"
                    />
                    <v-radio
                      label="Read and write"
                      value="read_write"
                      :disabled="resource === 'transactions' || resource === 'virtual_transactions' || resource === 'project'"
                    />
                  </v-radio-group>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <template slot="done">
            <h1>API Key has been edited!</h1>
        </template>
    </k-action-dialog>
</template>

<script>
import api from '@/services/api';
import modalMixin from '@/services/modalMixin';
import enums from '@/services/enums';

export default {
  props: {
    apiKeyId: String,
  },
  data() {
    return {
      valid: null,
      meta: {
        _name: '',
      },
      touchedPerms: {},
      permissions: [],
      enums,
      type: undefined,
    };
  },
  created() {
    api.kernel.get(`/api_keys/${this.apiKeyId}`).then((data) => {
      this.touchedPerms = data.permissions.reduce((acc, cur) => {
        const [resource, permission] = cur.split(':');
        return ({ ...acc, [resource]: permission });
      }, {});
      this.meta = data.meta;
      this.permissions = data.permissions;
      this.type = data.type;
    });
  },
  mixins: [modalMixin],
  computed: {
    apiCall() {
      return {
        method: 'PATCH',
        path: `/api_keys/${this.apiKeyId}`,
        body: {
          meta: this.meta,
          permissions: this.permissions,
        },
      };
    },
    resources() {
      return enums.resource.filter((x) => !(['users', 'api_keys'].includes(x)));
    },
  },
  methods: {
    updatePermission(res, perm) {
      this.touchedPerms[res] = perm;
      this.permissions = Object.entries(this.touchedPerms).map(([resource, permission]) => `${resource}:${permission}`);
    },
    getPermissionValue(resource) {
      const permission = this.touchedPerms[resource];
      if (permission === undefined) {
        return 'none';
      }
      return permission;
    },
  },
};
</script>
