<template>
    <div>
        <h1>Cards</h1>

        <v-btn color="primary" @click="openCreateModal">Create</v-btn>
        <k-card-create v-if="isCreateModalVisible" @close="closeCreateModal"/>

        <v-btn color="primary" @click="openCardTokenCreateModal">Create token</v-btn>
        <k-card-token-create v-if="isCardTokenCreateModalVisible" @close="closeCardTokenCreateModal"/>

        <k-filter-set :filters="filters" :query="myRoute.query"/>

        <k-list-view :source="source">
            <template slot="header">
                <th class="id-column">ID</th>
                <th>Date</th>
                <th>Card number</th>
                <th>Customer</th>
            </template>
            <template slot="object" slot-scope="{ o }">
                <tr @click="() => { selectedCard = o.id; openCardDetailModal() }" class="rowlink">
                  <td class="fulllink">
                      <k-id :id="o.id"/>
                  </td>
                  <td>{{o.created_at | moment('DD/MM/YYYY - HH:mm') }}</td>
                  <td>
                      {{o.card_info.number}}
                  </td>
                  <td>
                      {{o.customer_id}}
                  </td>
                </tr>
            </template>
        </k-list-view>
        <k-card-detail v-if="isCardDetailModalVisible" @close="closeCardDetailModal" v-bind:cardId="selectedCard"/>
    </div>
</template>

<script>
import activationMixin from '@/services/activationMixin';
import { useModal } from '@/services/modalMixin';
import enums from '@/services/enums';

export default {
  name: 'CardList',
  mixins: [activationMixin, useModal('CardDetail'), useModal('Create'), useModal('CardTokenCreate')],
  data() {
    return {
      filters: [
        { key: 'customer_id', name: 'Customer', type: 'id', id_prefix: 'cus', nullable: true },
        { key: 'created_at', name: 'Created at', type: 'time' },
        { key: 'card_info.number', name: 'Card number', type: 'string' },
        { key: 'card_info.first6', name: 'First 6 digits', type: 'string' },
        { key: 'offline_configuration', name: 'Offline configuration', type: 'enum', choices: enums.card_offline_configuration },
      ],
      selectedCard: undefined,
    };
  },
  created() {
    if (this.$route.query.id) {
      this.isCardDetailModalVisible = true;
      this.selectedCard = this.$route.query.id;
    }
  },
  computed: {
    source() {
      return {
        env: this.myRoute.params.env,
        project: this.myRoute.params.project,
        path: '/cards',
        query: this.myRoute.query,
      };
    },
  },
};
</script>
