<template>
    <div>
        <h1>Accounts</h1>

        <v-btn color="primary" @click="openCreateModal">Create</v-btn>
        <k-account-create v-if="isCreateModalVisible" @close="closeCreateModal"/>

        <k-filter-set :filters="filters" :query="myRoute.query"/>

        <k-list-view :source="source" :starred-tab="true">
            <template slot="header">
                <th style="width: 50px"></th>
                <th class="id-column">ID</th>
                <th>Name</th>
                <th>Owner</th>
                <th class="listable-right">Balance</th>
            </template>
            <template slot="object" slot-scope="{ o }">
                <router-link class="rowlink" :to="`/${myRoute.params.env}/${myRoute.params.project}/accounts/${o.id}`" tag="tr" :key="o.id">
                    <td @click.stop="" @click="toggleStarred(o)">
                      <v-icon class="star" :color="o.starred ? 'yellow' : 'white'">star</v-icon>
                    </td>
                    <td class="fulllink">
                        <k-id :id="o.id"/>
                    </td>
                    <td>
                       <span v-if="o.allow_negative_balance" class="text-red">
                            {{o.meta._name}}
                        </span>
                        <span v-else>{{o.meta._name}}</span>
                    </td>
                    <td v-if="o.customer">
                        <span v-if="o.customer.identity">
                            <k-id :id="o.customer.id"/> {{o.customer.identity.first_name}} {{o.customer.identity.last_name}}
                        </span>
                        <span v-else><k-id long :id="o.customer.id"/></span>
                    </td>
                    <td v-else-if="o.business">
                        <span>
                            <k-id :id="o.business.id"/> {{o.business.trading_name}}
                        </span>
                    </td>
                    <td v-else>
                        N/A
                      </td>
                    <td class="listable-right">
                        <k-value :value="o.balance"/>
                    </td>
                </router-link>
            </template>
        </k-list-view>
    </div>
</template>

<style scoped>
  .star {
    font-size: 30px;
    -moz-transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);
    -o-transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);
    -webkit-transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);
    transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);
  }
</style>

<script>
import api from '@/services/api';
import activationMixin from '@/services/activationMixin';
import enums from '@/services/enums';
import { useModal } from '@/services/modalMixin';

export default {
  name: 'AccountList',
  mixins: [activationMixin, useModal('Create')],
  data() {
    return {
      filters: [
        { key: 'balance.amount', name: 'Balance', type: 'int' },
        { key: 'value.currency', name: 'Currency', type: 'enum', choices: enums.currency },
        { key: 'allow_negative_balance', name: 'Allow negative balance', type: 'bool' },
        { key: 'business_id', name: 'Business', type: 'id', id_prefix: 'bus', nullable: true },
        { key: 'customer_id', name: 'Customer', type: 'id', id_prefix: 'cus', nullable: true },
        { key: 'created_at', name: 'Created at', type: 'time' },
      ],
    };
  },
  methods: {
    toggleStarred(account) {
      api.kernel.patch(`/accounts/${account.id}`, { starred: !account.starred }).then((data) => {
        Object.assign(account, data);
      });
    },
  },
  computed: {
    source() {
      return {
        env: this.myRoute.params.env,
        project: this.myRoute.params.project,
        path: '/accounts',
        query: {
          ...this.myRoute.query,
          expand: 'customer,business',
        },
      };
    },
  },
};
</script>

<style lang="scss">
.text-red {
    color: orange;
}
.filter-op, .filter-value {
    background: rgba(0, 0, 0, 0.1);
    line-height: 34px;
    padding: 0 10px;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    &:hover {
        background: rgba(0, 0, 0, 0.3);
    }
}
</style>
