<template>
    <k-action-dialog :api-call="apiCall" action="Create card" @close="close">
        <v-form v-model="valid">
            <k-customer-selector label="Customer" v-model="customer_id" required />

            <v-radio-group v-model="source">
                <v-radio label="Card info" value="card_info"/>
                <v-radio label="Card token" value="card_token"/>
            </v-radio-group>
            <div v-if="source === 'card_info'">
                <k-card-field v-model="card_info" />
            </div>
            <div v-if="source === 'card_token'">
                <v-text-field label="Card token ID" v-model="card_token_id" />
            </div>
        </v-form>
    </k-action-dialog>
</template>

<script>
import modalMixin from '@/services/modalMixin';

export default {
  data() {
    return {
      valid: null,
      source: 'card_info',
      card_token_id: null,
      card_info: {
        number: '',
        expiration_month: '',
        expiration_year: '',
        cvc: '',
      },
      customer_id: null,
    };
  },
  mixins: [modalMixin],
  computed: {
    apiCall() {
      const body = {
        customer_id: this.customer_id,
      };
      if (this.source === 'card_info') {
        body.card_info = this.card_info;
      }
      if (this.source === 'card_token') {
        body.card_token_id = this.card_token_id;
      }
      return {
        method: 'POST',
        path: '/cards',
        body,
      };
    },
  },
};

</script>
