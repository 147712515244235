<template>
  <k-action-dialog :api-call="apiCall" :disable-devmode="true" action="Perform" @close="close">
    <v-btn color="primary" @click="selectAll">Select all</v-btn>
    <v-form v-model="valid">
      <v-checkbox
        v-for="(check, index) in $global.env.system_checks"
        v-bind:key="index"
        v-model="selected[index]"
        :label="check"
        @change="checkboxUpdated($event, index)"/>
    </v-form>
  </k-action-dialog>
</template>

<script>
import Vue from 'vue';
import modalMixin from '@/services/modalMixin';

export default {
  name: 'SystemCheckPerform',
  mixins: [modalMixin],
  data() {
    return {
      valid: null,
      selected: {},
    };
  },
  computed: {
    apiCall() {
      const body = {
        checks: null,
      };

      if (Object.keys(this.selected).length !== this.$global.env.system_checks.length) {
        body.checks = [];

        Object.entries(this.selected).forEach((entry) => {
          body.checks.push(this.$global.env.system_checks[entry[0]]);
        });
      }

      return {
        method: 'POST',
        path: '/system_check',
        body,
      };
    },
  },
  methods: {
    selectAll() {
      for (let i = 0; i < this.$global.env.system_checks.length; i += 1) {
        Vue.set(this.selected, i, true);
      }
    },
    checkboxUpdated(selected, index) {
      if (selected === true) {
        Vue.set(this.selected, index, true);
      } else {
        Vue.delete(this.selected, index);
      }
    },
  },
};
</script>

<style>
  .v-input--checkbox, .v-input__slot {
    margin: 0 !important;
  }
</style>
