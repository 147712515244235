<template>
    <div>
        <h1>Reserve accounts</h1>

        <v-btn color="primary" @click="openCreateModal">Create</v-btn>
        <k-reserve-account-create v-if="isCreateModalVisible" @close="closeCreateModal"/>
        <k-reserve-account-edit v-if="isEditModalVisible"
        @close="closeEditModal" v-bind:reserve-account-id="selectedReserveAccount"/>

        <k-list-view :source="source">
            <template slot="header">
                <th class="id-column">ID</th>
                <th>Bot</th>
                <th>Supports transfer types</th>
                <th>IBAN</th>
                <th class="listable-right">Balance</th>
                <th class="listable-right">Actions</th>
            </template>
            <template slot="object" slot-scope="{ o }">
                <router-link :to="`/${myRoute.params.env}/reserve_accounts/${o.id}`" tag="tr" :key="o.id">
                    <td class="fulllink">
                        <k-id :id="o.id"/>
                    </td>
                    <td>
                        {{o.bot_name}}
                    </td>
                    <td>
                        {{o.transfer_supported_types}}
                    </td>
                    <td>
                        {{o.iban}}
                    </td>
                    <td class="listable-right">
                        <k-value :value="o.balance"/>
                    </td>
                    <td class="listable-right">
                      <v-btn color="primary" @click.prevent.stop="() => {selectedReserveAccount = o.id; openEditModal();}">Edit</v-btn>
                    </td>
                </router-link>
            </template>
        </k-list-view>
    </div>
</template>

<script>
import activationMixin from '@/services/activationMixin';
import { useModal } from '@/services/modalMixin';

export default {
  name: 'ReserveAccountList',
  mixins: [activationMixin, useModal('Create'), useModal('Edit')],
  computed: {
    source() {
      return {
        env: this.myRoute.params.env,
        path: '/reserve_accounts',
        query: this.myRoute.query,
      };
    },
  },
};
</script>
