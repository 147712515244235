<template>
    <div>
        <h1>Bank payin references</h1>

        <v-btn color="primary" @click="openCreateModal">Create</v-btn>
        <k-bank-payin-reference-create v-if="isCreateModalVisible" @close="closeCreateModal"/>

        <k-filter-set :filters="filters" :query="myRoute.query"/>

        <k-list-view :source="source">
            <template slot="header">
                <th class="id-column">ID</th>
                <th>Reference</th>
                <th>Account</th>
            </template>
            <template slot="object" slot-scope="{ o }">
                <tr class="rowlink" :key="o.id" @click="() => {selectedBankPayinReference = o.id; openBankPayinReferenceDetailModal();}">
                    <td class="fulllink">
                        <k-id :id="o.id"/>
                    </td>
                    <td>
                        {{o.reference}}
                    </td>
                    <td>
                        <router-link :to="`/${myRoute.params.env}/${myRoute.params.project}/accounts/${o.account_id}`" tag="a">
                            {{o.account_id}}
                        </router-link>
                    </td>
                </tr>
            </template>
        </k-list-view>
        <k-bank-payin-reference-detail v-if="isBankPayinReferenceDetailModalVisible" @close="closeBankPayinReferenceDetailModal" v-bind:bank-payin-reference-id="selectedBankPayinReference"/>
    </div>
</template>

<script>
import activationMixin from '@/services/activationMixin';
import { useModal } from '@/services/modalMixin';

export default {
  name: 'BankPayinReferenceList',
  mixins: [activationMixin, useModal('Create'), useModal('BankPayinReferenceDetail')],
  data() {
    return {
      filters: [
        { key: 'reference', name: 'Reference', type: 'string' },
        { key: 'account_id', name: 'Account', type: 'id', id_prefix: 'acc' },
        { key: 'created_at', name: 'Created at', type: 'time' },
      ],
      selectedBankPayinReference: undefined,
    };
  },
  computed: {
    source() {
      return {
        env: this.myRoute.params.env,
        project: this.myRoute.params.project,
        path: '/bank_payin_references',
        query: this.myRoute.query,
      };
    },
  },
};
</script>
