<template>
    <k-action-dialog :api-call="apiCall" action="Create reconciliation group" @close="close">
        <v-form v-model="valid">
            Enter Transaction or ReserveTransaction IDs, one per line
            <v-textarea v-model="items"/>
        </v-form>
    </k-action-dialog>
</template>

<script>
import modalMixin from '@/services/modalMixin';

export default {
  data() {
    return {
      valid: null,
      items: '',
    };
  },
  mixins: [modalMixin],
  computed: {
    apiCall() {
      const body = {
        transaction_ids: [],
        reserve_transaction_ids: [],
      };
      for (const id of this.items.split('\n')) {
        if (id.startsWith('tx_')) {
          body.transaction_ids.push(id);
        }
        if (id.startsWith('rstx_')) {
          body.reserve_transaction_ids.push(id);
        }
      }
      return {
        method: 'POST',
        path: '/reconciliation_groups',
        body,
      };
    },
  },
};

</script>
