<template>
    <div>
        <h1>Webhooks</h1>

        <v-btn color="primary" @click="openCreateModal">Create</v-btn>
        <k-webhook-create v-if="isCreateModalVisible" @close="closeCreateModal"/>

        <k-filter-set :filters="filters" :query="myRoute.query"/>

        <k-list-view :source="source" :allow-download="false">
            <template slot="header">
                <th class="id-column">ID</th>
                <th>URL</th>
                <th>Events</th>
            </template>
            <template slot="object" slot-scope="{ o }">
                <router-link class="rowlink" :to="`/${myRoute.params.env}/${myRoute.params.project}/webhooks/${o.id}`" tag="tr" :key="o.id">
                    <td class="fulllink">
                        <k-id :id="o.id"/>
                    </td>
                    <td>
                        {{o.url}}
                    </td>
                    <td>
                        {{ eventNames(o.event_types) }}
                    </td>
                </router-link>
            </template>
        </k-list-view>
    </div>
</template>

<script>
import activationMixin from '@/services/activationMixin';
import { useModal } from '@/services/modalMixin';

export default {
  name: 'WebhookList',
  mixins: [activationMixin, useModal('Create')],
  data() {
    return {
      filters: [
        { key: 'is_enabled', name: 'Enabled', type: 'bool' },
      ],
    };
  },
  methods: {
    eventNames(e) {
      return Object.entries(e).filter((o) => o[1]).map((o) => o[0]).join(', ');
    },
  },
  computed: {
    source() {
      return {
        path: '/webhooks',
        query: {
          ...this.myRoute.query,
        },
      };
    },
  },
};
</script>
