<template>
    <v-flex xs12 sm6 offset-sm3>
        <v-card style="padding: 30px;">
            <v-card-title primary-title>
                <h1>Change password</h1>
            </v-card-title>
            <v-card-text v-if="done" >
                Password changed successfully!
            </v-card-text>
            <v-card-text v-else>
                <v-form v-model="valid" ref="form" lazy-validation @submit.prevent="submit">
                    <v-text-field
                        label="Current password"
                        v-model="old_password"
                        type="password"
                        required
                        :rules="passwordRules"
                    />
                    <v-text-field
                        label="New password"
                        v-model="new_password"
                        type="password"
                        required
                        :rules="passwordRules"
                    />
                    <v-text-field
                        label="Confirm password"
                        v-model="new_password2"
                        type="password"
                        required
                        :rules="password2Rules"
                    />
                    <v-btn type="submit" v-if="!processing" :disabled="!valid" >
                        Change password
                    </v-btn>
                    <v-progress-circular v-if="processing" indeterminate color="primary"></v-progress-circular>
                </v-form>
                <v-alert v-if="error" type="error" :value="true">
                    {{ error.message }}
                </v-alert>
                <div>
                    <router-link to="/forgot_password">Forgot password</router-link>
                </div>
            </v-card-text>
        </v-card>
    </v-flex>
</template>

<script>
import api from '@/services/api';

export default {
  name: 'ChangePassword',
  data() {
    return {
      valid: true,
      old_password: '',
      new_password: '',
      new_password2: '',

      done: false,

      error: null,
      processing: false,
      passwordRules: [
        (v) => !!v || 'Password is required',
      ],
      password2Rules: [
        (v) => v === this.new_password || 'Passwords don\'t match',
      ],
    };
  },
  methods: {
    submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.error = null;
      this.processing = true;
      api.userman.post('/password', {
        old_password: this.old_password,
        new_password: this.new_password,
      }).then(() => {
        this.done = true;
      }, (error) => {
        this.processing = false;
        this.error = error;
      });
    },
  },
};
</script>
