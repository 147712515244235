<template>
    <k-dialog title="Event" @close="close">
        <k-detail-view :source="detailSource">
            <template slot="object" slot-scope="{ o }">
                <div class="proplist">
                    <div class="prop"><b>ID</b><span>{{o.id}}</span></div>
                    <div class="prop"><b>Created at</b><span>{{o.created_at}}</span></div>
                    <div class="prop"><b>Type</b><span>{{o.type}}</span></div>
                </div>
                <h2>Event JSON</h2>
                <div v-highlightjs="o && JSON.stringify(o, null, 2)"></div>
            </template>
        </k-detail-view>
    </k-dialog>
</template>

<script>
import modalMixin from '@/services/modalMixin';
import activationMixin from '@/services/activationMixin';

export default {
  name: 'EventDetail',
  props: {
    eventId: String,
  },
  mixins: [activationMixin, modalMixin],
  computed: {
    detailSource() {
      return {
        env: this.myRoute.params.env,
        project: this.myRoute.params.project,
        path: `/events/${this.eventId}`,
      };
    },
  },
};
</script>

<style lang="scss">
.proplist {
    > h1 {
        padding-left: 150px;
        font-size: 20px;
    }
    > .prop {
        > b {
            display: inline-block;
            text-align: right;
            padding: 5px 5px;
            width: 150px;
        }
        > span {
            display: inline-block;
        }
    }
}
</style>
