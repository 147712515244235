import Vue from 'vue';

export default Vue.extend({
  methods: {
    close() {
      this.$emit('close');
    },
  },
});

export function useModal(name: string, defaultValue: boolean = false) {
  const varName = `is${name}ModalVisible`;
  return Vue.extend({
    methods: {
      [`open${name}Modal`]() {
        (this as any)[varName] = true;
      },
      [`close${name}Modal`]() {
        (this as any)[varName] = false;
      },
    },
    data() {
      return {
        [varName]: defaultValue,
      };
    },
  });
}
