  <template>
  <k-action-dialog :api-call="apiCall" action="Create" @close="close">
    <v-form v-model="valid">
      <k-reserve-manual-transaction-type-selector label="Type" v-model="type" required />
      <v-text-field label="IBAN" v-model="bank_account_info.iban" />
      <v-text-field label="Beneficiary name" v-model="bank_account_info.beneficiary_name" />
      Amount
      <div style="padding: 10px;">
        <k-value-selector label="Amount" v-model="value" required />
      </div>
      Balance after
      <div style="padding: 10px;">
        <k-value-selector label="Balance after" v-model="balance_after" required />
      </div>
      <v-text-field label="Concept" v-model="concept" required />
      <v-text-field label="External Id" v-model="external_id" required />
      <v-textarea label="Raw" v-model="raw" required />
    </v-form>
  </k-action-dialog>
</template>

<script>
import modalMixin from '@/services/modalMixin';
import enums from '@/services/enums';

export default {
  name: 'AccountCreate',
  mixins: [modalMixin],
  props: {
    reserveAccountId: String,
  },
  data() {
    return {
      valid: null,
      type: enums.manual_reserve_transaction_type[0],
      bank_account_info: {
        iban: '',
        beneficiary_name: '',
      },
      value: {
        amount: 0,
        currency: 'EUR',
      },
      balance_after: {
        amount: 0,
        currency: 'EUR',
      },
      concept: '',
      external_id: '',
      raw: '{}',
    };
  },
  computed: {
    transferTypes() {
      return enums.manual_reserve_transaction_type;
    },
    apiCall() {
      let raw = {};
      try {
        raw = JSON.parse(this.raw);
      } catch (error) {
        raw = {};
      }
      const body = {
        reserve_account_id: this.reserveAccountId,
        type: this.type,
        bank_account_info: this.bank_account_info,
        value: this.value,
        balance_after: this.balance_after,
        concept: this.concept,
        external_id: this.external_id,
        raw,
      };

      return {
        method: 'POST',
        path: '/reserve_transactions',
        body,
      };
    },
  },
};

</script>
