<template>
  <div>
    <k-detail-view :source="detailSource">
        <template slot="object" slot-scope="{ o }">
            <h1>Account {{o.meta._name || o.id}}</h1>

            <v-btn color="primary" @click="openEditModal">Edit account</v-btn>
            <k-account-edit v-if="isEditModalVisible" @close="closeEditModal"/>

            <v-btn color="primary" @click="openTransferCreateModal">New Transfer</v-btn>
            <k-transfer-create v-if="isTransferCreateModalVisible" @close="closeTransferCreateModal" v-bind:source-account-id="myRoute.params.id"/>

            <v-btn color="primary" @click="openCardPayinCreateModal">New Card payin</v-btn>
            <k-card-payin-create v-if="isCardPayinCreateModalVisible" @close="closeCardPayinCreateModal" v-bind:account-id="myRoute.params.id"/>

            <v-btn color="primary" @click="openBankPayoutCreateModal">New Bank payout</v-btn>
            <k-bank-payout-create v-if="isBankPayoutCreateModalVisible" @close="closeBankPayoutCreateModal" v-bind:account-id="myRoute.params.id"/>

            <v-btn color="primary" @click="openBankPayinReferenceCreateModal">New Bank Payin reference</v-btn>
            <k-bank-payin-reference-create v-if="isBankPayinReferenceCreateModalVisible" @close="closeBankPayinReferenceCreateModal" v-bind:account-id="myRoute.params.id"/>

            <router-link class="v-btn v-btn--contained theme--dark v-size--default primary" :to="`/${myRoute.params.env}/${myRoute.params.project}/transfers?destination_account_id=${o.id}`" tag="button" :key="o.id">See Inbound Transfers</router-link>
            <router-link class="v-btn v-btn--contained theme--dark v-size--default primary" :to="`/${myRoute.params.env}/${myRoute.params.project}/transfers?source_account_id=${o.id}`" tag="button" :key="o.id">See Outbound Transfers</router-link>
            <router-link class="v-btn v-btn--contained theme--dark v-size--default primary" :to="`/${myRoute.params.env}/${myRoute.params.project}/card_payins?account_id=${o.id}`" tag="button" :key="o.id">See Card Payins</router-link>
            <router-link class="v-btn v-btn--contained theme--dark v-size--default primary" :to="`/${myRoute.params.env}/${myRoute.params.project}/bank_payouts?account_id=${o.id}`" tag="button" :key="o.id">See Bank Payouts</router-link>
            <router-link class="v-btn v-btn--contained theme--dark v-size--default primary" :to="`/${myRoute.params.env}/${myRoute.params.project}/bank_payin_references?account_id=${o.id}`" tag="button" :key="o.id">See Bank Payin References</router-link>

            <v-btn color="primary" @click="openJSONDetailModal">See account json</v-btn>
            <k-json-detail title="Account json detail" :object="o" v-if="isJSONDetailModalVisible" @close="closeJSONDetailModal"/>

            <k-filter-set :filters="filters" :query="myRoute.query"/>

            <k-list-view :source="source">
                <template slot="header">
                    <th class="id-column">ID</th>
                    <th>Date</th>
                    <th colspan="2">Type</th>
                    <th class="listable-right">Amount</th>
                    <th class="listable-right">Balance</th>
                </template>
                <template slot="object" slot-scope="{ o }">
                    <tr class="rowlink" :key="o.id" @click="() => {selectedTransaction = o.id; openTransactionDetailModal();}">
                        <td class="fulllink">
                            <k-id :id="o.id"/>
                        </td>
                        <td>{{o.created_at | moment('DD/MM/YYYY - HH:mm') }}</td>
                        <td>{{o.type.replaceAll('_', ' ')}}</td>
                        <td>
                          <span v-if="o.type == 'transfer_source' && !!o.transfer.destination_account"><v-icon>mdi-transfer-right</v-icon> <k-id long :id="o.transfer.destination_account.id"/></span>
                          <span v-if="o.type == 'transfer_destination' && !!o.transfer.source_account"><v-icon>mdi-transfer-left</v-icon> <k-id long :id="o.transfer.source_account.id"/></span>
                          <span v-if="!!o.card_payin"><v-icon>mdi-credit-card-outline</v-icon> <k-id long :id="o.card_payin.id"/></span>
                          <span v-if="!!o.bank_payout"><v-icon>mdi-bank-transfer-out</v-icon> <k-id long :id="o.bank_payout.id"/></span>
                        </td>
                        <td class="listable-right">
                            <k-value sign color :value="o.value"></k-value>
                        </td>
                        <td class="listable-right">
                            <k-value :value="o.balance_after"></k-value>
                        </td>
                    </tr>
                </template>
            </k-list-view>
        </template>
    </k-detail-view>

    <k-transaction-detail v-if="isTransactionDetailModalVisible" @close="closeTransactionDetailModal" v-bind:transaction-id="selectedTransaction"/>
  </div>
</template>

<script>
import activationMixin from '@/services/activationMixin';
import enums from '@/services/enums';
import { useModal } from '@/services/modalMixin';

export default {
  name: 'AccountDetail',
  mixins: [
    activationMixin,
    useModal('Edit'),
    useModal('TransferCreate'),
    useModal('TransactionDetail'),
    useModal('CardPayinCreate'),
    useModal('BankPayoutCreate'),
    useModal('BankPayinReferenceCreate'),
    useModal('JSONDetail'),
  ],
  data() {
    return {
      filters: [
        { key: 'value.amount', name: 'Value', type: 'int' },
        { key: 'value.currency', name: 'Currency', type: 'enum', choices: enums.currency },
        { key: 'type', name: 'Type', type: 'enum', choices: enums.transaction_type },
        { key: 'created_at', name: 'Created at', type: 'time' },
      ],
      selectedTransaction: undefined,
    };
  },
  computed: {
    detailSource() {
      return {
        env: this.myRoute.params.env,
        project: this.myRoute.params.project,
        path: `/accounts/${this.myRoute.params.id}`,
      };
    },
    source() {
      return {
        env: this.myRoute.params.env,
        project: this.myRoute.params.project,
        path: `/accounts/${this.myRoute.params.id}/transactions`,
        query: {
          ...this.myRoute.query,
          expand: 'transfer,transfer.source_account,transfer.destination_account,card_payin,bank_payout',
        },
      };
    },
  },
};
</script>
