import global from '@/global';
import api from '@/services/api';
import { Config } from '@/config.model';

// eslint-disable-next-line import/no-unresolved
const config: Config = require('config').default;

function loadSessionData() {
  if (config.usermanEnabled) {
    api.userman.get('/me').then((data: any) => {
      global.user = data;
    });
  }

  for (const envID of Object.keys(global.envs)) {
    const env = global.envs[envID];
    api.envs[env.id].get('/projects').then((data: any) => {
      env.projects = data.data;
    });
    api.envs[env.id].get('/currencies').then((data: any) => {
      env.currencies = data.data;
      const currenciesByID: any = {};
      for (const c of data.data) {
        currenciesByID[c.id] = c;
      }
      env.currenciesByID = currenciesByID;
    });
  }
}

export function setToken(token: string) {
  global.token = token;
  localStorage.setItem('token', token);
  loadSessionData();
}

export function cleanSessionData() {
  global.token = null;
  global.projects = null;
  global.project = null;
  global.user = null;
  localStorage.setItem('token', '');
  localStorage.setItem('email_verification_pending', '');
}

export function logout() {
  const res = api.userman.post('/logout', {});
  cleanSessionData();
  return res;
}

function init() {
  const token = localStorage.getItem('token');
  if (token || !config.usermanEnabled) {
    global.token = token;
    loadSessionData();
  }
}

export function hasToken() {
  return !!global.token;
}

init();
