<template>
    <div>
        <h1>Events</h1>
        <k-filter-set :filters="filters" :query="myRoute.query"/>

        <k-list-view :source="source">
            <template slot="header">
                <th class="id-column">ID</th>
                <th>Date</th>
                <th>Type</th>
            </template>
            <template slot="object" slot-scope="{ o }">
                <router-link class="rowlink" :to="`/${myRoute.params.env}/${myRoute.params.project}/events/${o.id}`" tag="tr" :key="o.id">
                    <td class="fulllink">
                        <k-id :id="o.id"/>
                    </td>
                    <td>{{o.created_at | moment('DD/MM/YYYY - HH:mm') }}</td>
                    <td>
                        {{o.type}}
                    </td>
                </router-link>
            </template>
        </k-list-view>
    </div>
</template>

<script>
import activationMixin from '@/services/activationMixin';
import enums from '@/services/enums';

export default {
  name: 'EventList',
  mixins: [activationMixin],
  data() {
    return {
      filters: [
        { key: 'created_at', name: 'Created at', type: 'time' },
        { key: 'type', name: 'Type', type: 'enum', choices: enums.event_type },
        { key: 'correlation_id', name: 'Correlation ID', type: 'string' },
      ],
      selectedEvent: undefined,
    };
  },
  computed: {
    source() {
      return {
        env: this.myRoute.params.env,
        project: this.myRoute.params.project,
        path: '/events',
        query: this.myRoute.query,
      };
    },
  },
};
</script>
