<template>
    <div>
        <h1>Scheduled Payments</h1>

        <k-filter-set :filters="filters" :query="myRoute.query"/>

        <k-list-view :source="source">
            <template slot="header">
                <th class="id-column">ID</th>
                <th>State</th>
                <th>Retries</th>
                <th>Next Attempt At</th>
                <th>Created At</th>
            </template>
            <template slot="object" slot-scope="{ o }">
                <tr class="rowlink" :key="o.id" @click="() => {selectedScheduledPayment= o.id; openScheduledPaymentDetailModal();}">
                    <td class="fulllink">
                        <k-id :id="o.id"/>
                    </td>
                    <td>
                        {{o.state}}
                    </td>
                    <td>
                        {{o.retries}}
                    </td>
                    <td>
                        {{o.next_attempt_at | moment('DD/MM/YYYY - HH:mm') }}
                    </td>
                    <td>
                        {{o.created_at | moment('DD/MM/YYYY - HH:mm') }}
                    </td>
                </tr>
            </template>
        </k-list-view>
        <k-scheduled-payment-detail v-if="isScheduledPaymentDetailModalVisible" @close="closeScheduledPaymentDetailModal" v-bind:scheduled-payment-id="selectedScheduledPayment"/>
    </div>
</template>

<script>
import activationMixin from '@/services/activationMixin';
import { useModal } from '@/services/modalMixin';

export default {
  name: 'ScheduledPaymentsList',
  mixins: [activationMixin, useModal('ScheduledPaymentDetail')],
  data() {
    return {
      filters: [
        { key: 'amount', name: 'Amount', type: 'string' },
        { key: 'source_account_id', name: 'Account', type: 'id', id_prefix: 'acc' },
        { key: 'created_at', name: 'Created at', type: 'time' },
      ],
      selectedScheduledPayment: undefined,
    };
  },
  computed: {
    source() {
      return {
        env: this.myRoute.params.env,
        project: this.myRoute.params.project,
        path: '/scheduled_payments',
        query: this.myRoute.query,
      };
    },
  },
};
</script>
