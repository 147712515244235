<template>
    <k-action-dialog :api-call="apiCall" action="Create virtual currency" @close="close">
        <v-form v-model="valid">
            <v-text-field label="Name" v-model="name" />
            <v-text-field label="Code" style="width: 100px;" v-model="code" />
            <v-text-field
              min="2"
              max="30"
              step="1"
              type="number"
              label="Decimals"
              style="width: 100px;"
              v-model.number="decimals"/>
        </v-form>
    </k-action-dialog>
</template>

<script>
import modalMixin from '@/services/modalMixin';

export default {
  data() {
    return {
      valid: null,
      name: '',
      code: '',
      decimals: 2,
    };
  },
  mixins: [modalMixin],
  computed: {
    apiCall() {
      return {
        method: 'POST',
        path: '/virtual_currencies',
        body: {
          code: this.code,
          decimals: this.decimals,
          meta: {
            _name: this.name,
          },
        },
      };
    },
  },
};

</script>
