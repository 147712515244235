<template>
    <k-dialog title="API Key Detail" @close="close">
        <v-text-field label="API Key ID" v-model="apiKeyId" required disabled/>
        <v-text-field label="Name" v-model="meta._name" disabled/>
        <v-simple-table v-if="type === 'secret'">
          <template v-slot:default>
            <thead>
              <tr>
                <th>
                  Resource
                </th>
                <th>
                  Permission
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(resource, index) in resources"
                :key="index"
              >
                <td>{{ resource }}</td>
                <td>
                  <v-radio-group
                    row
                    :value="getPermissionValue(resource)"
                    disabled
                  >
                    <v-radio
                      label="None"
                      value="none"
                      disabled
                    />
                    <v-radio
                      label="Read only"
                      value="read"
                      disabled
                    />
                    <v-radio
                      label="Read and write"
                      value="read_write"
                      disabled
                    />
                  </v-radio-group>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
    </k-dialog>
</template>

<script>
import api from '@/services/api';
import modalMixin from '@/services/modalMixin';
import enums from '@/services/enums';

export default {
  props: {
    apiKeyId: String,
  },
  data() {
    return {
      valid: null,
      meta: {
        _name: '',
      },
      permissions: {},
      enums,
      type: undefined,
    };
  },
  computed: {
    resources() {
      return enums.resource.filter((x) => !(['project', 'users', 'api_keys'].includes(x)));
    },
  },
  created() {
    api.kernel.get(`/api_keys/${this.apiKeyId}`).then((data) => {
      this.permissions = data.permissions.reduce((acc, cur) => {
        const [resource, permission] = cur.split(':');
        return ({ ...acc, [resource]: permission });
      }, {});
      this.meta = data.meta;
      this.type = data.type;
    });
  },
  mixins: [modalMixin],
  methods: {
    getPermissionValue(resource) {
      return this.permissions[resource];
    },
  },
};
</script>
