import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      light: {
        primary: '#2ba44b',
        secondary: '#83d298',
        accent: '#1ce351',
        error: '#db592b',
      },
      dark: {
        primary: '#2ba44b',
        secondary: '#83d298',
        accent: '#1ce351',
        error: '#db592b',
      },
    },
  },
});
