<template>
    <div>
        <h1>Virtual currencies</h1>

        <v-btn color="primary" @click="openCreateModal">Create</v-btn>
        <k-virtual-currency-create v-if="isCreateModalVisible" @close="closeCreateModal"/>

        <k-filter-set :filters="filters" :query="myRoute.query"/>

        <k-list-view :source="source">
            <template slot="header">
                <th class="id-column">ID</th>
                <th>Code</th>
                <th>Name</th>
                <th>Decimals</th>
                <th class="listable-right"></th>
            </template>
            <template slot="object" slot-scope="{ o }">
                <tr class="rowlink" :key="o.id" @click="() => {selectedVirtualCurrency = o.id; openVirtualCurrencyDetailModal();}">
                    <td class="fulllink">
                        <k-id :id="o.id"/>
                    </td>
                    <td>
                        {{o.code}}
                    </td>
                    <td>
                        {{o.meta._name}}
                    </td>
                    <td>
                        {{o.decimals}}
                    </td>
                    <td class="listable-buttons">
                      <router-link class="v-btn v-btn--contained theme--dark v-size--default primary" :to="`/${myRoute.params.env}/${myRoute.params.project}/virtual_accounts?balance.currency=${o.code}`" tag="button" :key="o.code" @click.native="$event.stopImmediatePropagation()">See Accounts</router-link>
                    </td>
                </tr>
            </template>
        </k-list-view>
        <k-virtual-currency-detail v-if="isVirtualCurrencyDetailModalVisible" @close="closeVirtualCurrencyDetailModal" v-bind:virtual-currency-id="selectedVirtualCurrency"/>
    </div>
</template>

<script>
import activationMixin from '@/services/activationMixin';
import { useModal } from '@/services/modalMixin';

export default {
  name: 'VirtualCurrencyList',
  mixins: [activationMixin, useModal('Create'), useModal('VirtualCurrencyDetail')],
  data() {
    return {
      filters: [
        { key: 'created_at', name: 'Created at', type: 'time' },
      ],
      selectedVirtualCurrency: undefined,
    };
  },
  computed: {
    source() {
      return {
        env: this.myRoute.params.env,
        project: this.myRoute.params.project,
        path: '/virtual_currencies',
        query: this.myRoute.query,
      };
    },
  },
};
</script>

<style scoped>
  .listable-buttons {
    float: right;
  }

  th:not(.listable-right) {
    width: 200px;
  }
</style>
