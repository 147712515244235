<template>
    <div>
        <h1>Bot transfer configs</h1>

        <v-btn color="primary" @click="openCreateModal">Create</v-btn>
        <k-bot-transfer-config-create v-if="isCreateModalVisible" @close="closeCreateModal"/>

        <k-bot-transfer-config-edit v-if="isEditModalVisible" @close="closeEditModal"
          v-bind:bot-transfer-config-id="selectedBotTransferConfig"/>

        <k-filter-set :filters="filters" :query="myRoute.query"/>

        <k-list-view :source="source">
            <template slot="header">
                <th class="id-column">ID</th>
                <th>Bot name</th>
                <th>Enabled</th>
                <th></th>
            </template>
            <template slot="object" slot-scope="{ o }">
                <tr class="rowlink" :key="o.id" @click="() => {selectedBotTransferConfig = o.id; openBotTransferConfigDetailModal();}">
                    <td class="fulllink">
                        <k-id :id="o.id"/>
                    </td>
                    <td>{{o.bot_name }}</td>
                    <td>{{o.enabled}}</td>
                    <td class="listable-buttons">
                      <v-btn color="green" @click.stop.prevent="() => {selectedBotTransferConfig = o.id; openEditModal();}">Edit</v-btn>
                    </td>
                </tr>
            </template>
        </k-list-view>

        <k-bot-transfer-config-detail v-if="isBotTransferConfigDetailModalVisible" @close="closeBotTransferConfigDetailModal" v-bind:bot-transfer-config-id="selectedBotTransferConfig"/>
    </div>
</template>

<script>
import api from '@/services/api';
import activationMixin from '@/services/activationMixin';
import { useModal } from '@/services/modalMixin';

export default {
  name: 'BotTransferConfigList',
  mixins: [
    activationMixin,
    useModal('Create'),
    useModal('Edit'),
    useModal('BotTransferConfigDetail'),
  ],
  data() {
    return {
      filters: [
        { key: 'bot_name', name: 'Bot name', type: 'string' },
        { key: 'created_at', name: 'Created at', type: 'time' },
      ],
      selectedBotTransferConfig: undefined,
    };
  },
  created() {
    if (this.$route.query.id) {
      this.isBotTransferConfigDetailModalVisible = true;
      this.selectedBotTransferConfig = this.$route.query.id;
    }
  },
  computed: {
    source() {
      return {
        env: this.myRoute.params.env,
        path: '/bot_transfer_configs',
        query: {
          ...this.myRoute.query,
        },
      };
    },
  },
  methods: {
    patch(rtx, state) {
      api.kernel.patch(`/reserve_transactions/${rtx.id}`, {
        card_payin_unwanted_state: state,
      }).then((data) => {
        // eslint-disable-next-line no-param-reassign
        rtx.card_payin_unwanted_state = data.card_payin_unwanted_state;
      });
    },
  },
};
</script>
