<template>
    <k-dialog :title="title" :big="true" @close="close">
        <k-detail-view :source="objectSource">
            <template slot="object" slot-scope="{ o }">
               <div v-highlightjs="o && JSON.stringify(o, null, 2)"></div>
            </template>
        </k-detail-view>
    </k-dialog>
</template>

<script>
import modalMixin from '@/services/modalMixin';
import activationMixin from '@/services/activationMixin';

export default {
  name: 'JSONDetail',
  mixins: [activationMixin, modalMixin],
  props: {
    title: String,
    object: Object,
  },
  computed: {
    objectSource() {
      return {
        object: this.object,
        env: 'development',
      };
    },
  },
};
</script>
