const config = {
  envs: [
    {
      id: 'live',
      name: 'Live (Goin)',
      url: 'https://api.kernelpay.com/v1',
      cors: true,
      headers: {
        'X-Kernel-Env': 'live',
      },
      apis: {
        ibans: true,
      },
    },
    // {
    //   id: 'live-eap',
    //   name: 'Easy Payments',
    //   url: 'https://api.kernelpay.com/v1',
    //   cors: true,
    //   headers: {
    //     'X-Kernel-Env': 'live-eap',
    //   },
    //   apis: {
    //     ibans: false,
    //   },
    // },
    {
      id: 'sandbox-stag',
      name: 'Staging',
      url: 'https://api.kernelpay.com/v1',
      cors: true,
      headers: {
        'X-Kernel-Env': 'sandbox-stag',
      },
      apis: {
        ibans: true,
      },
    },
    // {
    //   id: 'sandbox',
    //   name: 'Sandbox',
    //   url: 'https://api.kernelpay.com/v1',
    //   cors: true,
    //   headers: {
    //     'X-Kernel-Env': 'sandbox',
    //   },
    //   apis: {
    //     ibans: true,
    //   },
    // },
  ],
  adminEnabled: false,
  usermanEnabled: true,
  userman: {
    url: 'https://userman.kernelpay.com',
    cors: true,
  },
};

export default config;
