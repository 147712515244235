<template>
    <k-action-dialog :api-call="apiCall" action="Add card number" @close="close">
        <v-form v-model="valid">
            Source:
            <v-radio-group v-model="source">
                <v-radio label="Card" value="card"/>
                <v-radio label="Card payin" value="card_payin"/>
                <v-radio label="Card number" value="card_number"/>
            </v-radio-group>
            <div v-if="source === 'card'">
                <v-text-field label="Card ID" v-model="card_id" />
            </div>
            <div v-if="source === 'card_payin'">
                <v-text-field label="Card payin ID" v-model="card_payin_id" />
            </div>
            <div v-if="source === 'card_number'">
                <v-text-field label="Card number" v-model="card_number" />
            </div>
        </v-form>
    </k-action-dialog>
</template>

<script>
import modalMixin from '@/services/modalMixin';

export default {
  data() {
    return {
      valid: null,
      source: '',
      card_id: '',
      card_payin_id: '',
      card_number: '',
    };
  },
  mixins: [modalMixin],
  computed: {
    apiCall() {
      const body = {};

      if (this.source === 'card') {
        body.card_id = this.card_id;
      } else if (this.source === 'card_payin') {
        body.card_payin_id = this.card_payin_id;
      } else if (this.source === 'card_number') {
        body.card_info = {
          number: this.card_number,
        };
      }

      return {
        method: 'POST',
        path: '/acquiring_denylist/card_number',
        body,
      };
    },
  },
};

</script>

<style scoped>
pre {
    display: block;
    background: black;
    color: #ddd;
    padding: 20px;
    word-wrap: break-word;
    white-space: pre-wrap;
}
</style>
