<template>
    <k-action-dialog :api-call="apiCall" action="Create business" @close="close">
        <v-form v-model="valid">
          <v-text-field label="Trading name" v-model="trading_name" />
          <v-text-field label="Registration name" v-model="registration_name" />
          <v-text-field label="Registration number" v-model="registration_number" />
          <v-checkbox label="With address" v-model="has_address" required />
          <div v-if="has_address">
              <v-text-field label="Line 1" v-model="address.line_1" required />
              <v-text-field label="Line 2" v-model="address.line_2" required />
              <v-text-field label="Postal code" v-model="address.postal_code" required />
              <v-text-field label="City" v-model="address.city" required />
              <v-text-field label="Region" v-model="address.region" required />
              <v-select label="Country" v-model="address.country" :items="enums.country" />
          </div>
        </v-form>
    </k-action-dialog>
</template>

<script>
import modalMixin from '@/services/modalMixin';
import enums from '@/services/enums';

export default {
  data() {
    return {
      enums,
      valid: null,
      trading_name: '',
      registration_name: '',
      registration_number: '',
      has_address: false,
      address: {
        line_1: '',
        line_2: '',
        postal_code: '',
        city: '',
        region: '',
        country: 'ES',
      },
    };
  },
  mixins: [modalMixin],
  computed: {
    apiCall() {
      const body = {
        trading_name: this.trading_name,
        registration_name: this.registration_name,
        registration_number: this.registration_number,
      };

      if (this.has_address) {
        body.address = this.address;
      }

      return {
        method: 'POST',
        path: '/businesses',
        body,
      };
    },
  },
};

</script>
