<template>
    <k-action-dialog :api-call="apiCall" action="Manual extraction" @close="close">
        <v-form v-model="valid">

            <v-radio-group v-model="kind">
                <v-radio label="Standard" value="standard"/>
                <v-radio label="Since forever. DANGER: EXTREMELY SLOW" value="full" disabled/>
                <v-radio label="Since ExternalID" value="since"/>
                <v-radio label="Until ExternalID" value="until"/>
                <v-radio label="Whitelist, manual" value="whitelist"/>
                <v-radio label="Whitelist, all unknowns" value="whitelist_unknowns"/>
            </v-radio-group>

            <v-text-field v-if="kind === 'since'" label="Last external ID" v-model="last_external_id" />
            <v-text-field v-if="kind === 'until'" label="Until external ID" v-model="until_external_id" />
            <template v-if="kind === 'whitelist'">
                Enter ExternalID whitelist, one per line.
                WARNING: entering an ExternalID that doesn't exist will cause the bot to loop forever until the beginning of time!
                <v-textarea v-model="whitelist"/>
            </template>
        </v-form>
    </k-action-dialog>
</template>

<script>
import modalMixin from '@/services/modalMixin';

export default {
  props: {
    reserveAccountId: String,
  },
  mixins: [modalMixin],
  data() {
    return {
      valid: null,
      kind: 'standard',
      last_external_id: '',
      until_external_id: '',
      whitelist: '',
    };
  },
  computed: {
    apiCall() {
      const body = {};

      if (this.kind === 'full') {
        body.full = true;
      }

      if (this.kind === 'since') {
        body.last_external_id = this.last_external_id;
      }

      if (this.kind === 'until') {
        body.until_external_id = this.until_external_id;
      }

      if (this.kind === 'whitelist') {
        const ids = [];
        for (let id of this.whitelist.split('\n')) {
          id = id.trim();
          if (id !== '') {
            ids.push(id);
          }
        }
        body.external_id_whitelist = ids;
      }

      if (this.kind === 'whitelist_unknowns') {
        body.external_id_whitelist_all_unknowns = true;
      }

      return {
        method: 'POST',
        path: `/reserve_accounts/${this.reserveAccountId}/do_extraction`,
        body,
      };
    },
  },
};

</script>
