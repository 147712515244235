<template>
    <div>
      <div class="page-header">
          <h1>Banking Credentials Extractions</h1>
          <!-- <v-btn rounded color="primary" @click="openCreateModal">
            <v-icon left>mdi-plus</v-icon>
            Create
          </v-btn> -->
      </div>

        <!-- <k-banking-credential-create v-if="isCreateModalVisible" @close="closeCreateModal"/> -->
        <k-filter-set :filters="filters" :query="myRoute.query"/>

        <k-list-view :source="source">
            <template slot="header">
                <th class="id-column">ID</th>
                <th>Owner</th>
                <th>Connection</th>
                <th>Connnection Method</th>
                <th>State</th>
                <th class="listable-right"></th>
            </template>
            <template slot="object" slot-scope="{ o }">
              <tr :key="o.id">
                    <td class="fulllink">
                        <k-id :id="o.id" />
                    </td>
                    <td v-if="o.banking_credential.banking_connection.customer">
                        <span v-if="o.banking_credential.banking_connection.customer.identity">
                            <k-id :id="o.banking_credential.banking_connection.customer.id"/> {{o.banking_credential.banking_connection.customer.identity.first_name}} {{o.banking_credential.banking_connection.customer.identity.last_name}}
                        </span>
                        <span v-else><k-id long :id="o.banking_credential.banking_connection.customer.id"/></span>
                    </td>
                    <td v-else-if="o.banking_credential.banking_connection.business">
                        <span>
                            <k-id :id="o.banking_credential.banking_connection.business.id"/> {{o.banking_credential.banking_connection.business.trading_name}}
                        </span>
                    </td>
                    <td v-else>
                        N/A
                      </td>
                    <td>
                        <k-id :id="o.banking_credential.banking_connection_id" />
                    </td>
                    <td>
                        <code>{{o.banking_credential.banking_connection_method.provider_name}}</code> {{o.banking_credential.banking_connection_method.provider}}
                    </td>
                    <td>
                      {{o.state}}
                    </td>
                    <td class="listable-right">
                      <v-tooltip left color="orange" v-if="o.state === 'merge_waiting'">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            color="orange"
                            v-bind="attrs"
                            v-on="on"
                            @click.stop.prevent="merge(o.id)"
                          >
                            <v-icon size="20">mdi-transfer</v-icon>
                          </v-btn>
                        </template>
                        <span>Merge</span>
                      </v-tooltip>
                      <v-tooltip left color="green" v-if="o.data_file_url">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                        icon
                        color="green"
                        v-on="on"
                        v-bind="attrs"
                        :href="o.data_file_url"
                      >
                        <v-icon size="20">mdi-code-json</v-icon>
                      </v-btn>
                        </template>
                        <span>View result JSON</span>
                      </v-tooltip>
                      <v-tooltip left color="blue" v-if="o.logs_file_url">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                        icon
                        color="blue"
                        v-on="on"
                        v-bind="attrs"
                        :href="o.logs_file_url"
                      >
                        <v-icon size="20">mdi-clipboard-text-search</v-icon>
                      </v-btn>
                        </template>
                        <span>View logs</span>
                      </v-tooltip>
                    </td>
                  </tr>
            </template>
        </k-list-view>
    </div>
</template>

<script>
import api from '@/services/api';
import activationMixin from '@/services/activationMixin';
import { useModal } from '@/services/modalMixin';

export default {
  name: 'BankingCredentialExtractionsList',
  mixins: [activationMixin, useModal('Create')],
  data() {
    return {
      filters: [
        { key: 'created_at', name: 'Created at', type: 'time' },
      ],
      selectedInstitution: undefined,
    };
  },
  methods: {
    merge(id) {
      api.kernel.post(`/banking_credential_extractions/${id}/merge`, {});
    },
  },
  computed: {
    source() {
      return {
        env: this.myRoute.params.env,
        path: '/banking_credential_extractions',
        query: {
          ...this.myRoute.query,
          expand: ['banking_credential.banking_connection.customer'],
        },
      };
    },
  },
};
</script>
