<template>
    <div>
        <h1>Bank payouts</h1>

        <v-btn color="primary" @click="openCreateModal">Create</v-btn>
        <k-bank-payout-create v-if="isCreateModalVisible" @close="closeCreateModal"/>

        <k-filter-set :filters="filters" :query="myRoute.query"/>

        <k-list-view :source="source">
            <template slot="header">
                <th class="id-column">ID</th>
                <th>Date</th>
                <th>State</th>
                <th>Bank account</th>
                <th>Account</th>
                <th>Value</th>
            </template>
            <template slot="object" slot-scope="{ o }">
                <router-link class="rowlink" :to="`/${myRoute.params.env}/${myRoute.params.project}/bank_payouts/${o.id}`" tag="tr" :key="o.id">
                    <td class="fulllink">
                        <k-id :id="o.id"/>
                    </td>
                    <td>{{o.created_at | moment('DD/MM/YYYY - HH:mm') }}</td>
                    <td>
                        {{o.state}}
                    </td>
                    <td>
                        <span v-if="o.bank_account_id">
                            {{o.bank_account_id}}
                        </span>
                        <span v-else>
                            {{o.bank_account_info.iban}}
                        </span>
                    </td>
                    <td>
                        <router-link :to="`/${myRoute.params.env}/${myRoute.params.project}/accounts/${o.account_id}`" tag="a">
                            {{o.account_id}}
                        </router-link>
                    </td>
                    <td class="listable-right">
                        <k-value :value="o.value"/>
                    </td>
                </router-link>
            </template>
        </k-list-view>
    </div>
</template>

<script>
import activationMixin from '@/services/activationMixin';
import enums from '@/services/enums';
import { useModal } from '@/services/modalMixin';

export default {
  name: 'BankPayoutList',
  mixins: [activationMixin, useModal('Create')],
  data() {
    return {
      filters: [
        { key: 'value.amount', name: 'Value', type: 'int' },
        { key: 'value.currency', name: 'Currency', type: 'enum', choices: enums.currency },
        { key: 'concept', name: 'Concept', type: 'string' },
        { key: 'miniref', name: 'Miniref', type: 'string' },
        { key: 'account_id', name: 'Account', type: 'id', id_prefix: 'acc' },
        { key: 'bank_account_id', name: 'Bank account', type: 'id', id_prefix: 'ba' },
        { key: 'state', name: 'State', type: 'enum', choices: enums.bank_payout_state },
        { key: 'created_at', name: 'Created at', type: 'time' },
        { key: 'bank_account_info.iban', name: 'IBAN', type: 'string' },
        { key: 'type', name: 'Type', type: 'enum', choices: enums.bank_payout_type },
        { key: 'sender.iban', name: 'Sender IBAN', type: 'string' },
        { key: 'processed_at', name: 'Processed at', type: 'time' },
        { key: 'refunded_at', name: 'Refunded at', type: 'time' },
      ],
      selectedBankPayout: undefined,
    };
  },
  computed: {
    source() {
      return {
        env: this.myRoute.params.env,
        project: this.myRoute.params.project,
        path: '/bank_payouts',
        query: this.myRoute.query,
      };
    },
  },
};
</script>
