<template>
    <k-action-dialog :api-call="apiCall" action="Create" @close="close">
        <v-form v-model="valid">
            <k-virtual-account-selector label="Source virtual account" v-model="source_virtual_account_id" required ></k-virtual-account-selector>
            <k-virtual-account-selector label="Destination virtual account" v-model="destination_virtual_account_id" required ></k-virtual-account-selector>
            <k-value-selector label="Amount" amount-as-integer any-currency v-model="value"></k-value-selector>
        </v-form>
    </k-action-dialog>
</template>

<script>
import modalMixin from '@/services/modalMixin';

export default {
  props: {
    sourceAccountId: {
      type: String,
      required: false,
    },
  },
  mixins: [modalMixin],
  data() {
    return {
      valid: null,
      source_virtual_account_id: null,
      destination_virtual_account_id: null,
      value: {
        amount: 0,
        currency: 'EUR',
      },
    };
  },
  created() {
    if (this.sourceAccountId) {
      this.source_virtual_account_id = this.sourceAccountId;
    }
  },
  computed: {
    apiCall() {
      return {
        method: 'POST',
        path: '/virtual_transfers',
        body: {
          source_virtual_account_id: this.source_virtual_account_id,
          destination_virtual_account_id: this.destination_virtual_account_id,
          value: this.value,
        },
      };
    },
  },
};

</script>
