<template>
    <div>
        <h1>Recurring Payments</h1>

        <k-filter-set :filters="filters" :query="myRoute.query"/>

        <k-list-view :source="source">
            <template slot="header">
                <th class="id-column">ID</th>
                <th>Value</th>
                <th>Source Account</th>
                <th>Destination Account</th>
                <th>State</th>
                <th>Frequency</th>
                <th>Next Execution</th>
                <th>Created At</th>
            </template>
            <template slot="object" slot-scope="{ o }">
                <tr class="rowlink" :key="o.id" @click="() => {selectedRecurringPayment = o.id; openRecurringPaymentDetailModal();}">
                    <td class="fulllink">
                        <k-id :id="o.id"/>
                    </td>
                    <td>
                        <k-value :value="o.value"/>
                    </td>
                    <td>
                      <router-link :to="`/${myRoute.params.env}/${myRoute.params.project}/accounts/${o.source_account_id}`" tag="a">
                          {{o.source_account_id}}
                      </router-link>
                    </td>
                    <td>
                      <router-link :to="`/${myRoute.params.env}/${myRoute.params.project}/accounts/${o.destination_account_id}`" tag="a">
                          {{o.destination_account_id}}
                      </router-link>
                    </td>
                    <td>
                        {{o.state}}
                    </td>
                    <td>
                        every {{o.frequency.value}} {{o.frequency.unit}}s
                    </td>
                    <td>
                        {{o.next_execution | moment('DD/MM/YYYY - HH:mm') }}
                    </td>
                    <td>
                        {{o.created_at | moment('DD/MM/YYYY - HH:mm') }}
                    </td>
                </tr>
            </template>
        </k-list-view>
        <k-recurring-payment-detail v-if="isRecurringPaymentDetailModalVisible" @close="closeRecurringPaymentDetailModal" v-bind:recurring-payment-id="selectedRecurringPayment"/>
    </div>
</template>

<script>
import activationMixin from '@/services/activationMixin';
import { useModal } from '@/services/modalMixin';

export default {
  name: 'RecurringPaymentList',
  mixins: [activationMixin, useModal('RecurringPaymentDetail')],
  data() {
    return {
      filters: [
        { key: 'value', name: 'Value', type: 'string' },
        { key: 'source_account_id', name: 'Account', type: 'id', id_prefix: 'acc' },
        { key: 'created_at', name: 'Created at', type: 'time' },
      ],
      selectedRecurringPayment: undefined,
    };
  },
  computed: {
    source() {
      return {
        env: this.myRoute.params.env,
        project: this.myRoute.params.project,
        path: '/recurring_payments',
        query: this.myRoute.query,
      };
    },
  },
};
</script>
