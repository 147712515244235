<template>
    <div>
        <h1>API Keys</h1>

        <v-btn color="primary" @click="openCreateModal">Create</v-btn>
        <k-apikey-create v-if="isCreateModalVisible" @close="closeCreateModal"/>
        <k-api-key-edit v-if="isAPIKeyEditModalVisible" @close="closeAPIKeyEditModal" v-bind:api-key-id="selectedAPIKey"/>
        <k-api-key-delete v-if="isAPIKeyDeleteModalVisible" @close="closeAPIKeyDeleteModal" v-bind:api-key-id="selectedAPIKey"/>
        <k-api-key-detail v-if="isAPIKeyDetailModalVisible" @close="closeAPIKeyDetailModal" v-bind:api-key-id="selectedAPIKey"/>

        <k-filter-set :filters="filters" :query="myRoute.query"/>

        <k-list-view :source="source" :allow-download="false">
            <template slot="header">
                <th class="id-column">ID</th>
                <th>Name</th>
                <th>Type</th>
                <th>Token</th>
                <th>Created at</th>
                <th>Actions</th>
            </template>
            <template slot="object" slot-scope="{ o }">
                <tr @click.prevent.stop="() => {selectedAPIKey = o.id; openAPIKeyDetailModal();}">
                    <td class="fulllink">
                        <k-id :id="o.id"/>
                    </td>
                    <td class="description">{{ o.meta._name || '' }}</td>
                    <td>{{o.type}}</td>
                    <td>{{o.token}}</td>
                    <td>{{o.created_at | moment('DD/MM/YYYY - HH:mm') }}</td>
                    <td>
                      <v-btn color="primary" @click.prevent.stop="() => {selectedAPIKey = o.id; openAPIKeyEditModal();}">Edit</v-btn>
                      <v-btn color="red" @click.prevent.stop="() => {selectedAPIKey = o.id; openAPIKeyDeleteModal();}">Delete</v-btn>
                    </td>
                </tr>
            </template>
        </k-list-view>

        <div>
            <br>
            <p>Want to get started with the API? Check out these examples.</p>

            <h2>List accounts</h2>
            <pre>curl -H 'Authorization: Bearer {{sampleKeyOrDummy}}' \
                {{$global.env.url}}/accounts</pre>

            <h2>Create account</h2>
            <pre>curl -H 'Authorization: Bearer {{sampleKeyOrDummy}}' \
                {{$global.env.url}}/accounts -X POST \
                -d '{"currency": "EUR"}'</pre>
            <br>
            Check out the <a href="https://kernelpay.com/docs" target="_blank">API documentation</a> for more.
        </div>
    </div>
</template>

<script>
import api from '@/services/api';
import activationMixin from '@/services/activationMixin';
import enums from '@/services/enums';
import { useModal } from '@/services/modalMixin';

export default {
  name: 'APIKeyList',
  mixins: [activationMixin, useModal('Create'), useModal('APIKeyEdit'), useModal('APIKeyDelete'), useModal('APIKeyDetail')],
  data() {
    return {
      filters: [
        { key: 'type', name: 'Type', type: 'enum', choices: enums.api_key_type },
        { key: 'created_at', name: 'Created at', type: 'time' },
      ],
      sampleKey: null,
      selectedAPIKey: undefined,
      toggled: false,
    };
  },
  computed: {
    source() {
      return {
        env: this.myRoute.params.env,
        project: this.myRoute.params.project,
        path: '/api_keys',
        query: this.myRoute.query,
      };
    },
    sampleKeyOrDummy() {
      if (this.sampleKey) {
        return this.sampleKey;
      }
      return `${this.myRoute.params.env}_secretkey_xxxxxxx`;
    },
  },
  created() {
    api.kernel.get('/api_keys').then((data) => {
      for (const k of data.data) {
        if (k.type === 'secret' && k.token) {
          this.sampleKey = k.token;
        }
      }
    });
  },
};
</script>

<style scoped>
.description {
    max-width: 200px;
    overflow-wrap: break-word;
}
pre {
    display: block;
    background: black;
    color: #ddd;
    padding: 20px;
}
</style>
