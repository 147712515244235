import Vue from 'vue';
import hljs from 'highlight.js';
import moment from 'vue-moment';

import global from '@/global';
import App from './App.vue';
import router from './plugins/router';
import vuetify from './plugins/vuetify';

import '@/assets/style.scss';
import { Config } from './config.model';

Vue.use(moment);

// eslint-disable-next-line import/no-unresolved
const config: Config = require('config').default;

Vue.component('k-action-dialog', () => import('@/components/ActionDialog.vue'));
Vue.component('k-value', () => import('@/components/Value.vue'));
Vue.component('k-currency-selector', () => import('@/components/CurrencySelector.vue'));
Vue.component('k-object-selector', () => import('@/components/ObjectSelector.vue'));
Vue.component('k-account-selector', () => import('@/components/AccountSelector.vue'));
Vue.component('k-business-selector', () => import('@/components/BusinessSelector.vue'));
Vue.component('k-customer-selector', () => import('@/components/CustomerSelector.vue'));
Vue.component('k-bank-account-selector', () => import('@/components/BankAccountSelector.vue'));
Vue.component('k-card-selector', () => import('@/components/CardSelector.vue'));
Vue.component('k-sca-card-selector', () => import('@/components/SCACardSelector.vue'));
Vue.component('k-file-selector', () => import('@/components/FileSelector.vue'));
Vue.component('k-card-field', () => import('@/components/CardField.vue'));
Vue.component('k-value-selector', () => import('@/components/ValueSelector.vue'));
Vue.component('k-virtual-currency-selector', () => import('@/components/VirtualCurrencySelector.vue'));
Vue.component('k-banking-institution-selector', () => import('@/components/BankingInstitutionSelector.vue'));
Vue.component('k-banking-connection-selector', () => import('@/components/BankingConnectionSelector.vue'));
Vue.component('k-banking-connection-method-selector', () => import('@/components/BankingConnectionMethodSelector.vue'));
Vue.component('k-user-menu', () => import('@/components/UserMenu.vue'));
Vue.component('k-list-view', () => import('@/components/ListView.vue'));
Vue.component('k-detail-view', () => import('@/components/DetailView.vue'));
Vue.component('k-id', () => import('@/components/ID.vue'));
Vue.component('k-id-chip', () => import('@/components/IDChip.vue'));
Vue.component('k-filter-set', () => import('@/components/FilterSet.vue'));
Vue.component('k-dialog', () => import('@/components/Dialog.vue'));
Vue.component('k-meta-edit', () => import('@/components/MetaEdit.vue'));
Vue.component('k-file-view', () => import('@/components/FileView.vue'));
Vue.component('k-virtual-account-selector', () => import('@/components/VirtualAccountSelector.vue'));

Vue.component('k-systemcheck-perform', () => import('@/views/modals/SystemCheckPerform.vue'));

Vue.component('k-apikey-create', () => import('@/views/modals/APIKeyCreate.vue'));
Vue.component('k-api-key-edit', () => import('@/views/modals/APIKeyEdit.vue'));
Vue.component('k-api-key-delete', () => import('@/views/modals/APIKeyDelete.vue'));
Vue.component('k-api-key-detail', () => import('@/views/modals/APIKeyDetail.vue'));
Vue.component('k-account-create', () => import('@/views/modals/AccountCreate.vue'));
Vue.component('k-account-edit', () => import('@/views/modals/AccountEdit.vue'));
Vue.component('k-bank-account-create', () => import('@/views/modals/BankAccountCreate.vue'));
Vue.component('k-bank-account-detail', () => import('@/views/modals/BankAccountDetail.vue'));
Vue.component('k-bank-account-edit', () => import('@/views/modals/BankAccountEdit.vue'));
Vue.component('k-bank-payin-detail', () => import('@/views/modals/BankPayinDetail.vue'));
Vue.component('k-bank-payin-reference-create', () => import('@/views/modals/BankPayinReferenceCreate.vue'));
Vue.component('k-bank-payin-reference-detail', () => import('@/views/modals/BankPayinReferenceDetail.vue'));
Vue.component('k-iban-create', () => import('@/views/modals/IBANCreate.vue'));
Vue.component('k-iban-detail', () => import('@/views/modals/IBANDetail.vue'));
Vue.component('k-bank-payout-create', () => import('@/views/modals/BankPayoutCreate.vue'));
Vue.component('k-bank-payout-detail', () => import('@/views/modals/BankPayoutDetail.vue'));
Vue.component('k-business-create', () => import('@/views/modals/BusinessCreate.vue'));
Vue.component('k-business-detail', () => import('@/views/modals/Business.vue'));
Vue.component('k-card-create', () => import('@/views/modals/CardCreate.vue'));
Vue.component('k-card-detail', () => import('@/views/modals/Card.vue'));
Vue.component('k-sca-card-create', () => import('@/views/modals/ScaCardCreate.vue'));
Vue.component('k-sca-card-detail', () => import('@/views/modals/SCACard.vue'));
Vue.component('k-card-payin-create', () => import('@/views/modals/CardPayinCreate.vue'));
Vue.component('k-card-payin-dispute-detail', () => import('@/views/modals/CardPayinDisputeDetail.vue'));
Vue.component('k-card-payin-dispute-accept', () => import('@/views/modals/CardPayinDisputeAccept.vue'));
Vue.component('k-card-payin-settle', () => import('@/views/modals/CardPayinSettle.vue'));
Vue.component('k-card-payin-refund-create', () => import('@/views/modals/CardPayinRefundCreate.vue'));
Vue.component('k-card-token-create', () => import('@/views/modals/CardTokenCreate.vue'));
Vue.component('k-customer-create', () => import('@/views/modals/CustomerCreate.vue'));
Vue.component('k-customer-detail', () => import('@/views/modals/CustomerDetail.vue'));
Vue.component('k-event-detail', () => import('@/views/modals/EventDetail.vue'));
Vue.component('k-file-upload', () => import('@/views/modals/FileUpload.vue'));
Vue.component('k-transaction-detail', () => import('@/views/modals/TransactionDetail.vue'));
Vue.component('k-virtual-transaction-detail', () => import('@/views/modals/VirtualTransactionDetail.vue'));
Vue.component('k-transfer-create', () => import('@/views/modals/TransferCreate.vue'));
Vue.component('k-transfer-detail', () => import('@/views/modals/TransferDetail.vue'));
Vue.component('k-virtual-transfer-detail', () => import('@/views/modals/VirtualTransferDetail.vue'));
Vue.component('k-user-create', () => import('@/views/modals/UserCreate.vue'));
Vue.component('k-user-delete', () => import('@/views/modals/UserDelete.vue'));
Vue.component('k-user-edit', () => import('@/views/modals/UserEdit.vue'));
Vue.component('k-user-detail', () => import('@/views/modals/UserDetail.vue'));
Vue.component('k-user-role-edit', () => import('@/views/modals/UserRoleEdit.vue'));
Vue.component('k-virtual-account-create', () => import('@/views/modals/VirtualAccountCreate.vue'));
Vue.component('k-virtual-transfer-create', () => import('@/views/modals/VirtualTransferCreate.vue'));
Vue.component('k-virtual-currency-create', () => import('@/views/modals/VirtualCurrencyCreate.vue'));
Vue.component('k-virtual-currency-detail', () => import('@/views/modals/VirtualCurrencyDetail.vue'));
Vue.component('k-webhook-create', () => import('@/views/modals/WebhookCreate.vue'));
Vue.component('k-webhook-delete', () => import('@/views/modals/WebhookDelete.vue'));
Vue.component('k-webhook-edit', () => import('@/views/modals/WebhookEdit.vue'));
Vue.component('k-webhook-delivery-detail', () => import('@/views/modals/WebhookDeliveryDetail.vue'));
Vue.component('k-json-detail', () => import('@/views/modals/JSONDetail.vue'));
Vue.component('k-bot-transfer-config-create', () => import('@/views/modals/BotTransferConfigCreate.vue'));
Vue.component('k-bot-transfer-config-edit', () => import('@/views/modals/BotTransferConfigEdit.vue'));
Vue.component('k-bot-transfer-config-detail', () => import('@/views/modals/BotTransferConfigDetail.vue'));
Vue.component('k-acquiring-denylist-card-number-create', () => import('@/views/modals/AcquiringDenylistCardNumberCreate.vue'));
Vue.component('k-acquiring-denylist-card-number-detail', () => import('@/views/modals/AcquiringDenylistCardNumberDetail.vue'));
Vue.component('k-acquiring-denylist-bin-create', () => import('@/views/modals/AcquiringDenylistBINCreate.vue'));
Vue.component('k-acquiring-denylist-bin-detail', () => import('@/views/modals/AcquiringDenylistBINDetail.vue'));
Vue.component('k-scheduled-payment-detail', () => import('@/views/modals/ScheduledPaymentDetail.vue'));
Vue.component('k-recurring-payment-detail', () => import('@/views/modals/RecurringPaymentDetail.vue'));
Vue.component('k-banking-connection-create', () => import('@/views/modals/BankingConnectionCreate.vue'));
Vue.component('k-banking-credential-create', () => import('@/views/modals/BankingCredentialsCreate.vue')); // Move to admin

if (config.adminEnabled) {
  Vue.component('k-reconciliation-group-create', () => import('@/views/modals/ReconciliationGroupCreate.vue'));
  Vue.component('k-reserve-account-create', () => import('@/views/modals/ReserveAccountCreate.vue'));
  Vue.component('k-reserve-account-edit', () => import('@/views/modals/ReserveAccountEdit.vue'));
  Vue.component('k-reserve-account-extraction-create', () => import('@/views/modals/ReserveAccountExtractionCreate.vue'));
  Vue.component('k-reserve-account-conditional-extraction-create', () => import('@/views/modals/ReserveAccountConditionalExtractionCreate.vue'));
  Vue.component('k-reserve-transfer-detail', () => import('@/views/modals/ReserveTransferDetail.vue'));
  Vue.component('k-reserve-transaction-bank-payin-concept-override', () => import('@/views/modals/ReserveTransactionBankPayinConceptOverride.vue'));
  Vue.component('k-reserve-transaction-bank-transfer-refund-concept-override', () => import('@/views/modals/ReserveTransactionBankTransferRefundConceptOverride.vue'));
  Vue.component('k-reserve-transaction-card-payin-dispute-override', () => import('@/views/modals/ReserveTransactionCardPayinDisputeOverride.vue'));
  Vue.component('k-reserve-transaction-create', () => import('@/views/modals/ReserveTransactionCreate.vue'));
  Vue.component('k-reserve-manual-transaction-type-selector', () => import('@/components/ManualTransactionTypeSelector.vue'));
  Vue.component('k-banking-connection-method-activate', () => import('@/views/modals/BankingConnectionMethodActivate.vue'));
}

Vue.directive('highlightjs', {
  // deep: true,
  bind(el: any, binding: any) {
    if (binding.value) {
      // eslint-disable-next-line no-param-reassign
      el.textContent = binding.value;
    }
    hljs.highlightBlock(el);
  },
  componentUpdated(el, binding) {
    if (binding.value) {
      // eslint-disable-next-line no-param-reassign
      el.textContent = binding.value;
      hljs.highlightBlock(el);
    }
  },
});

Vue.config.productionTip = false;
Vue.prototype.$global = global;
Vue.prototype.$globalEventBus = new Vue();
Vue.prototype.$config = config;

new Vue({
  router,
  vuetify,
  render: (h: any) => h(App),
} as any).$mount('#app');
