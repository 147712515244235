<template>
    <k-action-dialog :api-call="apiCall" action="Override bank transfer refund concept" @close="close">
        <v-text-field label="Concept" v-model="bank_transfer_concept" required />
    </k-action-dialog>
</template>

<script>
import modalMixin from '@/services/modalMixin';

export default {
  props: {
    reserveTransactionId: String,
  },
  mixins: [modalMixin],
  data() {
    return {
      valid: null,
      bank_transfer_concept: '',
    };
  },
  computed: {
    apiCall() {
      return {
        method: 'POST',
        path: `/reserve_transactions/${this.reserveTransactionId}/bank_transfer_refund_concept_override`,
        body: {
          bank_transfer_concept: this.bank_transfer_concept,
        },
      };
    },
  },
};

</script>
