<template>
    <k-action-dialog :api-call="apiCall" :disable-devmode="true" action="Create" @close="close">
        <v-text-field label="User ID" v-model="user_id" required />
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>
                  Resource
                </th>
                <th>
                  Permission
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(resource, index) in enums.resource"
                :key="index"
              >
                <td>{{ resource }}</td>
                <td>
                  <v-radio-group
                    row
                    @change="(value) => updatePermission(resource, value)"
                    :value="getPermissionValue(resource)"
                  >
                    <v-radio
                      label="None"
                      value="none"
                      :disabled="resource === 'project'"
                    />
                    <v-radio
                      label="Read only"
                      value="read"
                    />
                    <v-radio
                      label="Read and write"
                      value="read_write"
                      :disabled="resource === 'transactions'"
                    />
                  </v-radio-group>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <template slot="done">
            <h1>User added to project!</h1>
        </template>
    </k-action-dialog>
</template>

<script>
import modalMixin from '@/services/modalMixin';
import enums from '@/services/enums';

export default {
  data() {
    return {
      user_id: '',
      touchedPerms: { project: 'read' },
      permissions: [],
      enums,
    };
  },
  mixins: [modalMixin],
  computed: {
    apiCall() {
      return {
        method: 'POST',
        path: '/users',
        body: {
          user_id: this.user_id,
          permissions: this.permissions,
        },
      };
    },
  },
  methods: {
    updatePermission(res, perm) {
      this.touchedPerms[res] = perm;
      this.permissions = Object.entries(this.touchedPerms).map(([resource, permission]) => `${resource}:${permission}`);
    },
    getPermissionValue(resource) {
      return this.touchedPerms[resource];
    },
  },
};
</script>
