export default {
  currency: [
    'EUR',
    'USD',
    'GBP',
  ],
  api_key_type: [
    'secret',
    'public',
  ],
  bank_payout_type: [
    'standard',
    'instant',
  ],
  reserve_account_transfer_type: [
    'sepa_credit',
    'sepa_instant',
  ],
  card_offline_configuration: [
    'null',
    'recurring',
    'unscheduled',
  ],
  country: [
    'AW', 'AF', 'AO', 'AI', 'AX', 'AL', 'AD', 'AE', 'AR', 'AM', 'AS', 'AQ',
    'TF', 'AG', 'AU', 'AT', 'AZ', 'BI', 'BE', 'BJ', 'BF', 'BD', 'BG', 'BH',
    'BS', 'BA', 'BL', 'SH', 'BY', 'BZ', 'BM', 'BO', 'BQ', 'BR', 'BB', 'BN',
    'BT', 'BV', 'BW', 'CF', 'CA', 'CC', 'CH', 'CL', 'CN', 'CI', 'CM', 'CD',
    'CG', 'CK', 'CO', 'KM', 'CV', 'CR', 'CU', 'CW', 'CX', 'KY', 'CY', 'CZ',
    'DE', 'DJ', 'DM', 'DK', 'DO', 'DZ', 'EC', 'EG', 'ER', 'EH', 'ES', 'EE',
    'ET', 'FI', 'FJ', 'FK', 'FR', 'FO', 'FM', 'GA', 'GB', 'GE', 'GG', 'GH',
    'GI', 'GN', 'GP', 'GM', 'GW', 'GQ', 'GR', 'GD', 'GL', 'GT', 'GF', 'GU',
    'GY', 'HK', 'HM', 'HN', 'HR', 'HT', 'HU', 'ID', 'IM', 'IN', 'IO', 'IE',
    'IR', 'IQ', 'IS', 'IL', 'IT', 'JM', 'JE', 'JO', 'JP', 'KZ', 'KE', 'KG',
    'KH', 'KI', 'KN', 'KR', 'XK', 'KW', 'LA', 'LB', 'LR', 'LY', 'LC', 'LI',
    'LK', 'LS', 'LT', 'LU', 'LV', 'MO', 'MF', 'MA', 'MC', 'MD', 'MG', 'MV',
    'MX', 'MH', 'MK', 'ML', 'MT', 'MM', 'ME', 'MN', 'MP', 'MZ', 'MR', 'MS',
    'MQ', 'MU', 'MW', 'MY', 'YT', 'NA', 'NC', 'NE', 'NF', 'NG', 'NI', 'NU',
    'NL', 'NO', 'NP', 'NR', 'NZ', 'OM', 'PK', 'PA', 'PN', 'PE', 'PH', 'PW',
    'PG', 'PL', 'PR', 'KP', 'PT', 'PY', 'PS', 'PF', 'QA', 'RE', 'RO', 'RU',
    'RW', 'SA', 'SD', 'SN', 'SG', 'GS', 'SJ', 'SB', 'SL', 'SV', 'SM', 'SO',
    'PM', 'RS', 'SS', 'ST', 'SR', 'SK', 'SI', 'SE', 'SZ', 'SX', 'SC', 'SY',
    'TC', 'TD', 'TG', 'TH', 'TJ', 'TK', 'TM', 'TL', 'TO', 'TT', 'TN', 'TR',
    'TV', 'TW', 'TZ', 'UG', 'UA', 'UM', 'UY', 'US', 'UZ', 'VA', 'VC', 'VE',
    'VG', 'VI', 'VN', 'VU', 'WF', 'WS', 'YE', 'ZA', 'ZM', 'ZW',
  ],
  employment_status: [
    'student',
    'employed',
    'self_employed',
    'searching',
    'not_employed',
  ],
  identity_document_type: [
    'national_id',
    'passport',
    'residence_permit',
    'driver_license',
  ],
  transaction_type: [
    'magic',
    'transfer_source',
    'transfer_destination',
    'card_payin',
    'card_payin_refund',
    'card_payin_refund_failure',
    'bank_payout',
    'bank_payout_failure',
    'bank_payin',
    'bank_payin_refund',
    'reserve_fee',
    'reserve_test',
    'card_payin_dispute',
    'card_payin_dispute_refund',
  ],
  card_token_state: [
    'destroyed',
    'temporary',
    'temporary_used',
    'permanent',
  ],
  card_brand: [
    'unknown',
    'american_express',
    'diners_club',
    'discover',
    'jcb',
    'maestro',
    'mastercard',
    'unionpay',
    'visa',
  ],
  card_payin_state: [
    'processing',
    'authorized',
    'declined',
    'settled',
    'authentication_pending',
    'authentication_timeout',
  ],
  card_payin_authentication_type: [
    'none',
    'three_d_secure',
    'three_d_secure_2',
  ],
  card_payin_refund_state: [
    'processing',
    'completed',
    'failed',
  ],
  card_payin_dispute_state: [
    'needs_response',
    'processing_response',
    'won',
    'lost',
  ],
  bank_payout_state: [
    'processing',
    'completed',
    'failed',
    'refunded',
  ],
  reserve_account_extraction_state: [
    'extraction_processing',
    'merge_waiting',
    'extraction_failed',
    'merge_processing',
    'merge_failed',
    'completed',
  ],
  reserve_transaction_type: [
    'unknown',
    'fee',
    'bank_transfer_received',
    'bank_transfer_refund_received',
    'bank_transfer_sent',
    'subtransactions',
    'card_payin_settled',
    'card_payin_refund_settled',
    'card_payin_dispute',
    'card_payin_dispute_refund',
    'card_payin_dispute_lost',
    'external_payment_sent',
    'external_payment_received',
  ],
  manual_reserve_transaction_type: [
    'unknown',
    'fee',
    'bank_transfer_received',
    'bank_transfer_sent',
    'external_payment_sent',
    'external_payment_received',
  ],
  reserve_transfer_state: [
    'processing',
    'completed',
    'failed',
  ],
  reserve_transfer_attempt_state: [
    'waiting',
    'processing',
    'completed',
    'failed_soft',
    'failed_hard',
    'failed_unsafe',
  ],
  acquiring_operation_state: [
    'processing',
    'authorized',
    'declined',
    'authentication_pending',
  ],
  event_type: [
    'card_payin_dispute_created',
    'card_payin_settled',
    'bank_payin_created',
    'bank_payout_completed',
    'bank_payout_failed',
    'bank_payout_refunded',
    'card_payin_dispute_won',
    'card_payin_dispute_lost',
    'card_payin_authorized',
    'card_payin_declined',
    'transfer_created',
    'card_payin_refund_completed',
    'card_payin_refund_failed',
    'recurring_payment_succeeded',
    'recurring_payment_canceled',
    'recurring_payment_past_due',
    'recurring_payment_failed',
    'payment_created',
    'card_offline_disabled',
    'card_offline_enabled',
  ],
  webhook_delivery_state: [
    'completed',
    'failed',
  ],
  scope: [
    'write',
    'read',
  ],
  resource: [
    'project',
    'api_keys',
    'files',
    'accounts',
    'transactions',
    'customers',
    'transfers',
    'bank_accounts',
    'bank_payouts',
    'bank_payins',
    'bank_payin_references',
    'card_tokens',
    'cards',
    'sca_cards',
    'card_payins',
    'card_payin_refunds',
    'card_payin_disputes',
    'events',
    'webhooks',
    'users',
    'ibans',
    'businesses',
    'virtual_accounts',
    'virtual_transactions',
    'virtual_currencies',
    'virtual_transfers',
    'fees',
    'acquiring_denylist',
    'recurring_payments',
    'scheduled_payments',
    'bank_institutions',
    'bank_connections',
  ],
  decline_code: [
    'generic_decline',
    'failed_to_authenticate',
    'call_issuer',
    'try_again',
    'try_again_later',
    'card_blocked',
    'card_not_supported',
    'card_expired',
    'issuer_not_available',
    'service_not_available',
    'pin_try_exceeded',
    'invalid_cvc',
    'incorrect_cvc',
    'operation_not_supported',
    'do_not_try_again',
    'processing_error',
    'sca_required',
    'insufficient_funds',
    'do_not_honor',
    'not_permitted',
    'in_denylist',
    'offline_disabled',
  ],
  card_payin_unwanted_state: [
    'refund_processing',
    'refund_failed',
    'refund_completed',
    'requires_action',
    'refund_pending',
    'refund_discarded',
  ],
  fee_type: [
    'unknown',
    'point_of_sale',
    'bank_transfer_sent',
    'reserve_account_maintenance',
  ],
};
