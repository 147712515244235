<template>
  <k-action-dialog :api-call="apiCall" action="Edit" @close="close">
    <v-form v-model="valid" v-if="o">
        Meta:
        <v-row>
          <v-col>
            <k-meta-edit v-model="o.meta"></k-meta-edit>
          </v-col>
        </v-row>
    </v-form>
  </k-action-dialog>
</template>

<script>
import api from '@/services/api';
import patch from '@/services/patch';
import modalMixin from '@/services/modalMixin';

export default {
  name: 'BankAccountEdit',
  mixins: [modalMixin],
  props: {
    bankAccountId: String,
  },
  data() {
    return {
      orig: null,
      o: null,
      valid: null,
    };
  },
  created() {
    api.kernel.get(`/bank_accounts/${this.bankAccountId}`).then((data) => {
      this.o = data;
      this.orig = JSON.parse(JSON.stringify(data)); // Deep clone
    });
  },
  computed: {
    apiCall() {
      if (!this.o) return {};

      return {
        method: 'PATCH',
        path: `/bank_accounts/${this.o.id}`,
        body: patch(this.orig, this.o),
      };
    },
  },
};

</script>
