<template>
    <div>
        <h1>SCA Cards</h1>

        <v-btn color="primary" @click="openCreateModal">Create</v-btn>
        <k-sca-card-create v-if="isCreateModalVisible" @close="closeCreateModal"/>

        <v-btn color="primary" @click="openCardTokenCreateModal">Create token</v-btn>
        <k-card-token-create v-if="isCardTokenCreateModalVisible" @close="closeCardTokenCreateModal"/>

        <k-filter-set :filters="filters" :query="myRoute.query"/>

        <k-list-view :source="source">
            <template slot="header">
                <th class="id-column">ID</th>
                <th>Date</th>
                <th>State</th>
                <th>Card number</th>
                <th>Customer</th>
            </template>
            <template slot="object" slot-scope="{ o }">
                <tr @click="() => { selectedSCACard = o.id; openSCACardDetailModal() }" class="rowlink">
                  <td class="fulllink">
                      <k-id :id="o.id"/>
                  </td>
                  <td>{{o.created_at | moment('DD/MM/YYYY - HH:mm') }}</td>
                  <td>
                      {{o.state}}
                  </td>
                  <td>
                      {{o.card_info.number}}
                  </td>
                  <td>
                      {{o.customer_id}}
                  </td>
                </tr>
            </template>
        </k-list-view>
        <k-sca-card-detail v-if="isSCACardDetailModalVisible" @close="closeSCACardDetailModal" v-bind:scaCardId="selectedSCACard"/>
    </div>
</template>

<script>
import activationMixin from '@/services/activationMixin';
import enums from '@/services/enums';
import { useModal } from '@/services/modalMixin';

export default {
  name: 'SCACardList',
  mixins: [activationMixin, useModal('SCACardDetail'), useModal('Create'), useModal('CardTokenCreate')],
  data() {
    return {
      filters: [
        { key: 'customer_id', name: 'Customer', type: 'id', id_prefix: 'cus', nullable: true },
        { key: 'created_at', name: 'Created at', type: 'time' },
        { key: 'card_info.number', name: 'Card number', type: 'string' },
        { key: 'state', name: 'State', type: 'enum', choices: enums.acquiring_operation_state },
        { key: 'card_info.first6', name: 'First 6 digits', type: 'string' },
        { key: 'decline_code', name: 'Decline code', type: 'enum', choices: enums.decline_code },
      ],
      selectedSCACard: undefined,
    };
  },
  created() {
    if (this.$route.query.id) {
      this.isSCACardDetailModalVisible = true;
      this.selectedSCACard = this.$route.query.id;
    }
  },
  computed: {
    source() {
      return {
        env: this.myRoute.params.env,
        project: this.myRoute.params.project,
        path: '/sca_cards',
        query: this.myRoute.query,
      };
    },
  },
};
</script>
