<template>
    <div>
        <h1>Bank Connection Methods</h1>

        <k-banking-connection-method-activate v-if="isCreateModalVisible" v-bind:connection-method="selectedConnectionMethod" @close="closeCreateModal"/>

        <k-filter-set :filters="filters" :query="myRoute.query"/>

        <k-list-view :source="source">
            <template slot="header">
                <th class="id-column">ID</th>
                <th>Name</th>
                <th>Provider</th>
                <th>Region</th>
                <th colspan="2">Linked Institution</th>
                <th>State</th>
                <th class="listable-right">Last Refresh</th>
                <th class="listable-right">Actions</th>
            </template>
            <template slot="object" slot-scope="{ o }">
              <tr :key="o.id">
                    <td class="fulllink">
                        <k-id :id="o.id"/>
                    </td>
                    <td>
                      <code>{{ o.provider_name }}</code> {{o.meta._name}}
                    </td>
                    <td>
                       {{ o.provider }}
                    </td>
                    <td>
                      <span><img :src="`https://flagsapi.com/${o.region}/flat/16.png`" :alt="o.region" /> <code>{{ o.region }}</code></span>
                    </td>
                    <td class="logo-column">
                      <img v-if="o.banking_institution" :src="o.banking_institution.meta._image_url" class="logo" alt="logo"/>
                    </td>
                    <td>
                        {{ o.banking_institution ? o.banking_institution.meta._name : '' }}
                    </td>
                    <td>
                       {{ o.state }}
                    </td>
                    <td class="listable-right">{{ o.created_at | moment("DD MMM YYYY, HH:mm") }}</td>
                    <td class="listable-right">
                      <v-btn
                        icon
                        color="green"
                        @click.prevent.stop="() => {selectedConnectionMethod = o; openCreateModal();}"
                        v-if="o.state === 'discovered'"
                      >
                        <v-icon size="20">mdi-plus</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        color="orange"
                        v-if="o.state === 'active'"
                      >
                        <v-icon size="20">mdi-pause</v-icon>
                      </v-btn>
                    </td>
                  </tr>
            </template>
        </k-list-view>
    </div>
</template>

<script>
import activationMixin from '@/services/activationMixin';
import { useModal } from '@/services/modalMixin';

export default {
  name: 'BankingConnectionList',
  mixins: [activationMixin, useModal('Create')],
  data() {
    return {
      filters: [
        { key: 'banking_institution_id', name: 'Institution', type: 'id', id_prefix: 'bi' },
        { key: 'region', name: 'Region', type: 'string' },
        { key: 'provider_name', name: 'Provider Name', type: 'string' },
        { key: 'created_at', name: 'Created at', type: 'time' },
      ],
      selectedConnection: undefined,
    };
  },
  computed: {
    source() {
      return {
        env: this.myRoute.params.env,
        path: '/banking_connection_methods',
        query: this.myRoute.query,
      };
    },
  },
};
</script>
