<template>
    <div>
        <h1>Card payin disputes</h1>
        <k-filter-set :filters="filters" :query="myRoute.query"/>

        <k-list-view :source="source">
            <template slot="header">
                <th class="id-column">ID</th>
                <th>Date</th>
                <th>State</th>
                <th>Evidence due date</th>
                <th>Is evidence provided</th>
                <th>Card payin</th>
                <th class="listable-right">Value</th>
            </template>
            <template slot="object" slot-scope="{ o }">
                <router-link class="rowlink" :to="`/${myRoute.params.env}/${myRoute.params.project}/card_payin_disputes/${o.id}`" tag="tr" :key="o.id">
                    <td class="fulllink">
                        <k-id :id="o.id"/>
                    </td>
                    <td>{{o.created_at | moment('DD/MM/YYYY - HH:mm') }}</td>
                    <td>
                        {{o.state}}
                    </td>
                    <td>
                        {{o.provided_evidence.due_by | moment('DD/MM/YYYY - HH:mm') }}
                    </td>
                    <td>
                        {{o.provided_evidence.file_id !== null ? 'Yes' : 'No' }}
                    </td>
                    <td>
                        <router-link :to="`/${myRoute.params.env}/${myRoute.params.project}/card_payins/${o.card_payin_id}`" tag="a">
                            {{o.card_payin_id}}
                        </router-link>
                    </td>
                    <td class="listable-right">
                        <k-value :value="o.value"/>
                    </td>
                </router-link>
            </template>
        </k-list-view>
    </div>
</template>

<script>
import activationMixin from '@/services/activationMixin';
import enums from '@/services/enums';

export default {
  name: 'CardPayinList',
  mixins: [activationMixin],
  data() {
    return {
      filters: [
        { key: 'value.amount', name: 'Value', type: 'int' },
        { key: 'value.currency', name: 'Currency', type: 'enum', choices: enums.currency },
        { key: 'card_payin_id', name: 'Card payin', type: 'id', id_prefix: 'cpi' },
        { key: 'state', name: 'State', type: 'enum', choices: enums.card_payin_dispute_state },
        { key: 'created_at', name: 'Created at', type: 'time' },
        { key: 'provided_evidence.due_by', name: 'Evidence due by', type: 'time' },
      ],
      seletectedCardPayinDispute: undefined,
    };
  },
  computed: {
    source() {
      return {
        env: this.myRoute.params.env,
        project: this.myRoute.params.project,
        path: '/card_payin_disputes',
        query: this.myRoute.query,
      };
    },
  },
};
</script>
