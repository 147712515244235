<template>
    <k-action-dialog :api-call="apiCall" action="Settle" @close="close">
        <p>This action simulates the settlement of a card payin. Useful for testing.</p>
        <p>Can only be used in sandbox. In live, card payins are automatically settled when the funds are settled with the card networks, typically in 1 or 2 business days.</p>
    </k-action-dialog>
</template>

<script>
import modalMixin from '@/services/modalMixin';

export default {
  props: {
    cardPayinId: String,
  },
  data() {
    return {
      valid: null,
    };
  },
  mixins: [modalMixin],
  computed: {
    apiCall() {
      const body = {};
      return {
        method: 'POST',
        path: `/card_payins/${this.cardPayinId}/settle`,
        body,
      };
    },
  },
};

</script>
