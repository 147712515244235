<template>
    <v-flex xs12 sm6 offset-sm3>
        <v-card style="padding: 30px;">
            <v-card-title primary-title>
                <h1>Create {{$global.env.name}} project</h1>
            </v-card-title>
            <v-card-text >
                <v-form v-model="valid" ref="form" lazy-validation @submit.prevent="submit">
                    <v-text-field
                        label="Name"
                        v-model="name"
                        required
                        :rules="nameRules"
                        autofocus
                    />
                    <v-btn type="submit" v-if="!processing" :disabled="!valid" >
                        Create project
                    </v-btn>
                    <v-progress-circular v-if="processing" indeterminate color="primary"></v-progress-circular>
                </v-form>
                <v-alert v-if="error" type="error" :value="true">
                    {{ error.message }}
                </v-alert>
            </v-card-text>
        </v-card>
    </v-flex>
</template>

<script>
import api from '@/services/api';

export default {
  data() {
    return {
      valid: true,
      name: '',

      error: null,
      processing: false,
      nameRules: [
        (v) => !!v || 'Required',
      ],
    };
  },
  methods: {
    submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.error = null;
      this.processing = true;
      api.kernel.post('/projects', {
        meta: {
          _name: this.name,
        },
      }).then((data) => {
        this.$globalEventBus.$emit('object-edited');
        this.$router.push(`/${this.$global.env.id}/${data.id}/accounts`);
      }, (error) => {
        this.processing = false;
        this.error = error;
      });
    },
  },
};
</script>
