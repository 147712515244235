<template>
    <k-action-dialog :api-call="apiCall" :disable-devmode="true" action="Edit" @close="close">
        <v-text-field label="User ID" v-model="userId" required disabled/>
        <v-radio-group
          v-model="role"
        >
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th></th>
                <th>Role</th>
                <th>Description</th>
              </tr>
            </thead>
              <tbody>
                <tr>
                  <td>
                    <v-radio value="none"/>
                  </td>
                  <td>
                    None
                  </td>
                  <td>
                    This role does not give any permission to the user by itself.
                  </td>
                </tr>
                <tr>
                  <td>
                    <v-radio value="auditor"/>
                  </td>
                  <td>
                    Auditor
                  </td>
                  <td>
                    Allows read access to all entities on Kernel Payments.
                  </td>
                </tr>
                <tr>
                  <td>
                    <v-radio value="support"/>
                  </td>
                  <td>
                    Support
                  </td>
                  <td>
                    Best for support team members. Allows to read all entities except API keys and allows to write on card payin refunds.
                  </td>
                </tr>
                <tr>
                  <td>
                    <v-radio value="admin"/>
                  </td>
                  <td>
                    Administrator
                  </td>
                  <td>
                    Best for business owners and company administrators. Allows to read and write on all entities.
                  </td>
                </tr>
              </tbody>
          </template>
        </v-simple-table>
        </v-radio-group>
        <template slot="done">
            <h1>User role edited!</h1>
        </template>
    </k-action-dialog>
</template>

<script>
import api from '@/services/api';
import modalMixin from '@/services/modalMixin';

export default {
  props: {
    userId: String,
  },
  mixins: [modalMixin],
  data() {
    return {
      valid: null,
      role: undefined,
    };
  },
  created() {
    api.kernel.get(`/users/${this.userId}`).then((data) => {
      this.role = data.role === null ? 'none' : data.role;
    });
  },
  computed: {
    apiCall() {
      return {
        method: 'PATCH',
        path: `/users/${this.userId}/role`,
        body: {
          role: this.role === 'none' ? null : this.role,
        },
      };
    },
  },
};
</script>
