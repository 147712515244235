<template>
    <div>
        <h1>Virtual transfers</h1>

        <v-btn color="primary" @click="openCreateModal">Create</v-btn>
        <k-virtual-transfer-create v-if="isCreateModalVisible" @close="closeCreateModal"/>

        <k-filter-set :filters="filters" :query="myRoute.query"/>

        <k-list-view :source="source">
            <template slot="header">
                <th class="id-column">ID</th>
                <th>Date</th>
                <th>From</th>
                <th>To</th>
                <th class="listable-right">Value</th>
            </template>
            <template slot="object" slot-scope="{ o }">
                <tr class="rowlink" :key="o.id" @click="() => {selectedVirtualTransfer = o.id; openVirtualTransferDetailModal();}">
                    <td class="fulllink">
                        <k-id :id="o.id"/>
                    </td>
                    <td>{{o.created_at | moment('DD/MM/YYYY - HH:mm') }}</td>
                    <td>
                        <router-link :to="`/${myRoute.params.env}/${myRoute.params.project}/virtual_accounts/${o.source_virtual_account_id}`" tag="a">
                            {{o.source_virtual_account_id}}
                        </router-link>
                    </td>
                    <td>
                        <router-link :to="`/${myRoute.params.env}/${myRoute.params.project}/virtual_accounts/${o.destination_virtual_account_id}`" tag="a">
                            {{o.destination_virtual_account_id}}
                        </router-link>
                    </td>
                    <td class="listable-right">
                        <k-value :value="o.value"/>
                    </td>
                </tr>
            </template>
        </k-list-view>

        <k-virtual-transfer-detail v-if="isVirtualTransferDetailModalVisible" @close="closeVirtualTransferDetailModal" v-bind:virtual-transfer-id="selectedVirtualTransfer"/>
    </div>
</template>

<script>
import activationMixin from '@/services/activationMixin';
import { useModal } from '@/services/modalMixin';

export default {
  name: 'VirtualTransferList',
  mixins: [activationMixin, useModal('Create'), useModal('VirtualTransferDetail')],
  data() {
    return {
      filters: [
        { key: 'value.amount', name: 'Value', type: 'int' },
        { key: 'value.currency', name: 'Currency', type: 'string' },
        { key: 'virtual_source_account_id', name: 'Virtual source account', type: 'id', id_prefix: 'vacc' },
        { key: 'virtual_destination_account_id', name: 'Virtual destination account', type: 'id', id_prefix: 'vacc' },
        { key: 'created_at', name: 'Created at', type: 'time' },
      ],
      selectedVirtualTransfer: undefined,
    };
  },
  created() {
    if (this.$route.query.id) {
      this.isVirtualTransferDetailModalVisible = true;
      this.selectedVirtualTransfer = this.$route.query.id;
    }
  },
  computed: {
    source() {
      return {
        env: this.myRoute.params.env,
        project: this.myRoute.params.project,
        path: '/virtual_transfers',
        query: this.myRoute.query,
      };
    },
  },
};
</script>
