<template>
    <k-dialog title="Delivery" @close="close">
        <k-detail-view :source="detailSource">
            <template slot="object" slot-scope="{ o }">
                <div class="proplist">
                    <div class="prop"><b>ID</b><span>{{o.id}}</span></div>
                    <div class="prop"><b>Created at</b><span>{{o.created_at}}</span></div>
                    <div class="prop"><b>State</b><span>{{o.state}}</span></div>
                    <div class="prop"><b>Error</b><span>{{o.error}}</span></div>
                    <div class="prop"><b>Duration</b><span>{{o.duration_ms}} ms</span></div>
                </div>

                <v-btn color="primary" @click="redeliver">Redeliver</v-btn>

                <v-tabs>
                    <v-tab key="req">Request</v-tab>
                    <v-tab key="resp">Response</v-tab>
                    <v-tab-item key="req">
                        <div v-highlightjs="o && atob(o.request_headers)"></div>
                        <div v-highlightjs="o && atob(o.request_body)"></div>
                    </v-tab-item>
                    <v-tab-item key="resp">
                        <div v-highlightjs="o && atob(o.response_headers)"></div>
                        <div v-highlightjs="o && atob(o.response_body)"></div>
                    </v-tab-item>
                </v-tabs>
            </template>
        </k-detail-view>
    </k-dialog>
</template>

<script>
import modalMixin from '@/services/modalMixin';
import api from '@/services/api';
import activationMixin from '@/services/activationMixin';

export default {
  name: 'WebhookDeliveryDetail',
  props: {
    webhookId: String,
    webhookDeliveryId: String,
  },
  mixins: [activationMixin, modalMixin],
  computed: {
    detailSource() {
      return {
        env: this.myRoute.params.env,
        project: this.myRoute.params.project,
        path: `/webhooks/${this.computedWebhookId}/deliveries/${this.webhookDeliveryId}`,
        query: this.myRoute.query,
      };
    },
    computedWebhookId() {
      if (this.webhookId === undefined || this.webhookId === '') {
        return this.myRoute.params.id;
      }
      return this.webhookId;
    },
  },
  methods: {
    isEmpty(o) {
      return !o || Object.keys(o).length === 0;
    },
    atob(a) {
      return atob(a);
    },
    redeliver() {
      api.kernel.post(`/webhooks/${this.computedWebhookId}/deliveries/${this.webhookDeliveryId}/redeliver`, {}).then(() => {
        // eslint-disable-next-line no-alert
        alert('Event queued for delivery.');
      }, () => {
        // eslint-disable-next-line no-alert
        alert('Something went wrong.');
      });
    },
  },
};
</script>

<style lang="scss">
.proplist {
    > h1 {
        padding-left: 150px;
        font-size: 20px;
    }
    > .prop {
        > b {
            display: inline-block;
            text-align: right;
            padding: 5px 5px;
            width: 150px;
        }
        > span {
            display: inline-block;
        }
    }
}
</style>
