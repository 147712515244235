<template>
  <k-action-dialog :api-call="apiCall" action="Edit" @close="close">
    <v-form v-model="valid" v-if="o">
     <v-text-field label="Retry delay for transfers in seconds" v-model.number="o.transfer_failed_soft_retry_delay" type="number" required />
      <v-text-field label="Max number of transfer attempts" v-model.number="o.transfer_max_attempts" type="number" required />
      <v-text-field label="(Extraction) Max after last external id" v-model.number="o.extraction_max_after_last_external_id" type="number" required />
     <v-text-field label="External ID" v-model="o.external_id" type="text"/>
    </v-form>
  </k-action-dialog>
</template>

<script>
import api from '@/services/api';
import patch from '@/services/patch';
import modalMixin from '@/services/modalMixin';

export default {
  props: {
    reserveAccountId: String,
  },
  name: 'ReserveAccountEdit',
  mixins: [modalMixin],
  data() {
    return {
      orig: null,
      o: null,
      valid: null,
    };
  },
  created() {
    api.kernel.get(`/reserve_accounts/${this.reserveAccountId}`).then((data) => {
      this.o = data;
      this.orig = JSON.parse(JSON.stringify(data)); // Deep clone
    });
  },
  computed: {
    apiCall() {
      if (!this.o) return {};

      const body = {
        ...this.o,
      };

      if (body.external_id && body.external_id.length === 0) {
        body.external_id = null;
      }

      return {
        method: 'PATCH',
        path: `/reserve_accounts/${this.o.id}`,
        body: patch(this.orig, this.o),
      };
    },
  },
};

</script>
