/* eslint-disable vue/no-reserved-keys */
import Vue from 'vue';
import _ from 'lodash';

export default Vue.extend({
  data() {
    return {
      myRoute: null as any,
      _activationMixinActivated: true,
    };
  },
  created() {
    this._activationMixinActivated = true;
    this._activationMixinRefresh();
  },
  activated() {
    this._activationMixinActivated = true;
    this._activationMixinRefresh();
  },
  deactivated() {
    this._activationMixinActivated = false;
  },
  methods: {
    _activationMixinRefresh() {
      if (this._activationMixinActivated) {
        const newMyRoute = {
          path: this.$route.path,
          params: { ...this.$route.params },
          query: { ...this.$route.query },
        };
        if (!_.isEqual(this.myRoute, newMyRoute)) {
          this.myRoute = newMyRoute;
        }
      }
    },
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler() {
        this._activationMixinRefresh();
      },
    },
  },
});
