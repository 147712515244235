<template>
  <div>
    <h1>Projects</h1>

    <div v-for="env in $global.envs" :key="env.id">
      <h2 style="margin-top: 30px;">{{env.name}}</h2>
      <v-btn
        v-if="$config.adminEnabled || !env.id.startsWith('live')"
        color="primary"
        router
        :to="`/${env.id}/create`"
        append
      >Create</v-btn>
      <k-list-view :source="source(env.id)" :allow-download="false">
        <template slot="header">
          <th class="id-column">ID</th>
          <th>Name</th>
        </template>
        <template slot="object" slot-scope="{ o }">
          <router-link :to="`/${env.id}/${o.id}/accounts`" tag="tr" :key="o.id">
            <td class="fulllink">
              <k-id :id="o.id" />
            </td>
            <td>{{o.meta._name}}</td>
          </router-link>
        </template>
      </k-list-view>
    </div>
  </div>
</template>

<script>
import activationMixin from '@/services/activationMixin';

export default {
  name: 'ProjectList',
  mixins: [activationMixin],
  methods: {
    source(env) {
      return {
        env,
        path: '/projects',
        query: this.myRoute.query,
      };
    },
  },
};
</script>
