<template>
  <k-action-dialog :api-call="apiCall" action="Create" @close="close">
    <v-form v-model="valid">
      <v-text-field label="Name" v-model="name" required />
      <k-currency-selector label="Currency" v-model="currency" required />

      <v-radio-group v-model="account_owner">
          <v-radio label="Customer" value="customer"/>
          <v-radio label="Business" value="business"/>
      </v-radio-group>
      <div v-if="account_owner === 'customer'">
        <k-customer-selector label="Customer" v-model="customer_id" required />
      </div>
      <div v-if="account_owner === 'business'">
        <k-business-selector label="Business" v-model="business_id" required />
      </div>

      Meta:

      <br>
      <v-row>
        <v-col>
          <k-meta-edit v-model="meta"></k-meta-edit>
        </v-col>
      </v-row>
    </v-form>
  </k-action-dialog>
</template>

<script>
import modalMixin from '@/services/modalMixin';

export default {
  name: 'AccountCreate',
  mixins: [modalMixin],
  data() {
    return {
      valid: null,
      name: '',
      currency: 'EUR',
      account_owner: 'customer',
      customer_id: null,
      business_id: null,
      meta: {},
    };
  },
  computed: {
    apiCall() {
      const body = {
        currency: this.currency,
        meta: this.meta,
      };

      body.meta._name = this.name;

      if (this.account_owner === 'customer') {
        body.customer_id = this.customer_id;
      }

      if (this.account_owner === 'business') {
        body.business_id = this.business_id;
      }

      return {
        method: 'POST',
        path: '/accounts',
        body,
      };
    },
  },
};

</script>
