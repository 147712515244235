<template>
    <div>
        <h1>Virtual accounts</h1>

        <v-btn color="primary" @click="openCreateModal">Create</v-btn>
        <k-virtual-account-create v-if="isCreateModalVisible" @close="closeCreateModal"/>

        <k-filter-set :filters="filters" :query="myRoute.query"/>

        <k-list-view :source="source">
            <template slot="header">
                <th class="id-column">ID</th>
                <th>Name</th>
                <th>Customer</th>
                <th class="listable-right">Balance</th>
            </template>
            <template slot="object" slot-scope="{ o }">
                <router-link class="rowlink" :to="`/${myRoute.params.env}/${myRoute.params.project}/virtual_accounts/${o.id}`" tag="tr" :key="o.id">
                    <td class="fulllink">
                        <k-id :id="o.id"/>
                    </td>
                    <td>
                        {{o.meta._name}}
                    </td>
                    <td>
                        <span v-if="o.customer">
                            <k-id :id="o.customer.id"/> {{o.customer.first_name}} {{o.customer.last_name}}
                        </span>
                        <span v-else>-</span>
                    </td>
                    <td class="listable-right">
                        <k-value :value="o.balance"/>
                    </td>
                </router-link>
            </template>
        </k-list-view>
    </div>
</template>

<script>
import activationMixin from '@/services/activationMixin';
// import enums from '@/services/enums';
import { useModal } from '@/services/modalMixin';

export default {
  name: 'AccountList',
  mixins: [activationMixin, useModal('Create')],
  data() {
    return {
      filters: [
        { key: 'balance.currency', name: 'Currency', type: 'enum', choices: this.$global.projectVirtualCurrenciesCodes },
        { key: 'customer_id', name: 'Customer', type: 'id', id_prefix: 'cus', nullable: true },
        { key: 'created_at', name: 'Created at', type: 'time' },
      ],
    };
  },
  computed: {
    source() {
      return {
        env: this.myRoute.params.env,
        project: this.myRoute.params.project,
        path: '/virtual_accounts',
        query: {
          ...this.myRoute.query,
          expand: 'customer,virtual_currency',
        },
      };
    },
  },
};
</script>

<style lang="scss">
.filter-op, .filter-value {
    background: rgba(0, 0, 0, 0.1);
    line-height: 34px;
    padding: 0 10px;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    &:hover {
        background: rgba(0, 0, 0, 0.3);
    }
}
</style>
