import Vue from 'vue';
import { Config } from './config.model';

// eslint-disable-next-line import/no-unresolved
const config: Config = require('config').default;

const global: any = {
  user: null,
  token: null,

  envs: {},

  env: null,
  project: null,
  projectVirtualCurrenciesByCode: null,
  projectVirtualCurrenciesCodes: [],
  scope: 'nil',
};

config.envs.forEach((env: any) => {
  global.envs[env.id] = {
    ...env,
    projects: null,
    currencies: null,
    currenciesByID: null,
  };
});

// Hack to make globals reactive.
const globalReactive = new Vue({ data: { global } }).global;

export default globalReactive;
