<template>
  <k-action-dialog :api-call="apiCall" action="Edit" @close="close">
    <v-form v-model="valid" v-if="o">
      <v-text-field label="Name" v-model="o.meta._name" required />
      <v-checkbox label="Allow negative balance" v-model="o.allow_negative_balance" required />
      <v-checkbox label="Starred" v-model="o.starred" required />
      <k-value-selector label="Balance" v-model="o.balance" required />
    </v-form>
  </k-action-dialog>
</template>

<script>
import api from '@/services/api';
import patch from '@/services/patch';
import modalMixin from '@/services/modalMixin';

export default {
  name: 'AccountEdit',
  mixins: [modalMixin],
  data() {
    return {
      orig: null,
      o: null,
      valid: null,
    };
  },
  created() {
    api.kernel.get(`/accounts/${this.$route.params.id}`).then((data) => {
      this.o = data;
      this.orig = JSON.parse(JSON.stringify(data)); // Deep clone
    });
  },
  computed: {
    apiCall() {
      if (!this.o) return {};

      return {
        method: 'PATCH',
        path: `/accounts/${this.o.id}`,
        body: patch(this.orig, this.o),
      };
    },
  },
};

</script>
