<template>
    <div>
        <h1>Businesses</h1>

        <v-btn color="primary" @click="openCreateModal">Create</v-btn>
        <k-business-create v-if="isCreateModalVisible" @close="closeCreateModal"/>

        <k-filter-set :filters="filters" :query="myRoute.query"/>

        <k-list-view :source="source">
            <template slot="header">
                <th class="id-column">ID</th>
                <th>Trading name</th>
                <th>Registration name</th>
                <th class="listable-right">Created at</th>

            </template>
            <template slot="object" slot-scope="{ o }">
                <tr @click="() => { selectedBusiness = o.id; openBusinessDetailModal() }" class="rowlink">
                  <td class="fulllink">
                      <k-id :id="o.id"/>
                  </td>
                  <td>
                    {{o.trading_name}}
                  </td>
                  <td>
                    {{o.registration_name}}
                  </td>
                  <td class="listable-right">{{o.created_at | moment('DD/MM/YYYY - HH:mm') }}</td>
                </tr>
            </template>
        </k-list-view>
        <k-business-detail v-if="isBusinessDetailModalVisible" @close="closeBusinessDetailModal" v-bind:businessId="selectedBusiness"/>
    </div>
</template>

<script>
import activationMixin from '@/services/activationMixin';
import { useModal } from '@/services/modalMixin';

export default {
  name: 'BusinessList',
  mixins: [activationMixin, useModal('BusinessDetail'), useModal('Create')],
  data() {
    return {
      filters: [
        { key: 'created_at', name: 'Created at', type: 'time' },
      ],
      selectedBusiness: undefined,
    };
  },
  created() {
    if (this.$route.query.id) {
      this.isBusinessDetailModalVisible = true;
      this.selectedBusiness = this.$route.query.id;
    }
  },
  computed: {
    source() {
      return {
        env: this.myRoute.params.env,
        project: this.myRoute.params.project,
        path: '/businesses',
        query: this.myRoute.query,
      };
    },
  },
};
</script>
