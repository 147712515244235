<template>
    <v-list v-if="$global.env && $global.project">
        <template v-for="(item, i) in filteredItems">
            <v-subheader :key="i" v-if="item.heading">
                {{ item.heading }}
            </v-subheader>
            <v-list-group :key="i" v-else-if="item.children" v-model="item.model" no-action>
                <v-list-item slot="item">
                    <v-list-item-action>
                        <v-icon>{{ item.model ? item.icon : item['icon-alt'] }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ item.text }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-for="(child, i) in item.children" :key="i">
                    <v-list-item-action v-if="child.icon">
                        <v-icon>{{ child.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ child.text }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>
            <v-list-item :key="i" v-else router :to="`/${$route.params.env}/${$route.params.project}${item.action}`">
                <v-list-item-action>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>
                        {{ item.text }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>
    </v-list>
</template>

<script>
export default {
  computed: {
    filteredItems() {
      return this.items.filter((item) => {
        if (item.showIf === undefined) {
          return true;
        }
        return !!item.showIf;
      });
    },
  },
  data() {
    return {
      items: [
        { icon: 'account_balance_wallet', text: 'Accounts', action: '/accounts' },
        { icon: 'mdi-domain', text: 'Businesses', action: '/businesses' },
        { icon: 'mdi-account-multiple', text: 'Customers', action: '/customers' },
        { icon: 'swap_horiz', text: 'Transfers', action: '/transfers' },
        { icon: 'mdi-cash-register', text: 'Fees', action: '/fees' },
        { heading: 'Cards' },
        { icon: 'mdi-credit-card-plus-outline', text: 'Card payins', action: '/card_payins' },
        { icon: 'mdi-credit-card-outline', text: 'Cards', action: '/cards' },
        { icon: 'mdi-sword-cross', text: 'Disputes', action: '/card_payin_disputes' },
        { icon: 'mdi-credit-card-remove', text: 'Card numbers denylist', action: '/acquiring_denylist/cards' },
        { icon: 'mdi-bank-remove', text: 'BIN denylist', action: '/acquiring_denylist/bins' },
        { heading: 'Bank transfers' },
        { icon: 'call_received', text: 'Bank payins', action: '/bank_payins' },
        { icon: 'call_made', text: 'Bank payouts', action: '/bank_payouts' },
        { icon: 'mdi-bank-transfer-in', text: 'Bank payin references', action: '/bank_payin_references' },
        { icon: 'mdi-account-cash', text: 'IBANs', action: '/ibans', showIf: this.$global.env.apis && this.$global.env.apis.ibans },
        { icon: 'account_balance', text: 'Bank accounts', action: '/bank_accounts' },
        // { heading: 'Bank Aggregation' },
        // { icon: 'mdi-bank', text: 'Institutions', action: '/banking_institutions' },
        // { icon: 'mdi-login-variant', text: 'Connections', action: '/banking_connections' },
        // { icon: 'mdi-checkbook', text: 'Accounts', action: '/banking_products' },
        // { heading: 'Virtual accounting', showIf: this.$global.env.apis && this.$global.env.apis.virtual_accounting },
        // { icon: 'mdi-bitcoin', text: 'Virtual currencies', action: '/virtual_currencies', showIf: this.$global.env.apis && this.$global.env.apis.virtual_accounting },
        // { icon: 'mdi-wallet', text: 'Virtual accounts', action: '/virtual_accounts', showIf: this.$global.env.apis && this.$global.env.apis.virtual_accounting },
        // { icon: 'swap_horiz', text: 'Virtual transfers', action: '/virtual_transfers', showIf: this.$global.env.apis && this.$global.env.apis.virtual_accounting },
        { heading: 'Payments' },
        // { icon: 'point_of_sale', text: 'Payments', action: '/payments' },
        { icon: 'today', text: 'Scheduled Payments', action: '/scheduled_payments' },
        { icon: 'update', text: 'Recurring Payments', action: '/recurring_payments' },
        // { heading: 'Documents' },
        // { icon: 'cloud_upload', text: 'Files', action: '/files' },
        { heading: 'Events' },
        { icon: 'notifications_active', text: 'Events', action: '/events' },
        { icon: 'mdi-webhook', text: 'Webhooks', action: '/webhooks' },
        { heading: 'Settings' },
        { icon: 'settings', text: 'General', action: '/general' },
        { icon: 'vpn_key', text: 'API Keys', action: '/api_keys' },
        { icon: 'mdi-account-group', text: 'Users', action: '/users' },
      ],
    };
  },
};
</script>
