<template>
    <div>
        <h1>Extractions</h1>
        <k-filter-set :filters="filters" :query="myRoute.query"/>
        <k-list-view :source="source">
            <template slot="header">
                <th class="id-column">ID</th>
                <th>RA ID</th>
                <th>Date</th>
                <th>State</th>
                <th>ExtID</th>
                <th>TX</th>
                <th>sTX</th>
                <th>sTX bundles</th>
            </template>
            <template slot="object" slot-scope="{ o }">
                <tr>
                    <td class="fulllink"><k-id :id="o.id"/></td>
                    <td><k-id :id="o.reserve_account_id"/></td>
                    <td>{{o.created_at | moment('DD/MM/YYYY - HH:mm') }}</td>
                    <td>{{ o.state }}</td>
                    <td>{{ o.external_id_whitelist ? `Whitelist len=${o.external_id_whitelist.length}` : o.last_external_id }}</td>
                    <td>{{ o.transaction_count }}</td>
                    <td>{{ o.subtransaction_count }}</td>
                    <td>{{ o.subtransaction_bundle_count }}</td>
                    <td class="listable-buttons">
                        <v-btn :disabled="!o.logs_filename" color="blue" target="_blank" :href="`https://admin.kernel.network/api/${$global.env.id}/v1/bots_file/${o.logs_filename}`">L</v-btn>
                        <v-btn :disabled="!o.data_filename" color="purple" target="_blank" :href="`https://admin.kernel.network/api/${$global.env.id}/v1/bots_file/${o.data_filename}`">D</v-btn>
                    </td>
                </tr>
            </template>
        </k-list-view>
    </div>
</template>

<script>
import activationMixin from '@/services/activationMixin';
import enums from '@/services/enums';

export default {
  name: 'ReserveAccountExtractionList',
  mixins: [activationMixin],
  data() {
    return {
      filters: [
        { key: 'created_at', name: 'Created at', type: 'time' },
        { key: 'reserve_account_id', name: 'Reserve Account', type: 'id', id_prefix: 'rsacc' },
        { key: 'state', name: 'State', type: 'enum', choices: enums.reserve_account_extraction_state },
      ],
    };
  },
  computed: {
    source() {
      return {
        env: this.myRoute.params.env,
        path: '/reserve_account_extractions',
        query: this.myRoute.query,
      };
    },
  },
};
</script>

<style lang="scss">
</style>
