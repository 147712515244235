<template>
    <k-action-dialog :api-call="apiCall" action="Create card token" @close="close">
        <v-form v-model="valid">
            <k-card-field v-model="card_info" />
        </v-form>

        <template slot="done" slot-scope="{ response }">
            <h1>Card token created!</h1>

            This is the one-time-use card token ID:
            <pre>{{ response.json.id }}</pre>
        </template>
    </k-action-dialog>
</template>

<script>
import modalMixin from '@/services/modalMixin';

export default {
  data() {
    return {
      valid: null,
      card_info: {
        number: '',
        expiration_month: null,
        expiration_year: null,
        cvc: '',
      },
    };
  },
  mixins: [modalMixin],
  computed: {
    apiCall() {
      const body = {
        card_info: {
          number: this.card_info.number,
          expiration_month: parseInt(this.card_info.expiration_month, 10),
          expiration_year: parseInt(this.card_info.expiration_year, 10),
          cvc: this.card_info.cvc,
        },
      };
      return {
        method: 'POST',
        path: '/card_tokens',
        body,
      };
    },
  },
};

</script>

<style scoped>
pre {
    display: block;
    background: black;
    color: #ddd;
    padding: 20px;
    word-wrap: break-word;
    white-space: pre-wrap;
}
</style>
