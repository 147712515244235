<template>
    <k-detail-view :source="detailSource">
        <template slot="object" slot-scope="{ o }">
            <h1>ReserveTransaction {{o.id}}</h1>

            <v-card v-if="o.type == 'unknown'" style="max-width: 30%; padding: 5px 20px; margin: 10px 0">
              Reserve transaction type is `unknown`. A type should be set:
              <v-radio-group v-model="type" mandatory>
                <v-radio v-for="(type, index) in rtxTypes" :key="index"
                  :label="type"
                  :value="index"
                ></v-radio>
              </v-radio-group>

              <v-btn @click="setType">Set type</v-btn>
            </v-card>

            <v-btn v-if="!o.reconciliation_group_id" @click="set_as_test">Set as test</v-btn>
            <v-btn v-if="!o.reconciliation_group_id" @click="reextract(o)">Reextract</v-btn>

            <v-btn color="primary" @click="openBankPayinConceptOverrideModal">(BPI) Ref override</v-btn>
            <k-reserve-transaction-bank-payin-concept-override v-if="isBankPayinConceptOverrideModalVisible" @close="closeBankPayinConceptOverrideModal" v-bind:reserve-transaction-id="o.id"/>

            <v-btn color="primary" @click="openBankTransferRefundConceptOverrideModal">(BTRF) Concept override</v-btn>
            <k-reserve-transaction-bank-transfer-refund-concept-override v-if="isBankTransferRefundConceptOverrideModalVisible" @close="closeBankTransferRefundConceptOverrideModal" v-bind:reserve-transaction-id="o.id"/>

            <v-btn color="primary" @click="openCardPayinDisputeOverrideModal">(CPI) Dispute override</v-btn>
            <k-reserve-transaction-card-payin-dispute-override v-if="isCardPayinDisputeOverrideModalVisible" @close="closeCardPayinDisputeOverrideModal" v-bind:reserve-transaction-id="o.id"/>

            <div v-if="o.type == 'subtransactions'">
                <h2>Subtransactions</h2>
                <k-filter-set :filters="filters" :query="myRoute.query"/>
                <k-list-view :source="source">
                    <template slot="header">
                        <th class="id-column">ID</th>
                        <th>Date</th>
                        <th>Type</th>
                        <th>Concept</th>
                        <th class="listable-right">Value</th>
                        <th></th>
                    </template>
                    <template slot="object" slot-scope="{ o }">
                        <router-link :to="`/${myRoute.params.env}/reserve_transactions/${o.id}`" tag="tr" :key="o.id">
                            <td class="fulllink"><k-id :id="o.id"/></td>
                            <td>{{o.created_at | moment('DD/MM/YYYY - HH:mm') }}</td>
                            <td>{{o.type}}</td>
                            <td>{{o.concept}}</td>
                            <td class="listable-right"><k-value sign color :value="o.value"></k-value></td>
                            <td class="listable-buttons">
                                <v-btn v-if="o.reconciliation_group_id" router :to="`/${myRoute.params.env}/reconciliation_groups/${o.reconciliation_group_id}`" color="red">G</v-btn>
                            </td>
                        </router-link>
                    </template>
                </k-list-view>
            </div>
            <h2>Raw</h2>
            <div v-highlightjs="o && JSON.stringify(o, null, 2)"></div>
        </template>
    </k-detail-view>
</template>

<script>
import api from '@/services/api';
import activationMixin from '@/services/activationMixin';
import enums from '@/services/enums';
import { useModal } from '@/services/modalMixin';

export default {
  name: 'ReserveTransactionDetail',
  mixins: [
    activationMixin,
    useModal('BankPayinConceptOverride'),
    useModal('BankTransferRefundConceptOverride'),
    useModal('CardPayinDisputeOverride'),
  ],
  data() {
    return {
      type: undefined,
      filters: [
        { key: 'value.amount', name: 'Value', type: 'int' },
        { key: 'value.currency', name: 'Currency', type: 'enum', choices: enums.currency },
        { key: 'type', name: 'Type', type: 'enum', choices: enums.reserve_transaction_type },
        { key: 'created_at', name: 'Created at', type: 'time' },
      ],
    };
  },
  methods: {
    reextract(o) {
      api.kernel.post(`/reserve_accounts/${o.reserve_account_id}/do_extraction`, {
        external_id_whitelist: [o.external_id],
      }).then(() => {
        // eslint-disable-next-line no-alert
        alert('Marchando!');
      });
    },
    setType() {
      const body = {
        type: enums.reserve_transaction_type[this.type],
      };
      api.kernel.patch(`/reserve_transactions/${this.myRoute.params.id}`, body).then(() => {
        api.kernel.get(`/reserve_transactions/${this.myRoute.params.id}`).then(() => {
          this.$globalEventBus.$emit('object-edited');
        });
      });
    },
    set_as_test() {
      api.kernel.post(`/reserve_transactions/${this.myRoute.params.id}/set_as_test`).then(() => {
        api.kernel.get(`/reserve_transactions/${this.myRoute.params.id}`).then(() => {
          this.$globalEventBus.$emit('object-edited');
        });
      });
    },
  },
  computed: {
    rtxTypes() {
      return enums.reserve_transaction_type;
    },
    rawJSON() {
      return JSON.stringify(this.o.raw, null, 2);
    },
    source() {
      return {
        env: this.myRoute.params.env,
        path: '/reserve_transactions',
        query: {
          ...this.myRoute.query,
          parent_reserve_transaction_id: this.myRoute.params.id,
          expand: 'bank_payin',
        },
      };
    },
    detailSource() {
      return {
        env: this.myRoute.params.env,
        path: `/reserve_transactions/${this.myRoute.params.id}`,
      };
    },
  },
};
</script>
