<template>
    <div>
        <h1>Banking Products</h1>

        <k-filter-set :filters="filters" :query="myRoute.query"/>

        <k-list-view :source="source">
            <template slot="header">
                <th class="id-column">ID</th>
                <th>Name</th>
                <th>Type</th>
                <th colspan="2">Institution</th>
                <th>Customer</th>
                <th>Connection</th>
                <th class="listable-right">Balance</th>
            </template>
            <template slot="object" slot-scope="{ o }">
                <router-link class="rowlink" :to="`/${myRoute.params.env}/${myRoute.params.project}/banking_products/${o.id}`" tag="tr" :key="o.id">
                    <td class="fulllink">
                        <k-id :id="o.id"/>
                    </td>
                    <td>
                      {{ o.meta._name }}
                    </td>
                    <td>
                      {{ o.type }}
                    </td>
                    <td class="logo-column">
                      <img :src="o.banking_connection.banking_institution.meta._image_url" class="logo" alt="logo"/>
                    </td>
                    <td>
                        {{o.banking_connection.banking_institution.meta._name}}
                    </td>
                    <td class="fulllink">
                        <k-id :id="o.banking_connection.customer_id"/>
                    </td>
                    <td class="fulllink">
                        <k-id :id="o.banking_connection_id"/>
                    </td>
                    <td class="listable-right">
                      <k-value :value="o.balance"/>
                    </td>
                </router-link>
            </template>
        </k-list-view>
        <!-- <k-iban-detail v-if="isIBANDetailModalVisible" @close="closeIBANDetailModal" v-bind:iban-id="selectedConnection"/> -->
    </div>
</template>

<script>
import activationMixin from '@/services/activationMixin';
import enums from '@/services/enums';
import { useModal } from '@/services/modalMixin';

export default {
  name: 'BankingProductsList',
  mixins: [activationMixin, useModal('Create'), useModal('IBANDetail')],
  data() {
    return {
      filters: [
        { key: 'balance.amount', name: 'Balance', type: 'int' },
        { key: 'balance.currency', name: 'Currency', type: 'enum', choices: enums.currency },
        { key: 'banking_connection_id', name: 'Bank Connection', type: 'id', id_prefix: 'bc' },
        { key: 'created_at', name: 'Created at', type: 'time' },
      ],
      selectedConnection: undefined,
    };
  },
  computed: {
    source() {
      return {
        env: this.myRoute.params.env,
        project: this.myRoute.params.project,
        path: '/banking_products',
        query: this.myRoute.query,
      };
    },
  },
};
</script>
