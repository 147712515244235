<template>
    <k-detail-view :source="detailSource">
        <template slot="object" slot-scope="{ o }">
            <h1>ReserveAccount {{o.id}}</h1>
            Extraction enabled: {{o.extraction_enabled}}

            <v-btn color="red" v-if="o.extraction_enabled" @click="set_extraction_enabled(false)">Disable</v-btn>
            <v-btn color="green" v-if="!o.extraction_enabled" @click="set_extraction_enabled(true)">Enable</v-btn>

            <v-btn color="teal" @click="openReserveAccountExtractionCreateModal">Manual extraction</v-btn>
            <k-reserve-account-extraction-create v-if="isReserveAccountExtractionCreateModalVisible" @close="closeReserveAccountExtractionCreateModal" v-bind:reserve-account-id="o.id"/>

            <v-btn color="teal" @click="openReserveAccountConditionalExtractionCreateModal">Conditional extraction</v-btn>
            <k-reserve-account-conditional-extraction-create v-if="isReserveAccountConditionalExtractionCreateModalVisible" @close="closeReserveAccountConditionalExtractionCreateModal" v-bind:reserve-account-id="o.id"/>

            <v-btn color="teal" v-if="o.supports_manual_transactions" @click="openReserveTransactionCreateModal">Create Transaction</v-btn>
            <k-reserve-transaction-create v-if="isReserveTransactionCreateModalVisible" @close="closeReserveTransactionCreateModal" v-bind:reserve-account-id="o.id"/>

            <k-filter-set :filters="filters" :query="myRoute.query"/>
            <k-list-view :source="source">
                <template slot="header">
                    <th class="id-column">ID</th>
                    <th>Date</th>
                    <th>Type</th>
                    <th>External ID</th>
                    <th>Concept</th>
                    <th class="listable-right">Value</th>
                    <th class="listable-right">Balance</th>
                    <th></th>
                </template>
                <template slot="object" slot-scope="{ o }">
                    <router-link :to="`/${myRoute.params.env}/reserve_transactions/${o.id}`" tag="tr" :key="o.id">
                        <td class="fulllink"><k-id :id="o.id"/></td>
                        <td>{{o.created_at | moment('DD/MM/YYYY - HH:mm') }}</td>
                        <td>{{o.type}}</td>
                        <td>{{o.external_id}}</td>
                        <td>{{o.concept}}</td>
                        <td class="listable-right">
                            <k-value sign color :value="o.value"></k-value>
                        </td>
                        <td class="listable-right">
                            <k-value :value="o.balance_after"></k-value>
                        </td>
                        <td class="listable-buttons">
                            <v-btn v-if="o.reconciliation_group_id" router :to="`/${myRoute.params.env}/reconciliation_groups/${o.reconciliation_group_id}`" color="red">G</v-btn>
                        </td>
                    </router-link>
                </template>
            </k-list-view>
        </template>
    </k-detail-view>
</template>

<script>
import api from '@/services/api';
import activationMixin from '@/services/activationMixin';
import enums from '@/services/enums';
import { useModal } from '@/services/modalMixin';

export default {
  name: 'ReserveAccountDetail',
  mixins: [
    activationMixin,
    useModal('ReserveAccountExtractionCreate'),
    useModal('ReserveAccountConditionalExtractionCreate'),
    useModal('ReserveTransactionCreate'),
  ],
  data() {
    return {
      filters: [
        { key: 'value.amount', name: 'Value', type: 'int' },
        { key: 'value.currency', name: 'Currency', type: 'enum', choices: enums.currency },
        { key: 'type', name: 'Type', type: 'enum', choices: enums.reserve_transaction_type },
        { key: 'created_at', name: 'Created at', type: 'time' },
        { key: 'external_id', name: 'External ID', type: 'string' },
      ],
    };
  },
  computed: {
    detailSource() {
      return {
        env: this.myRoute.params.env,
        path: `/reserve_accounts/${this.myRoute.params.id}`,
      };
    },
    source() {
      return {
        env: this.myRoute.params.env,
        path: '/reserve_transactions',
        query: {
          ...this.myRoute.query,
          reserve_account_id: this.myRoute.params.id,
          parent_reserve_transaction_id: 'null',
          expand: 'bank_payin',
        },
      };
    },
  },
  methods: {
    set_extraction_enabled(enabled) {
      api.kernel.patch(`/reserve_accounts/${this.myRoute.params.id}`, {
        extraction_enabled: enabled,
      }).then(() => {
        this.$globalEventBus.$emit('object-edited');
      });
    },
  },
};
</script>
