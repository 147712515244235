<template>
    <k-action-dialog :api-call="apiCall" action="Create transfer" @close="close">
        <v-form v-model="valid">
          <v-radio-group v-model="transfer_type">
            <v-radio label="Simple" value="simple"/>
            <v-radio label="Multiple Sources" value="sources"/>
            <v-radio label="Multiple Destinations" value="destinations"/>
          </v-radio-group>
          <k-value-selector label="Amount" v-if="transfer_type == 'simple'" v-model="sources[0].value" />
          <div v-if="transfer_type == 'sources'">
            <div class="account-selector row" v-for="index in sources.length" :key="'source-'+index">
              <div class="row">
                <v-btn icon color="green" x-large @click="deleteSource(index-1)" :disabled="index == 1">
                  <v-icon>mdi-minus-box</v-icon>
                </v-btn>
                <v-col>
                  <k-account-selector :label="'Source account ' + index"
                    v-model="sources[index-1].source_account_id"></k-account-selector>
                  <k-value-selector v-if="destinations.length === 1" label="Amount"
                    v-model="sources[index-1].value"></k-value-selector>
                </v-col>
              </div>
            </div>
            <v-btn color="green" :disabled="destinations.length > 1" @click="addSource">Add source</v-btn>
          </div>
          <div v-else>
            <k-account-selector label="Source account"
            v-model="sources[0].source_account_id"></k-account-selector>
          </div>

          <hr>

          <div v-if="transfer_type == 'destinations'">
            <div class="account-selector row" v-for="index in destinations.length" :key="'destination-'+index" >
              <div class="row">
                <v-btn icon color="green" x-large @click="deleteDestination(index-1)" :disabled="index == 1">
                  <v-icon>mdi-minus-box</v-icon>
                </v-btn>
                <v-col>
                  <k-account-selector :label="'Destination account ' + index"
                    v-model="destinations[index-1].destination_account_id"></k-account-selector>
                  <k-value-selector v-if="sources.length === 1" label="Amount"
                    v-model="destinations[index-1].value"></k-value-selector>
                </v-col>
              </div>
            </div>
            <v-btn color="green" :disabled="sources.length > 1" @click="addDestination">Add destination</v-btn>
          </div>
          <div v-else>
            <k-account-selector label="Destination account"
                  v-model="destinations[0].destination_account_id"></k-account-selector>
          </div>

          <hr>

          Meta:
          <v-row>
            <v-col>
              <k-meta-edit v-model="meta"></k-meta-edit>
            </v-col>
          </v-row>
        </v-form>
    </k-action-dialog>
</template>

<script>
import modalMixin from '@/services/modalMixin';

export default {
  props: {
    sourceAccountId: {
      type: String,
      required: false,
    },
  },
  mixins: [modalMixin],
  data() {
    return {
      transfer_type: 'simple',
      sources: [
        {
          source_account_id: null,
          value: {
            amount: 100,
            currency: 'EUR',
          },
        },
      ],
      destinations: [
        {
          destination_account_id: null,
          value: {
            amount: 100,
            currency: 'EUR',
          },
        },
      ],
      meta: {},
      valid: null,
    };
  },
  created() {
    if (this.sourceAccountId) {
      this.sources[0].source_account_id = this.sourceAccountId;
    }
  },
  methods: {
    addSource() {
      this.sources.push({
        source_account_id: null,
        value: {
          amount: 100,
          currency: 'EUR',
        },
      });
    },
    deleteSource(index) {
      this.sources.splice(index, 1);
    },
    addDestination() {
      this.destinations.push({
        destination_account_id: null,
        value: {
          amount: 100,
          currency: 'EUR',
        },
      });
    },
    deleteDestination(index) {
      this.destinations.splice(index, 1);
    },
  },
  computed: {
    apiCall() {
      const body = {};

      if (this.sources.length === 1) {
        body.source_account_id = this.sources[0].source_account_id;
      } else {
        body.split_source = [];
        this.sources.forEach((source) => {
          body.split_source.push(source);
        });
      }

      if (this.destinations.length === 1) {
        body.destination_account_id = this.destinations[0].destination_account_id;
      } else {
        body.split_destination = [];
        this.destinations.forEach((destination) => {
          body.split_destination.push(destination);
        });
      }

      if (this.sources.length === 1 && this.destinations.length === 1) {
        body.value = this.sources[0].value;
      }

      body.meta = this.meta;

      return {
        method: 'POST',
        path: '/transfers',
        body,
      };
    },
  },
};
</script>

<style scoped lang="scss">
  div.row {
    margin: 0;
  }

  hr {
    margin: 20px 0;
  }

  div.account-selector {
    display: flex;

    > .v-btn {
      margin: auto 20px auto 0;
    }
  }
</style>
