
import Vue from 'vue';
import ProjectNav from '@/components/ProjectNav.vue';
import EnvNav from '@/components/EnvNav.vue';

export default Vue.extend({
  name: 'App',
  data: () => ({
    drawer: true,
  }),
  methods: {
    getProjectPath(env: any, project: any) {
      let path = this.$route.path.split('/').slice(3).join('/');
      if (path === '') {
        path = 'accounts';
      }
      return {
        path: `/${env.id}/${project.id}/${path}`,
        query: this.$route.query,
      };
    },
    getNav() {
      if ((this as any).$global.env && (this as any).$global.project) return ProjectNav;
      if ((this as any).$global.env && (this as any).$config.adminEnabled) return EnvNav;
      return null;
    },
  },
});
