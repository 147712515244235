<template>
    <k-dialog title="User" @close="close">
      <v-text-field label="User ID" v-model="userId" required disabled/>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th>
                Resource
              </th>
              <th>
                Permission
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(resource, index) in enums.resource"
              :key="index"
              disabled
            >
              <td>{{ resource }}</td>
              <td>
                <v-radio-group
                  row
                  :value="getPermissionValue(resource)"
                >
                  <v-radio
                    label="None"
                    value="none"
                    disabled
                  />
                  <v-radio
                    label="Read only"
                    value="read"
                    disabled
                  />
                  <v-radio
                    label="Read and write"
                    value="read_write"
                    disabled
                  />
                </v-radio-group>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </k-dialog>
</template>

<script>
import api from '@/services/api';
import modalMixin from '@/services/modalMixin';
import activationMixin from '@/services/activationMixin';
import enums from '@/services/enums';

export default {
  name: 'UserDetail',
  props: {
    userId: String,
  },
  mixins: [activationMixin, modalMixin],
  data() {
    return {
      enums,
      permissions: {},
    };
  },
  created() {
    api.kernel.get(`/users/${this.userId}`).then((data) => {
      this.permissions = data.permissions.reduce((acc, cur) => {
        const [resource, permission] = cur.split(':');
        return ({ ...acc, [resource]: permission });
      }, {});
      this.permissions = { project: 'read', ...this.permissions };
    });
  },
  methods: {
    getPermissionValue(resource) {
      return this.permissions[resource];
    },
  },
};
</script>
