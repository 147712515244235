<template>
    <k-action-dialog :api-call="apiCall" action="Create card payin" @close="close">
        <v-form v-model="valid">
            <k-account-selector label="Destination account" v-model="account_id" required />
            <k-value-selector label="Amount" v-model="value" required />

            <v-radio-group v-model="authentication_type" required>
                <v-radio label="None" value="none"/>
                <v-radio label="3DSecure (deprecated)" value="three_d_secure" disabled />
                <v-radio label="3DSecure 2.0" value="three_d_secure_2"/>
            </v-radio-group>

            <div v-if="authentication_type !== 'none'">
              <v-text-field label="Authentication redirect URL" v-model="authentication_redirect_url" />
            </div>
            <div v-if="authentication_type === 'three_d_secure_2'">
              <v-checkbox :disabled="configure_offline_card" label="Offline" v-model="offline" />
              Configure card for future `offline` usage:
              <v-radio-group v-model="configure_offline_card" :disabled="source !== 'card' || offline" mandatory>
                  <v-radio label="Don't configure it now" selected/>
                  <v-radio label="Offline recurring usage: charges that are recurrent on a predefined amount or schedule (e.g. subscriptions)" value="recurring"/>
                  <v-radio label="Offline unscheduled usage: charges that are not recurring on a predefined schedule or amount (e.g. balance top-up)" value="unscheduled"/>
              </v-radio-group>
            </div>

            <v-text-field label="Acquiring backend" v-model="acquiring_backend" />

            Payment source:
            <v-radio-group v-model="source">
                <v-radio label="Card info" value="card_info"/>
                <v-radio label="Card token" value="card_token"/>
                <v-radio label="Card" value="card"/>
            </v-radio-group>
            <div v-if="source === 'card_info'">
                <k-card-field v-model="card_info" />
            </div>
            <div v-if="source === 'card_token'">
                <v-text-field label="Card token ID" v-model="card_token_id" />
            </div>
            <div v-if="source === 'card'">
                <k-card-selector label="Card" v-model="card_id" required />
            </div>

            Meta:
            <v-row>
              <v-col>
                <k-meta-edit v-model="meta"></k-meta-edit>
              </v-col>
            </v-row>
        </v-form>
    </k-action-dialog>
</template>

<script>
import modalMixin from '@/services/modalMixin';

export default {
  props: {
    accountId: String,
  },
  mixins: [modalMixin],
  data() {
    return {
      valid: null,
      source: 'card_info',
      card_token_id: null,
      card_id: null,
      card_info: {
        number: '',
        expiration_month: '',
        expiration_year: '',
        cvc: '',
      },
      acquiring_backend: '',
      account_id: null,
      authentication_type: 'none',
      authentication_redirect_url: '',
      offline: null,
      configure_offline_card: null,
      value: {
        amount: 100,
        currency: 'EUR',
      },
      meta: {},
    };
  },
  created() {
    if (this.accountId) {
      this.account_id = this.accountId;
    }
  },
  computed: {
    apiCall() {
      const body = {
        account_id: this.account_id,
        value: this.value,
        authentication_type: this.authentication_type,
      };
      if (this.source === 'card_info') {
        body.card_info = this.card_info;
      }
      if (this.source === 'card_token') {
        body.card_token_id = this.card_token_id;
      }
      if (this.source === 'card') {
        body.card_id = this.card_id;
      }
      if (this.authentication_type !== 'none') {
        body.authentication_redirect_url = this.authentication_redirect_url;
      }
      if (this.authentication_type === 'three_d_secure_2') {
        if (this.offline) {
          body.offline = this.offline;
        }
        if (this.configure_offline_card && this.source === 'card') {
          body.configure_offline_card = this.configure_offline_card;
        }
      }
      if (this.acquiring_backend !== '') {
        body.extra_fields = {};
        body.extra_fields.acquiring_backend = this.acquiring_backend;
      }
      body.meta = this.meta;
      return {
        method: 'POST',
        path: '/card_payins',
        body,
      };
    },
  },
};

</script>
