<template>
    <div>
        <h1>Users</h1>

        <v-btn color="primary" @click="openCreateModal">Create</v-btn>
        <k-user-create v-if="isCreateModalVisible" @close="closeCreateModal"/>

        <k-filter-set :filters="filters" :query="myRoute.query"/>

        <k-list-view :source="source" :allow-download="false">
            <template slot="header">
                <th class="id-column">User ID</th>
                <th class="id-column">Email</th>
                <th>Role</th>
                <th></th>
            </template>
            <template slot="object" slot-scope="{ o }">
                <tr @click="() => {selectedUser = o.user_id; openUserDetailModal();}">
                    <td class="fulllink">
                        <k-id :id="o.user_id"/>
                    </td>
                    <td>{{o.email}}</td>
                    <td>{{o.role}}</td>
                    <td class="right">
                      <v-btn color="primary" @click.stop.prevent="() => {selectedUser = o.user_id; openUserEditModal();}">Edit</v-btn>
                      <v-btn color="primary" @click.stop.prevent="() => {selectedUser = o.user_id; openUserRoleEditModal();}">Edit Role</v-btn>
                      <v-btn color="red" @click.stop.prevent="() => {selectedUser = o.user_id; openUserDeleteModal();}">Delete</v-btn>
                    </td>
                </tr>
            </template>
        </k-list-view>
        <k-user-edit v-if="isUserEditModalVisible" @close="closeUserEditModal" v-bind:user-id="selectedUser"/>
        <k-user-delete v-if="isUserDeleteModalVisible" @close="closeUserDeleteModal" v-bind:user-id="selectedUser"/>
        <k-user-detail v-if="isUserDetailModalVisible" @close="closeUserDetailModal" v-bind:user-id="selectedUser"/>
        <k-user-role-edit v-if="isUserRoleEditModalVisible" @close="closeUserRoleEditModal" v-bind:user-id="selectedUser"/>
    </div>
</template>

<script>
import activationMixin from '@/services/activationMixin';
import enums from '@/services/enums';
import { useModal } from '@/services/modalMixin';

export default {
  name: 'UserList',
  mixins: [activationMixin, useModal('Create'), useModal('UserEdit'), useModal('UserDelete'), useModal('UserDetail'), useModal('UserRoleEdit')],
  data() {
    return {
      filters: [
        { key: 'scope', name: 'Scope', type: 'enum', choices: enums.scope },
      ],
      selectedUser: undefined,
    };
  },
  computed: {
    source() {
      return {
        env: this.myRoute.params.env,
        project: this.myRoute.params.project,
        path: '/users',
        query: this.myRoute.query,
      };
    },
  },
};
</script>

<style scoped>
td.right {
  text-align: right;
}
</style>
