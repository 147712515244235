<template>
  <k-action-dialog :api-call="apiCall" action="Create" @close="close">
    <v-form v-model="valid">
      <v-text-field label="Bot name" v-model="bot_name" required />
      <v-checkbox label="Is enabled?" v-model="enabled" required />

      <v-checkbox label="Supports SEPA Credit" value="sepa_credit" v-model="supported_types" required />
      <v-checkbox label="Supports SEPA Instant" value="sepa_instant" v-model="supported_types" required />

      <v-checkbox label="With maximum allowed amount" v-model="with_max_amount" required />
      <div v-if="with_max_amount">
        <v-text-field
          v-model.number="max_amount"
          type="number"
          single-line
          class="shrink"
          style="width: 120px !important; margin-right: 25px"
          required />
      </div>

      Ignore the following IBANs:
      <div v-for="(iban, idx) in ignore_ibans" :key="idx">
          <v-layout row wrap>
              <v-flex xs6 style="padding: 0 12px">
                  <v-text-field
                    v-model="ignore_ibans[idx]"
                    style="padding: 0"/>
              </v-flex>
          </v-layout>
      </div>
      <v-btn color="green" @click="addIBAN">Add new</v-btn>

    </v-form>
  </k-action-dialog>
</template>

<script>
import modalMixin from '@/services/modalMixin';

export default {
  name: 'BotTransferConfigCreate',
  mixins: [modalMixin],
  data() {
    return {
      valid: null,
      bot_name: '',
      enabled: false,
      supported_types: [],
      with_max_amount: false,
      max_amount: 0,
      ignore_ibans: [],
    };
  },
  computed: {
    apiCall() {
      const body = {
        bot_name: this.bot_name,
        enabled: this.enabled,
      };

      if (this.supported_types.length > 0) {
        body.supported_types = this.supported_types;
      }

      if (this.with_max_amount && this.max_amount > 0) {
        body.max_amount = this.max_amount;
      }

      body.ignore_ibans = [];
      for (const iban of this.ignore_ibans) {
        if (iban !== '') {
          body.ignore_ibans.push(iban);
        }
      }

      return {
        method: 'POST',
        path: '/bot_transfer_configs',
        body,
      };
    },
  },
  methods: {
    addIBAN() {
      this.ignore_ibans.push('');
    },
  },

};

</script>
