<template>
    <k-action-dialog :api-call="apiCall" :disable-devmode="true" action="Delete" @close="close">
        <v-text-field label="User ID" v-model="userId" required disabled/>

        <template slot="done">
            <h1>User deleted from project!</h1>
        </template>
    </k-action-dialog>
</template>

<script>
import modalMixin from '@/services/modalMixin';

export default {
  props: {
    userId: String,
  },
  mixins: [modalMixin],
  computed: {
    apiCall() {
      return {
        method: 'DELETE',
        path: `/users/${this.userId}`,
      };
    },
  },
};
</script>
