<template>
    <k-action-dialog :api-call="apiCall" action="Create bank payin reference" @close="close">
        <v-form v-model="valid">
            <k-account-selector label="Destination account" v-model="account_id" required />
        </v-form>
    </k-action-dialog>
</template>

<script>
import modalMixin from '@/services/modalMixin';

export default {
  props: {
    accountId: String,
  },
  mixins: [modalMixin],
  data() {
    return {
      valid: null,
      account_id: null,
    };
  },
  created() {
    if (this.accountId) {
      this.account_id = this.accountId;
    }
  },
  computed: {
    apiCall() {
      const body = {
        account_id: this.account_id,
      };
      return {
        method: 'POST',
        path: '/bank_payin_references',
        body,
      };
    },
  },
};

</script>
